import React from 'react'
import { Link } from 'react-router-dom'
import orca from '../../assets/images/orca.svg'
import '../../assets/css/App.css'

function ErrorPage() {
  return (
    <div className="App">
      <div className="App-header bg-orca">
        <img
          src={orca}
          className="App-logo whiteForce"
          width={250}
          alt="logo"
        />
        <h4 className="my-3">Oops! you&apos;re lost...</h4>
        <Link to="/home">
          <p>點擊此處回 ORCA BM</p>
        </Link>
      </div>
    </div>
  )
}

export default ErrorPage
