import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'
import Select from 'react-select'

import { faLink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { apiAuthInstance } from '../../apis/backend'
import LoadingButton from '../../components/shared/LoadingButton'
import ModalSubmitWarning from '../../components/modal/ModalSubmitWarning'
import TableList from '../../components/shared/TableList'
import Navbar from '../../components/shared/Navbar'
import Footer from '../../components/shared/Footer'
import Toolbar from '../../components/shared/Toolbar'
import ModalApiStatus from '../../components/modal/ModalApiStatus'

export default function AdminApplicationReview({ settings }) {
  const navigate = useNavigate()
  const { email } = settings.state.authData

  const initSpecificApplicationData = {
    application: {
      status: '',
      remark: '',
      is_authorized: false,
    },
    applicant_data: {
      user: '',
      company_name: '',
      oca_name: '',
      contact_number: '',

      mailchimp_addr_address: '',
      mailchimp_addr_city: '',
      mailchimp_addr_state: '',
      mailchimp_addr_zip_code: '',
      mailchimp_addr_country: '',

      mail_topic: '【ORCA BM 系統通知】ORCA 開通完成',
      mail_content: [
        {
          field: '',
          type: 'fixed',
          value: '您已成功開通 ORCA! 請用以下帳號密碼登入。',
        },
        { field: 'account', type: 'text', value: `帳號：` },
        { field: 'password', type: 'text', value: `密碼：` },
      ],
      account: '',
      password: '',
    },

    ma_data: {
      mailchimp_reply_to: '',
      mailchimp_from_name: '',

      line_id: '',
      line_name: '',
      line_channel_access_token: '',
      line_channel_secret: '',

      facebook_app_id: '',
      facebook_app_secret: '',
    },

    sample_data_csv: {
      crm_profile_csv_url: '',
      crm_profile_csv_name: '',

      items_csv_url: '',
      items_csv_name: '',

      orders_csv_url: '',
      orders_csv_name: '',
    },

    sample_data_mapping: {
      crm_profile_crm_id: '',
      crm_profile_email: '',
      crm_profile_phone: '',

      items_item_id: '',
      items_profit_center: '',
      items_type: '',
      items_segment_1: '',
      items_segment_2: '',
      items_segment_3: '',

      orders_crm_id: '',
      orders_order_date: '',
      orders_order_number: '',
      orders_order_amount: '',
      orders_item_id: '',
      orders_quantity: '',
      orders_unit_selling_price: '',
      orders_actual_selling_price: '',
    },

    formal_data_csv: {
      crm_profile_csv_url: '',
      crm_profile_csv_name: '',

      items_csv_url: '',
      items_csv_name: '',

      orders_csv_url: '',
      orders_csv_name: '',
    },
  }
  const initModalAction = {
    type: '',
    targetId: undefined,
  }
  const initModal = {
    action: initModalAction,
    loaded: true,
    show: false,
    warningModalShow: false,
    rejectModalShow: false,
    notificationModalShow: false,
    settingsModalShow: false,
  }
  const initRejection = {
    status: 'incomplete',
    remark: '',
  }
  const initFormalDataRejection = {
    status: 'formal data incomplete',
    remark: '',
  }
  const initApiStatus = {
    status: '',
    message: '',
    apiStatusModalShow: false,
  }
  const [modal, setModal] = useState(initModal)
  const [specificApplication, setSpecificApplication] = useState(
    initSpecificApplicationData
  )
  const [rejection, setRejection] = useState(initRejection)
  const [formalDataRejection, setFormalDataRejection] = useState(
    initFormalDataRejection
  )
  const [apiStatus, setApiStatus] = useState(initApiStatus)
  const [applications, setApplications] = useState(false)

  const [isFormalData, setIsFormalData] = useState(false)
  const [applicationId, setApplicationId] = useState(-1)

  const dataSrcOptions = [
    { value: 'csv', label: '匯入CSV' },
    { value: 'ga4', label: 'GA4' },
    { value: 'shopify', label: 'Shopify' },
    { value: 'api', label: 'API' },
    // { value: 'tcloud', label: '雲市集' },
  ]
  const [dataSrc, setDataSrc] = useState(dataSrcOptions[0])

  async function putPendingApplicationStatus(id) {
    await apiAuthInstance({
      url: 'applications/client-application/',
      method: 'put',
      data: {
        confirmation: {
          status: specificApplication.application.status,
        },
      },
      params: {
        application_id: id,
      },
    })
      .then(() => {})
      .catch(() => {})
  }
  async function getApplications() {
    await apiAuthInstance({
      url: 'applications/client-application/',
      method: 'get',
      params: {
        user_permission: 'orca_admin',
        user_email: email,
      },
    })
      .then((response) => {
        setApplications(response.data.data)
      })
      .catch((error) => {
        switch (error.response.status) {
          case 401:
            navigate('/home')
            break
          default:
            break
        }
        // setApiStatus({
        //   status: error.response.data.status,
        //   message: error.response.data.message,
        //   apiStatusModalShow: true,
        // })
      })
  }
  async function getSpecificApplication(id) {
    await apiAuthInstance({
      url: 'applications/client-application/',
      method: 'get',
      params: {
        user_permission: 'orca_admin',
        application_id: id,
      },
    })
      .then((response) => {
        setSpecificApplication({
          ...response.data.data,
          applicant_data: {
            ...specificApplication.applicant_data,
            ...response.data.data.applicant_data,
          },
        })
        if (
          response.data.data.application.status === 'formal data pending' ||
          response.data.data.application.status === 'formal data incomplete' ||
          response.data.data.application.status === 'formal data approved'
        ) {
          setIsFormalData(true)
          setModal((prevState) => ({
            ...prevState,
            action: {
              ...initModalAction,
              type: 'formal data review',
            },
          }))
        } else {
          setModal((prevState) => ({
            ...prevState,
            action: {
              ...initModalAction,
              type: 'review',
            },
          }))
        }
      })
      .catch(() => {})
  }
  async function putClientDataSrc() {
    await apiAuthInstance({
      url: `applications/data-source/${applicationId}/`,
      method: 'put',
      data: {
        data_src: dataSrc.value,
      },
    })
      .then((response) => {
        setModal((prevState) => ({
          ...prevState,
          loaded: true,
          show: false,
          warningModalShow: false,
        }))
        setApiStatus({
          status: response.data.status,
          message: response.data.message,
          apiStatusModalShow: true,
        })
        getApplications()
      })
      .catch((error) => {
        setApiStatus({
          status: error.response.data.status,
          message: error.response.data.message,
          apiStatusModalShow: true,
        })
      })
  }
  async function putApplicationStatus() {
    switch (modal.action.type) {
      case 'reject':
        setModal((prevState) => ({
          ...prevState,
          loaded: false,
        }))
        await apiAuthInstance({
          url: 'applications/client-application/',
          method: 'put',
          data: {
            confirmation: rejection,
          },
          params: {
            application_id: applicationId,
          },
        })
          .then((response) => {
            setModal((prevState) => ({
              ...prevState,
              loaded: true,
              show: false,
              warningModalShow: false,
              rejectModalShow: false,
            }))
            setApiStatus({
              status: response.data.status,
              message: response.data.message,
              apiStatusModalShow: true,
            })
            getApplications()
          })
          .catch((error) => {
            setApiStatus({
              status: error.response.data.status,
              message: error.response.data.message,
              apiStatusModalShow: true,
            })
          })
        break

      case 'review':
        setModal((prevState) => ({
          ...prevState,
          loaded: false,
        }))
        await apiAuthInstance({
          url: 'applications/client-application/',
          method: 'put',
          data: {
            confirmation: {
              status: 'approved',
            },
          },
          params: {
            application_id: applicationId,
          },
        })
          .then((response) => {
            setModal((prevState) => ({
              ...prevState,
              loaded: true,
              show: false,
              warningModalShow: false,
              rejectModalShow: false,
            }))
            setApiStatus({
              status: response.data.status,
              message: response.data.message,
              apiStatusModalShow: true,
            })
            getApplications()
          })
          .catch((error) => {
            setApiStatus({
              status: error.response.data.status,
              message: error.response.data.message,
              apiStatusModalShow: true,
            })
          })
        break
      case 'formal data reject':
        setModal((prevState) => ({
          ...prevState,
          loaded: false,
        }))
        await apiAuthInstance({
          url: 'applications/client-application/',
          method: 'put',
          data: {
            confirmation: formalDataRejection,
          },
          params: {
            application_id: applicationId,
          },
        })
          .then((response) => {
            setModal((prevState) => ({
              ...prevState,
              loaded: true,
              show: false,
              warningModalShow: false,
              rejectModalShow: false,
            }))
            setApiStatus({
              status: response.data.status,
              message: response.data.message,
              apiStatusModalShow: true,
            })
            getApplications()
          })
          .catch((error) => {
            setApiStatus({
              status: error.response.data.status,
              message: error.response.data.message,
              apiStatusModalShow: true,
            })
          })
        break
      case 'formal data review':
        setModal((prevState) => ({
          ...prevState,
          loaded: false,
        }))
        await apiAuthInstance({
          url: 'applications/client-application/',
          method: 'put',
          data: {
            confirmation: {
              status: 'formal data approved',
            },
          },
          params: {
            application_id: applicationId,
          },
        })
          .then((response) => {
            setModal((prevState) => ({
              ...prevState,
              loaded: true,
              show: false,
              warningModalShow: false,
              rejectModalShow: false,
            }))
            setApiStatus({
              status: response.data.status,
              message: response.data.message,
              apiStatusModalShow: true,
            })
            getApplications()
          })
          .catch((error) => {
            setApiStatus({
              status: error.response.data.status,
              message: error.response.data.message,
              apiStatusModalShow: true,
            })
          })
        break
      case 'is_authorized':
        setModal((prevState) => ({
          ...prevState,
          loaded: false,
        }))
        await apiAuthInstance({
          url: 'account/user/authorized/',
          method: 'post',
          data: {},
          params: {
            email: specificApplication.applicant_data.applicant,
          },
        })
          .then((response) => {
            setModal((prevState) => ({
              ...prevState,
              loaded: true,
              show: false,
              warningModalShow: false,
              rejectModalShow: false,
            }))
            setApiStatus({
              status: response.data.status,
              message: response.data.message,
              apiStatusModalShow: true,
            })
            getApplications()
          })
          .catch((error) => {
            setApiStatus({
              status: error.response.data.status,
              message: error.response.data.message,
              apiStatusModalShow: true,
            })
          })
        break
      default:
        break
    }
  }
  async function sendNotificationEmail(id) {
    await apiAuthInstance({
      url: `applications/notification/`,
      method: 'post',
      data: {
        type: 'openSuccess',
        id,
        applicant_data: specificApplication.applicant_data,
      },
    })
      .then((response) => {
        setModal((prevState) => ({
          ...prevState,
          loaded: true,
          show: false,
          warningModalShow: false,
          rejectModalShow: false,
        }))
        setApiStatus({
          status: response.data.status,
          message: response.data.message,
          apiStatusModalShow: true,
        })
      })
      .catch((error) => {
        setApiStatus({
          status: error.response.data.status,
          message: error.response.data.message,
          apiStatusModalShow: true,
        })
      })
  }

  const handleModalShow = async (event) => {
    let selectedApplicationData
    switch (event.target.name) {
      case 'review':
        selectedApplicationData = applications.find(
          (application) => application.id === parseInt(event.target.value, 10)
        )
        await getSpecificApplication(selectedApplicationData.id)
        setApplicationId(selectedApplicationData.id)
        setModal((prevState) => ({
          ...prevState,
          show: true,
          warningModalShow: false,
        }))
        break

      case 'notification':
        selectedApplicationData = applications.find(
          (application) => application.id === parseInt(event.target.value, 10)
        )
        await getSpecificApplication(selectedApplicationData.id)
        setApplicationId(selectedApplicationData.id)
        setModal((prevState) => ({
          ...prevState,
          action: {
            ...initModalAction,
            type: 'notification',
          },
          show: false,
          notificationModalShow: true,
        }))
        break

      case 'settings':
        selectedApplicationData = applications.find(
          (application) => application.id === parseInt(event.target.value, 10)
        )
        setApplicationId(selectedApplicationData.id)
        setDataSrc({
          value: selectedApplicationData.data_src,
          label: dataSrcOptions.find(
            (option) => option.value === selectedApplicationData.data_src
          )?.label,
        })
        setModal((prevState) => ({
          ...prevState,
          action: {
            ...initModalAction,
            type: 'settings',
          },
          show: false,
          settingsModalShow: true,
        }))
        break

      case 'delete':
        break

      default:
        // pass
        break
    }
  }

  const handleModalClose = () => {
    setSpecificApplication(initSpecificApplicationData)
    setModal((prevState) => ({
      ...prevState,
      action: initModalAction,
      show: false,
      warningModalShow: false,
      rejectModalShow: false,
      notificationModalShow: false,
      settingsModalShow: false,
    }))
    setApiStatus(initApiStatus)
    setIsFormalData(false)
  }
  const handleModalSubmit = (event) => {
    switch (event.target.name) {
      case 'review':
        setModal((prevState) => ({
          ...prevState,
          show: false,
          warningModalShow: true,
        }))
        break
      case 'reject':
        setModal((prevState) => ({
          ...prevState,
          show: false,
          warningModalShow: true,
          rejectModalShow: false,
          notificationModalShow: false,
        }))
        break
      case 'formal data review':
        setModal((prevState) => ({
          ...prevState,
          show: false,
          warningModalShow: true,
        }))
        break
      case 'formal data reject':
        setModal((prevState) => ({
          ...prevState,
          show: false,
          warningModalShow: true,
          rejectModalShow: false,
          notificationModalShow: false,
        }))
        break
      case 'notification':
        setModal((prevState) => ({
          ...prevState,
          notificationModalShow: false,
          warningModalShow: true,
        }))
        break
      case 'is_authorized':
        setModal((prevState) => ({
          ...prevState,
          show: false,
          warningModalShow: true,
          rejectModalShow: false,
          notificationModalShow: false,
          action: {
            ...prevState.action,
            type: 'is_authorized',
          },
        }))
        break
      case 'settings':
        setModal((prevState) => ({
          ...prevState,
          settingsModalShow: false,
          warningModalShow: true,
        }))
        break
      default:
        // pass
        break
    }
  }
  const handleModalReject = (event) => {
    // 先跳出填寫備註的modal再跳出警告視窗
    switch (event.target.name) {
      case 'reject':
        setModal((prevState) => ({
          ...prevState,
          action: {
            ...initModalAction,
            type: 'reject',
          },
          show: false,
          rejectModalShow: true,
        }))
        break
      case 'formal data reject':
        setModal((prevState) => ({
          ...prevState,
          action: {
            ...initModalAction,
            type: 'formal data reject',
          },
          show: false,
          rejectModalShow: true,
        }))
        break
      default:
        // pass
        break
    }
  }
  const handleWarningModalCancel = (event) => {
    switch (event.target.name) {
      case 'review':
        setModal((prevState) => ({
          ...prevState,
          show: true,
          warningModalShow: false,
        }))
        break
      case 'notification':
        setModal((prevState) => ({
          ...prevState,
          notificationModalShow: true,
          warningModalShow: false,
        }))
        break
      case 'settings':
        setModal((prevState) => ({
          ...prevState,
          settingsModalShow: true,
          warningModalShow: false,
        }))
        break
      default:
        setModal((prevState) => ({
          ...prevState,
          show: false,
          warningModalShow: false,
        }))
        break
    }
  }

  const handleWarningModalSubmit = (event) => {
    switch (event.target.name) {
      case 'review':
        putApplicationStatus()
        break
      case 'reject':
        putApplicationStatus()
        break
      case 'formal data review':
        putApplicationStatus()
        break
      case 'formal data reject':
        putApplicationStatus()
        break
      case 'is_authorized':
        putApplicationStatus()
        break
      case 'notification':
        sendNotificationEmail(applicationId)
        break
      case 'settings':
        putClientDataSrc()
        break
      default:
        // pass
        break
    }
  }
  useEffect(() => {
    if (!email) return
    getApplications()
  }, [email])
  useEffect(() => {
    if (specificApplication.application.status === 'pending' && !isFormalData) {
      putPendingApplicationStatus(applicationId)
    }
  }, [specificApplication])

  return (
    <Container fluid="true" className="pt-2 pb-3 px-5 mb-5 h-100 w-100">
      <Navbar settings={settings} />
      <Toolbar settings={settings} />
      <ModalSubmitWarning
        settings={{
          submitButton: {
            name: modal.action.type,
            value: modal.action.targetId,
            content: '送出',
            loaded: modal.loaded,
          },
          warningModalShow: modal.warningModalShow,
          handleWarningModalCancel,
          handleModalSubmit: handleWarningModalSubmit,
          handleModalClose,
          warningMessageType: modal.action.type,
        }}
      />
      <ModalApiStatus
        settings={{
          content: {
            status: apiStatus.status,
            message: apiStatus.message,
          },
          apiStatusModalShow: apiStatus.apiStatusModalShow,
          handleModalClose,
        }}
      />
      <Row className="d-flex card h-78 mb-2 h6 tableList">
        {applications ? (
          <TableList
            settings={{
              tableConfig: [
                {
                  head: '申請者',
                  dataType: 'text',
                  fieldName: 'applicant',
                },
                {
                  head: '建立時間',
                  dataType: 'datetime-tz+8',
                  fieldName: 'create_time',
                },
                {
                  head: '更新時間',
                  dataType: 'datetime-tz+8',
                  fieldName: 'edit_time',
                },
                {
                  head: '申請來源',
                  dataType: 'text-center',
                  fieldName: 'application_src',
                },
                {
                  head: '資料來源',
                  dataType: 'text-center',
                  fieldName: 'data_src',
                },
                {
                  head: '審核狀態',
                  dataType: 'text-center',
                  fieldName: 'status',
                },
                // {
                //   head: '帳戶開通狀態',
                //   dataType: 'text-center',
                //   fieldName: 'is_authorized',
                // },
                {
                  head: '功能列',
                  dataType: 'review applications',
                  fieldAsValue: 'id',
                  onReviewClick: handleModalShow,
                },
              ],
              data: applications,
              size: 10,
              pagination: true,
            }}
          />
        ) : (
          <div className="d-flex flex-column justify-content-center h-100 w-100">
            <Row
              className="d-flex w-100"
              style={{
                paddingLeft: '30px',
              }}
            >
              <Spinner
                className="m-auto p-0"
                // size="lg"
                style={{
                  height: '60px',
                  width: '60px',
                }}
                animation="border"
                variant="orcaMid"
              />
            </Row>
            <Row>
              <h4 className="text-center text-orcaMid pt-4">資料載入中</h4>
            </Row>
          </div>
        )}
      </Row>

      {/* 特定工單 */}
      <Modal show={modal.show} size="xl">
        <Modal.Header closeButton onHide={handleModalClose}>
          <Modal.Title className="px-4">
            {modal.action.type} the new test member
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex px-5">
          <Row className="d-flex h-100 w-100 m-0">
            {/* Applicant Information */}
            <Col
              xs={5}
              className="pt-2 d-flex flex-column px-1 text-orcaDarker lh-md"
            >
              <h5 className="text-orcaMid fw-bolder">帳戶申請資料</h5>
              <div className="lh-sm pb-2">
                <Row className="pt-2">
                  <Col xs={3}>申請者:</Col>
                  <Col xs={9} className="text-end">
                    {specificApplication.applicant_data.applicant}
                  </Col>
                </Row>
              </div>
              <div className="lh-sm py-2">
                <Row>
                  <Col xs={4}>公司名稱:</Col>
                  <Col xs={8} className="text-end">
                    {specificApplication.applicant_data.company_name}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>OCA 名稱:</Col>
                  <Col xs={8} className="text-end">
                    {specificApplication.applicant_data.oca_name}
                  </Col>
                </Row>
              </div>
              <div className="lh-sm py-2">
                <Row>
                  <Col xs={4}>聯絡電話:</Col>
                  <Col xs={8} className="text-end">
                    {specificApplication.applicant_data.contact_number}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>郵遞區號:</Col>
                  <Col xs={8} className="text-end">
                    {specificApplication.applicant_data.mailchimp_addr_zip_code}
                  </Col>
                </Row>
                <Row>
                  <Col xs={2}>地址:</Col>
                  <Col
                    xs={10}
                    className="oneLineLimit text-end"
                    title={`${specificApplication.applicant_data.mailchimp_addr_country} ${specificApplication.applicant_data.mailchimp_addr_city} ${specificApplication.applicant_data.mailchimp_addr_state} ${specificApplication.applicant_data.mailchimp_addr_address}`}
                  >
                    {specificApplication.applicant_data.mailchimp_addr_country}{' '}
                    {specificApplication.applicant_data.mailchimp_addr_city}{' '}
                    {specificApplication.applicant_data.mailchimp_addr_state}{' '}
                    {specificApplication.applicant_data.mailchimp_addr_address}
                  </Col>
                </Row>
              </div>
            </Col>

            {/* MA Information */}
            <Col
              xs={7}
              className="pt-2 d-flex flex-column px-3 text-orcaDarker lh-lg"
            >
              <h5 className="text-orcaMid fw-bolder">ORCA MA 申請資料</h5>
              <div className="lh-lg ps-2">
                <div className="lh-sm py-2">
                  <Row>
                    <Col xs={3}>寄件者信箱:</Col>
                    <Col xs={9} className="text-end">
                      {specificApplication.ma_data.mailchimp_reply_to}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={3}>寄件者名稱:</Col>
                    <Col xs={9} className="text-end">
                      {specificApplication.ma_data.mailchimp_from_name}
                    </Col>
                  </Row>
                </div>
                <div className="lh-sm py-2">
                  <Row>
                    <Col xs={2}>Line ID:</Col>
                    <Col xs={10} className="text-end">
                      {specificApplication.ma_data.line_id}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={3}>Line Name:</Col>
                    <Col xs={9} className="text-end">
                      {specificApplication.ma_data.line_name}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={5}>Line Channel Access Token:</Col>
                    <Col xs={7} className="text-end">
                      {specificApplication.ma_data.line_channel_access_token}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>Line Channel Secret:</Col>
                    <Col xs={8} className="text-end">
                      {specificApplication.ma_data.line_channel_secret}
                    </Col>
                  </Row>
                </div>
                <div className="lh-sm py-2">
                  <Row>
                    <Col xs={4}>Facebook App ID:</Col>
                    <Col xs={8} className="text-end">
                      {specificApplication.ma_data.facebook_app_id}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>Facebook App Secret:</Col>
                    <Col xs={8} className="text-end">
                      {specificApplication.ma_data.facebook_app_secret}
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>

            {/* <hr
              className="my-5 w-100"
              style={{ border: '1px dashed #6f6fb1' }}
            />

            <Row className="px-0">
              <Col className="h5 text-start text-orcaMid fw-bolder pb-1">
                範例數據欄位對應與下載
              </Col>
            </Row> */}

            {/* CRM PROFILE */}
            {/* <Row className="FormsMappingCard">
              <Col xs={3} className="d-flex my-auto">
                <FontAwesomeIcon
                  icon={faAddressCard}
                  style={{ height: '1.9rem', color: '#b7b6e7' }}
                />
                <h5 className="my-auto px-2">CRM PROFILE</h5>
              </Col>
              <Col xs={2} className="text-end text-orcaLighter my-auto small">
                範例資料
              </Col>
              <Col
                xs={5}
                className="text-end text-orcaLighter fw-bolder oneLineLimit ms-auto my-auto small"
                title={`File Name: ${specificApplication.sample_data_csv.crm_profile_csv_name}`}
              >
                {specificApplication.sample_data_csv.crm_profile_csv_name}
                &ensp;
              </Col>
              <Col xs="auto" className="text-end pe-2">
                <a
                  href={specificApplication.sample_data_csv.crm_profile_csv_url}
                >
                  <Button
                    size="sm"
                    variant="orcaLight"
                    title={
                      specificApplication.sample_data_csv.crm_profile_csv_name
                    }
                    className="mb-1"
                  >
                    檔案下載&ensp;
                    <FontAwesomeIcon
                      icon={faCircleDown}
                      className="text-orcaLight"
                    />
                  </Button>
                </a>
              </Col>
              {isFormalData && (
                <>
                  <Col
                    xs={5}
                    className="text-end text-orcaLighter mb-auto small"
                  >
                    正式資料
                  </Col>
                  <Col
                    xs={5}
                    className="text-end text-orcaLighter fw-bolder oneLineLimit ms-auto mb-auto small"
                    title={`File Name: ${specificApplication.formal_data_csv.crm_profile_csv_name}`}
                  >
                    {specificApplication.formal_data_csv.crm_profile_csv_name}
                    &ensp;
                  </Col>
                  <Col xs="auto" className="text-end pe-2">
                    <a
                      href={
                        specificApplication.formal_data_csv.crm_profile_csv_url
                      }
                    >
                      <Button
                        size="sm"
                        variant="orcaLight"
                        title={
                          specificApplication.formal_data_csv
                            .crm_profile_csv_name
                        }
                        className="mb-2"
                      >
                        檔案下載&ensp;
                        <FontAwesomeIcon
                          icon={faCircleDown}
                          className="text-orcaLight"
                        />
                      </Button>
                    </a>
                  </Col>
                </>
              )}
              <div className="px-4 border-top">
                <Row>
                  <Col xs={7}>crm_profile_crm_id:</Col>
                  <Col xs={5}>
                    {specificApplication.sample_data_mapping.crm_profile_crm_id}
                  </Col>
                </Row>
                <Row>
                  <Col xs={7}>crm_profile_email:</Col>
                  <Col xs={5}>
                    {specificApplication.sample_data_mapping.crm_profile_email}
                  </Col>
                </Row>
                <Row>
                  <Col xs={7}>crm_profile_phone:</Col>
                  <Col xs={5}>
                    {specificApplication.sample_data_mapping.crm_profile_phone}
                  </Col>
                </Row>
              </div>
            </Row> */}

            {/* ITEMS */}
            {/* <Row className="FormsMappingCard mt-2">
              <Col xs={3} className="d-flex my-auto">
                <FontAwesomeIcon
                  icon={faRectangleList}
                  style={{ height: '1.8rem', color: '#b7b6e7' }}
                />
                <h5 className="my-auto px-2">ITEMS</h5>
              </Col>
              <Col xs={2} className="text-end text-orcaLighter my-auto small">
                範例資料
              </Col>
              <Col
                xs={5}
                className="text-orcaLighter fw-bolder oneLineLimit ms-auto my-auto small"
                title={`File Name: ${specificApplication.sample_data_csv.items_csv_name}`}
              >
                {specificApplication.sample_data_csv.items_csv_name}
                &ensp;
              </Col>
              <Col xs="auto" className="text-end pe-2">
                <a href={specificApplication.sample_data_csv.items_csv_url}>
                  <Button
                    size="sm"
                    variant="orcaLight"
                    title={specificApplication.sample_data_csv.items_csv_name}
                    className="mb-1"
                  >
                    檔案下載&ensp;
                    <FontAwesomeIcon
                      icon={faCircleDown}
                      className="text-orcaLight"
                    />
                  </Button>
                </a>
              </Col>
              {isFormalData && (
                <>
                  <Col
                    xs={5}
                    className="text-end text-orcaLighter mb-auto  small"
                  >
                    正式資料
                  </Col>
                  <Col
                    xs={5}
                    className="text-end text-orcaLighter fw-bolder oneLineLimit ms-auto mb-auto small"
                    title={`File Name: ${specificApplication.formal_data_csv.items_csv_name}`}
                  >
                    {specificApplication.formal_data_csv.items_csv_name}
                    &ensp;
                  </Col>
                  <Col xs="auto" className="text-end pe-2">
                    <a href={specificApplication.formal_data_csv.items_csv_url}>
                      <Button
                        size="sm"
                        variant="orcaLight"
                        title={
                          specificApplication.formal_data_csv.items_csv_name
                        }
                        className="mb-2"
                      >
                        檔案下載&ensp;
                        <FontAwesomeIcon
                          icon={faCircleDown}
                          className="text-orcaLight"
                        />
                      </Button>
                    </a>
                  </Col>
                </>
              )}
              <div className="px-4 border-top">
                <Row>
                  <Col xs={4}>items_item_id:</Col>
                  <Col xs={8}>
                    {specificApplication.sample_data_mapping.items_item_id}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>items_profit_center:</Col>
                  <Col xs={8}>
                    {
                      specificApplication.sample_data_mapping
                        .items_profit_center
                    }
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>items_type:</Col>
                  <Col xs={8}>
                    {specificApplication.sample_data_mapping.items_type}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>items_segment_1:</Col>
                  <Col xs={8}>
                    {specificApplication.sample_data_mapping.items_segment_1}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>items_segment_2:</Col>
                  <Col xs={8}>
                    {specificApplication.sample_data_mapping.items_segment_2}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>items_segment_3:</Col>
                  <Col xs={8}>
                    {specificApplication.sample_data_mapping.items_segment_3}
                  </Col>
                </Row>
              </div>
            </Row> */}

            {/* ORDERS */}
            {/* <Row className="FormsMappingCard mt-2"> */}
            {/* <Col xs={3} className="d-flex my-auto">
                <FontAwesomeIcon
                  icon={faMoneyCheckDollar}
                  style={{ height: '2rem', color: '#b7b6e7' }}
                />
                <h5 className="my-auto px-2">ORDERS</h5>
              </Col>
              <Col xs={2} className="text-end text-orcaLighter my-auto small">
                範例資料
              </Col>
              <Col
                xs={5}
                className="text-orcaLighter fw-bolder oneLineLimit ms-auto my-auto small"
                title={`File Name: ${specificApplication.sample_data_csv.orders_csv_name}`}
              >
                {specificApplication.sample_data_csv.orders_csv_name}
                &ensp;
              </Col>
              <Col xs="auto" className="text-end pe-2">
                <a href={specificApplication.sample_data_csv.orders_csv_url}>
                  <Button
                    size="sm"
                    variant="orcaLight"
                    title={specificApplication.sample_data_csv.orders_csv_name}
                    className="mb-2"
                  >
                    檔案下載&ensp;
                    <FontAwesomeIcon
                      icon={faCircleDown}
                      className="text-orcaLight"
                    />
                  </Button>
                </a>
              </Col> */}
            {/* {isFormalData && (
                <>
                  <Col
                    xs={5}
                    className="text-end text-orcaLighter mb-auto small"
                  >
                    正式資料
                  </Col>
                  <Col
                    xs={5}
                    className="text-end text-orcaLighter fw-bolder oneLineLimit ms-auto mb-auto small"
                    title={`File Name: ${specificApplication.formal_data_csv.orders_csv_name}`}
                  >
                    {specificApplication.formal_data_csv.orders_csv_name}
                    &ensp;
                  </Col>
                  <Col xs="auto" className="text-end pe-2">
                    <a
                      href={specificApplication.formal_data_csv.orders_csv_url}
                    >
                      <Button
                        size="sm"
                        variant="orcaLight"
                        title={
                          specificApplication.formal_data_csv.orders_csv_name
                        }
                        className="mb-2"
                      >
                        檔案下載&ensp;
                        <FontAwesomeIcon
                          icon={faCircleDown}
                          className="text-orcaLight"
                        />
                      </Button>
                    </a>
                  </Col>
                </>
              )} */}
            {/* <div className="px-4 border-top">
                <Row>
                  <Col xs={4}>orders_crm_id:</Col>
                  <Col xs={8}>
                    {specificApplication.sample_data_mapping.orders_crm_id}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>orders_order_date:</Col>
                  <Col xs={8}>
                    {specificApplication.sample_data_mapping.orders_order_date}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>orders_order_number:</Col>
                  <Col xs={8}>
                    {
                      specificApplication.sample_data_mapping
                        .orders_order_number
                    }
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>orders_order_amount:</Col>
                  <Col xs={8}>
                    {
                      specificApplication.sample_data_mapping
                        .orders_order_amount
                    }
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>orders_item_id:</Col>
                  <Col xs={8}>
                    {specificApplication.sample_data_mapping.orders_item_id}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>orders_quantity:</Col>
                  <Col xs={8}>
                    {specificApplication.sample_data_mapping.orders_quantity}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4} className="title pe-0">
                    orders_unit_selling_price:
                  </Col>
                  <Col xs={8}>
                    {
                      specificApplication.sample_data_mapping
                        .orders_unit_selling_price
                    }
                  </Col>
                </Row>
                <Row>
                  <Col xs={4} className="title pe-0">
                    orders_actual_selling_price:
                  </Col>
                  <Col xs={8}>
                    {
                      specificApplication.sample_data_mapping
                        .orders_actual_selling_price
                    }
                  </Col>
                </Row>
              </div> */}
            {/* </Row> */}
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex px-5">
          <div className="d-flex me-auto">
            <Button variant="secondary" onClick={handleModalClose}>
              取消
            </Button>
          </div>
          {isFormalData && (
            <div className="d-flex px-0 ms-auto pe-1">
              <Button
                name="formal data reject"
                className="me-2"
                variant="danger"
                onClick={handleModalReject}
              >
                退件
              </Button>
              <span>
                <LoadingButton
                  settings={{
                    name: 'formal data review',
                    value: modal.action.targetId,
                    content: '核准',
                    loaded: modal.loaded,
                    onClick: handleModalSubmit,
                  }}
                />
              </span>
              <Button
                name="is_authorized"
                variant="success"
                onClick={handleModalSubmit}
                className="ms-2"
              >
                開通
              </Button>
            </div>
          )}
          {!isFormalData && (
            <div className="d-flex px-0 ms-auto pe-1">
              <Button
                name="reject"
                className="me-2"
                variant="danger"
                onClick={handleModalReject}
              >
                退件
              </Button>
              <span>
                <LoadingButton
                  settings={{
                    name: modal.action.type,
                    value: modal.action.targetId,
                    content: '核准',
                    loaded: modal.loaded,
                    onClick: handleModalSubmit,
                  }}
                />
              </span>
            </div>
          )}
        </Modal.Footer>
      </Modal>

      {/* Reject Modal */}
      <Modal show={modal.rejectModalShow} size="md">
        <Modal.Header className="h4" closeButton onClick={handleModalClose}>
          Reject the application
        </Modal.Header>
        <Modal.Body className="px-5">
          <Form>
            <Row className="w-100 h-100">
              <Col xs={12}>
                <Row>
                  <Form.Group controlId="contacts-csv-file">
                    {isFormalData && (
                      <>
                        <Form.Label className="d-flex mt-3 h6 text-orca">
                          正式檔案退件原因及說明
                        </Form.Label>
                        <Form.Control
                          // maxLength="50"
                          name="formalDataRejectionReason"
                          as="textarea"
                          rows={6}
                          defaultValue={formalDataRejection.remark}
                          onChange={(event) =>
                            setFormalDataRejection({
                              ...formalDataRejection,
                              remark: event.target.value,
                            })
                          }
                        />
                      </>
                    )}
                    {!isFormalData && (
                      <>
                        <Form.Label className="d-flex mt-3 h6 text-orca">
                          退件原因及說明
                        </Form.Label>
                        <Form.Control
                          name="rejectionReason"
                          as="textarea"
                          rows={6}
                          defaultValue={rejection.remark}
                          onChange={(event) =>
                            setRejection({
                              ...rejection,
                              remark: event.target.value,
                            })
                          }
                        />
                      </>
                    )}
                  </Form.Group>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            variant="secondary"
            name="rejection"
            onClick={handleModalClose}
          >
            取消
          </Button>
          <LoadingButton
            settings={{
              name: modal.action.type,
              value: modal.action.targetId,
              content: '退件',
              loaded: modal.loaded,
              onClick: handleModalSubmit,
            }}
          />
        </Modal.Footer>
      </Modal>

      {/* 設定資料來源 */}
      <Modal show={modal.settingsModalShow} size="md">
        <Modal.Header closeButton onHide={handleModalClose}>
          <Modal.Title className="px-4 fs-5">資料來源管理</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <div className="w-100 text-center text-orca py-3">
            <FontAwesomeIcon icon={faLink} style={{ fontSize: '5rem' }} />
          </div>
          <Form className="w-100">
            <Form.Group className="mb-3 w-100" controlId="">
              <Form.Label className="mb-0 text-orca h6">資料來源</Form.Label>
              <Select
                // styles={purpleSelector}
                value={{
                  value: dataSrc.value,
                  label: dataSrc.label,
                }}
                placeholder=""
                closeMenuOnSelect
                name=""
                // value={{
                //   value: 't',
                //   label: '',
                // }}
                // components={{ Option: TitledOptions, Control: TitledControl }}
                onChange={(event) => {
                  setDataSrc({
                    label: event.label,
                    value: event.value,
                  })
                }}
                options={dataSrcOptions.map((options) => ({
                  value: options.value,
                  label: options.label,
                }))}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex px-5">
          <div className="d-flex mx-auto">
            <Button variant="secondary" onClick={handleModalClose}>
              取消
            </Button>
            <Button
              variant="orca"
              className="mx-2"
              name="settings"
              onClick={handleModalSubmit}
            >
              確定
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* 通知功能 */}
      <Modal show={modal.notificationModalShow} size="md">
        <Modal.Header closeButton onHide={handleModalClose}>
          <Modal.Title className="px-4 fs-5">寄件通知功能</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex px-5">
          <Row className="d-flex h-100 w-100 m-0 text-orca">
            <Form.Select
              size="sm"
              // value={JSON.stringify({
              //   mail_topic: specificApplication.applicant_data.mail_topic,
              //   mail_content: specificApplication.applicant_data.mail_content,
              // })}
              onChange={(e) => {
                const value = JSON.parse(e.target.value)
                setSpecificApplication({
                  ...specificApplication,
                  applicant_data: {
                    ...specificApplication.applicant_data,
                    mail_topic: value.mail_topic,
                    mail_content: value.mail_content,
                  },
                })
              }}
            >
              <option
                disabled
                value={JSON.stringify({
                  mail_topic: '',
                  mail_content: [{ field: '', type: 'text', value: '' }],
                })}
              >
                選擇信件模板
              </option>
              <option
                value={JSON.stringify({
                  mail_topic: '【ORCA BM 系統通知】ORCA 開通完成',
                  mail_content: [
                    {
                      field: '',
                      type: 'fixed',
                      value: '您已成功開通 ORCA! 請用以下帳號密碼登入。',
                    },
                    { field: 'account', type: 'text', value: `帳號：` },
                    { field: 'password', type: 'text', value: `密碼：` },
                  ],
                })}
              >
                ORCA 開通完成
              </option>
              <option
                value={JSON.stringify({
                  mail_topic: '',
                  mail_content: [{ field: '', type: 'text', value: '' }],
                })}
              >
                其他
              </option>
            </Form.Select>
            <h6 className="oneLineLimit text-orcaMid my-auto pt-3 px-0">
              收件人: {specificApplication.applicant_data.applicant}
            </h6>
            <hr
              className="my-2 w-100"
              style={{ border: '1px dashed #6f6fb1' }}
            />

            <Form.Group className="d-flex w-100 py-2 px-0">
              <Form.Control
                placeholder="主旨"
                type="text"
                size="sm"
                value={specificApplication.applicant_data.mail_topic}
                onChange={(e) =>
                  setSpecificApplication({
                    ...specificApplication,
                    applicant_data: {
                      ...specificApplication.applicant_data,
                      mail_topic: e.target.value,
                    },
                  })
                }
              />
            </Form.Group>
            <Form.Control
              placeholder="內容"
              as="textarea"
              size="sm"
              style={{ height: '13rem' }}
              value={specificApplication.applicant_data.mail_content
                .map(
                  (f) =>
                    `${f.value}${
                      specificApplication.applicant_data[f.field] || ''
                    }`
                )
                .join('\n')}
              onChange={(e) => {
                const values = {}
                specificApplication.applicant_data.mail_content.forEach(
                  (f, i) => {
                    if (f.type !== 'fixed')
                      values[f.field] = e.target.value
                        .split('\n')
                        [i].substring(f.value.length)
                  }
                )
                if (Object.keys(values).length > 0)
                  setSpecificApplication({
                    ...specificApplication,
                    applicant_data: {
                      ...specificApplication.applicant_data,
                      ...values,
                    },
                  })
              }}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex px-5">
          <div className="d-flex mx-auto">
            <Button variant="secondary" onClick={handleModalClose}>
              取消
            </Button>
            <span className="ps-2">
              <LoadingButton
                settings={{
                  name: modal.action.type,
                  value: modal.action.targetId,
                  content: '送出',
                  loaded: modal.loaded,
                  onClick: handleModalSubmit,
                }}
              />
            </span>
          </div>
        </Modal.Footer>
      </Modal>
      <Footer />
    </Container>
  )
}
