import { useTranslation } from 'react-i18next'
import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

export default function ModalFormConfirm(props) {
  const { t } = useTranslation('ModalFormConfirm')
  const { settings } = props
  const { content, data, dataType } = settings

  return (
    <Modal show={settings.formConfirmModalShow} size="md">
      <Modal.Header
        className="h6 text-orca"
        closeButton
        onHide={settings.handleModalClose}
      >
        {content}申請資料確認
      </Modal.Header>
      <Modal.Body className="w-100 px-4">
        <Form className="w-100">
          {
            // eslint-disable-next-line no-nested-ternary
            dataType === 'object' ? (
              Object.keys(data).map((key, index) => (
                <Row
                  className="lh-md pt-1"
                  style={{ borderBottom: '1.5px dotted #eee' }}
                  key={index}
                >
                  <Col xs={5} className="h6 text-orca">
                    {t(`${key}`)}:
                  </Col>
                  <Col
                    xs={7}
                    className="fs-6 text-end oneLineLimit"
                    title={data[key]}
                  >
                    {data[key]}
                  </Col>
                </Row>
              ))
            ) : dataType === 'objects in object' ? (
              Object.keys(data).map((category) =>
                Object.keys(data[category]).map((key, index) => (
                  <Row
                    className="lh-md pt-1"
                    style={{ borderBottom: '1.5px dotted #eee' }}
                    key={index}
                  >
                    <Col xs={5} className="h6 text-orca">
                      {key}:
                    </Col>
                    <Col
                      xs={7}
                      className="fs-6 text-end oneLineLimit"
                      title={data[category][key]}
                    >
                      {data[category][key]}
                    </Col>
                  </Row>
                ))
              )
            ) : (
              <h5>dataType error</h5>
            )
          }
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button
          variant="secondary"
          name={settings.formConfirmType}
          onClick={settings.handleFormConfirmModalCancel}
        >
          取消
        </Button>
        <Button
          variant="orca"
          name="confirm"
          value={settings.submitButton.value}
          onClick={settings.handleModalSubmit}
        >
          確認
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
