/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import { Container, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {
  // faAddressCard,
  // faCircleDown,
  // faMoneyCheckDollar,
  // faRectangleList,
  faCircleCheck,
  faCircleChevronRight,
  faCircleExclamation,
  faHourglassHalf,
} from '@fortawesome/free-solid-svg-icons'
import { faThumbsUp } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { apiAuthInstance } from '../../apis/backend'
import Navbar from '../../components/shared/Navbar'
import Footer from '../../components/shared/Footer'
import Toolbar from '../../components/shared/Toolbar'

export default function ApplicantApplicationReview({ settings }) {
  const initSpecificApplicationData = {
    application: {
      status: '',
      remark: '',
      is_authorized: false,
    },
    applicant_data: {
      user: '',
      company_name: '',
      oca_name: '',
      contact_number: '',

      mailchimp_addr_address: '',
      mailchimp_addr_city: '',
      mailchimp_addr_state: '',
      mailchimp_addr_zip_code: '',
      mailchimp_addr_country: '',
    },

    ma_data: {
      mailchimp_reply_to: '',
      mailchimp_from_name: '',

      line_id: '',
      line_name: '',
      line_channel_access_token: '',
      line_channel_secret: '',

      facebook_app_id: '',
      facebook_app_secret: '',
    },

    sample_data_csv: {
      crm_profile_csv_url: '',
      crm_profile_csv_name: '',

      items_csv_url: '',
      items_csv_name: '',

      orders_csv_url: '',
      orders_csv_name: '',
    },

    sample_data_mapping: {
      crm_profile_crm_id: '',
      crm_profile_email: '',
      crm_profile_phone: '',

      items_item_id: '',
      items_profit_center: '',
      items_type: '',
      items_segment_1: '',
      items_segment_2: '',
      items_segment_3: '',

      orders_crm_id: '',
      orders_order_date: '',
      orders_order_number: '',
      orders_order_amount: '',
      orders_item_id: '',
      orders_quantity: '',
      orders_unit_selling_price: '',
      orders_actual_selling_price: '',
    },
  }
  const [specificApplication, setSpecificApplication] = useState(
    initSpecificApplicationData
  )
  const [isFormalData, setIsFormalData] = useState(false)
  const [dataSrc, setDataSrc] = useState('')
  async function getSpecificApplication() {
    await apiAuthInstance({
      url: 'applications/client-application/',
      method: 'get',
      params: {
        user_permission: 'bm_admin',
      },
    })
      .then((response) => {
        setDataSrc(response.data.data.application.data_src)
        setSpecificApplication(response.data.data)
        if (
          response.data.data.application.status === 'formal data pending' ||
          response.data.data.application.status === 'formal data incomplete' ||
          response.data.data.application.status === 'formal data approved'
        ) {
          setIsFormalData(true)
        }
      })
      .catch((e) => {
        console.log(e)
        setSpecificApplication({
          ...specificApplication,
          application: { ...specificApplication.applicant, status: 'not yet' },
        })
      })
  }

  useEffect(() => {
    getSpecificApplication()
  }, [])
  return (
    <Container fluid="true" className="pt-2 pb-3 px-5 mb-5 h-100 w-100">
      <Navbar settings={settings} />
      <Toolbar settings={settings} />

      {/* 工單審核進度條 */}
      <Row className="card h-78 p-3 d-block" style={{ overflowY: 'auto' }}>
        {!specificApplication.application.status && (
          <div className="d-flex flex-column justify-content-center h-100 w-100">
            <Row
              className="d-flex w-100"
              style={{
                paddingLeft: '30px',
              }}
            >
              <Spinner
                className="m-auto p-0"
                // size="lg"
                style={{
                  height: '60px',
                  width: '60px',
                }}
                animation="border"
                variant="orcaMid"
              />
            </Row>
            <Row>
              <h4 className="text-center text-orcaMid pt-4">資料載入中</h4>
            </Row>
          </div>
        )}
        {specificApplication.application.status &&
          specificApplication.application.status !== 'not yet' && (
            <>
              <div className="d-flex w-100">
                <Row className="w-100 mx-auto fw-bolder text-orcaWhite pt-2">
                  <Col
                    className={`d-flex my-auto px-0 ${
                      (specificApplication.application.status === 'pending' ||
                        specificApplication.application.status ===
                          'under review' ||
                        specificApplication.application.status ===
                          'incomplete' ||
                        specificApplication.application.status === 'approved' ||
                        isFormalData) &&
                      'text-orcaMid fw-bolder'
                    }`}
                  >
                    <h5 className="mx-auto mb-0">待審核</h5>
                  </Col>
                  <Col
                    className={`d-flex my-auto px-0 ${
                      (specificApplication.application.status ===
                        'under review' ||
                        specificApplication.application.status ===
                          'incomplete' ||
                        specificApplication.application.status === 'approved' ||
                        isFormalData) &&
                      'text-orcaMid fw-bolder'
                    }`}
                  >
                    <FontAwesomeIcon
                      className="my-auto me-auto h4"
                      icon={faCircleChevronRight}
                    />
                    <h5 className="my-auto me-auto mb-0">審核中</h5>
                  </Col>
                  <Col className="d-flex my-auto px-0">
                    <FontAwesomeIcon
                      className={`my-auto me-auto h4 ${
                        (specificApplication.application.status ===
                          'incomplete' ||
                          specificApplication.application.status ===
                            'approved' ||
                          isFormalData) &&
                        'text-orcaMid fw-bolder'
                      }`}
                      icon={faCircleChevronRight}
                    />
                    <h5
                      className={`my-auto me-auto mb-0 ${
                        (specificApplication.application.status ===
                          'incomplete' ||
                          specificApplication.application.status ===
                            'approved' ||
                          isFormalData) &&
                        'text-orcaMid fw-bolder'
                      }`}
                    >
                      退件/未完成
                    </h5>
                  </Col>
                  <Col
                    className={`d-flex my-auto px-0 ${
                      (specificApplication.application.status === 'approved' ||
                        isFormalData) &&
                      'text-orcaMid fw-bolder'
                    }`}
                  >
                    <FontAwesomeIcon
                      className="my-auto me-auto h4"
                      icon={faCircleChevronRight}
                    />
                    <h5 className="my-auto me-auto mb-0">審核完成</h5>
                  </Col>
                  <Col
                    className={`d-flex my-auto px-0 ${
                      isFormalData && 'text-orcaMid fw-bolder'
                    }`}
                  >
                    <FontAwesomeIcon
                      className="my-auto me-auto h4"
                      icon={faCircleChevronRight}
                    />
                    <div className="h5 my-auto me-auto mb-0">
                      正式檔案上傳
                      <br />
                      <small className="position-absolute px-4">
                        {specificApplication.application.status ===
                        'formal data pending' ? (
                          <small style={{ color: '#7013eb' }}>
                            <FontAwesomeIcon icon={faHourglassHalf} />
                            &nbsp;待審核
                          </small>
                        ) : specificApplication.application.status ===
                          'formal data incomplete' ? (
                          <small className="text-red">
                            <FontAwesomeIcon icon={faCircleExclamation} />
                            &nbsp;待修正
                          </small>
                        ) : specificApplication.application.status ===
                          'formal data approved' ? (
                          <small className="text-success">
                            <FontAwesomeIcon icon={faCircleCheck} />
                            &nbsp;已上傳
                          </small>
                        ) : (
                          ''
                        )}
                      </small>
                    </div>
                  </Col>
                  <Col
                    className={`d-flex my-auto px-0 ${
                      settings.state.authData.status ===
                        'formal data approved' && 'text-orcaMid fw-bolder'
                    }`}
                  >
                    <FontAwesomeIcon
                      className="my-auto me-auto h4"
                      icon={faCircleChevronRight}
                    />
                    <h5 className="my-auto me-auto mb-0">帳戶已開通</h5>
                  </Col>
                </Row>
              </div>
              <hr className="my-4" style={{ border: '1px dashed #6f6fb1' }} />
            </>
          )}

        {/* 工單審核未完成＆退單 */}
        {specificApplication.application.status === 'incomplete' && (
          <div className="d-flex w-100">
            <div className="lh-sm py-2 w-100 mx-auto">
              <h5 className="text-orca fw-bolder px-5">申請未通過</h5>
              <span className="d-flex">
                <span className="w-90 d-flex text-orca fw-bolder mb-0 mx-auto row">
                  <Col xs={10} className="h6 my-auto mb-1 ps-1">
                    退件說明
                  </Col>
                  <Col xs={2} className="d-flex mb-1 pe-0">
                    <Link
                      className="noDeco text-orcaLink fs-6 ms-auto"
                      to="/orca/application/application-main"
                    >
                      <Button
                        size="sm"
                        variant="orca"
                        className="noDropdownIcon ms-auto"
                      >
                        <FontAwesomeIcon
                          className="pe-1 fw-bolder"
                          icon={faCircleChevronRight}
                        />
                        前往修改
                      </Button>
                    </Link>
                  </Col>
                </span>
              </span>
              <Form.Control
                disabled
                rows={3}
                as="textarea"
                className="card m-auto w-90"
                value={specificApplication.application.remark}
              />
              <hr className="my-4" style={{ border: '1px dashed #6f6fb1' }} />
            </div>
          </div>
        )}

        {/* 工單審核通過 */}
        {specificApplication.application.status === 'approved' && (
          <>
            <div className="lh-sm py-2 h-75 text-orca pt-3">
              <div className="d-flex w-100 h-50 pt-5">
                <FontAwesomeIcon
                  style={{ fontSize: '5rem' }}
                  className="m-auto"
                  icon={faThumbsUp}
                />
              </div>
              <h4 className="text-orcaDark text-center fw-bolder mb-auto lh-md">
                您的申請已通過審核！
                <br />
              </h4>
              {dataSrc === 'csv' ? (
                <>
                  <h4 className="text-orcaDark text-center fw-bolder mb-auto lh-md">
                    請點擊下方按鈕上傳正式檔案。
                  </h4>
                  <span className="d-flex py-5 mb-5">
                    <Link
                      className="noDeco mx-auto"
                      to="/orca/formal/formal-main"
                    >
                      <Button variant="orca">前往上傳</Button>
                    </Link>
                  </span>
                </>
              ) : dataSrc === 'ga4' ? (
                <>
                  <br />
                  <h4 className="text-orcaDark text-center fw-bolder mb-auto lh-md">
                    ● 請到
                    <a
                      href="https://analytics.google.com/analytics/web/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Google Analytics
                    </a>
                    →管理→帳戶存取管理／資源存取管理，將wavenet_rd@wavenet.com.tw加入編輯者權限
                  </h4>
                  <br />
                  <h4 className="text-orcaDark text-center fw-bolder mb-auto lh-md">
                    ● 請到
                    <a
                      href="https://tagmanager.google.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Google代碼管理工具
                    </a>
                    →管理→使用者管理，將wavenet_rd@wavenet.com.tw加入使用者權限（可編輯＋讀取）
                  </h4>
                  <br />
                  <h4 className="text-orcaDark text-center fw-bolder mb-auto lh-md">
                    ● 等候資料串接測試結果，帳戶正式開通之後將以信件通知
                  </h4>
                </>
              ) : dataSrc === 'shopify' ? (
                <>
                  <br />
                  <h4 className="text-orcaDark text-center fw-bolder mb-auto lh-md">
                    ● 請在您的{' '}
                    <a
                      href="https://www.shopify.com/tw"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Shopify電商網站
                    </a>
                    安裝ORCA
                    App，並依照App的步驟說明進行操作，即可完成資料串接以及帳戶開通
                  </h4>
                  <br />
                </>
              ) : dataSrc === 'api' ? (
                <>
                  <br />
                  <h4 className="text-orcaDark text-center fw-bolder mb-auto lh-md">
                    ● 請等候資料串接測試結果，帳戶正式開通之後將以信件通知
                  </h4>
                  <br />
                </>
              ) : (
                <>
                  <h4 className="text-orcaDark text-center fw-bolder mb-auto lh-md">
                    {/* 請點擊下方按鈕上傳正式檔案。 */}
                  </h4>
                  {/* <span className="d-flex py-5 mb-5">
                    <Link
                      className="noDeco mx-auto"
                      to="/orca/formal/formal-main"
                    >
                      <Button variant="orca">前往上傳</Button>
                    </Link>
                  </span> */}
                </>
              )}
            </div>
            <hr className="my-5" style={{ border: '1px dashed #6f6fb1' }} />
          </>
        )}

        {/* 正式檔案上傳 / 待修正 */}
        {isFormalData && (
          <div className="d-flex w-100">
            <div className="lh-sm py-2 w-100 mx-auto">
              {specificApplication.application.status ===
                'formal data incomplete' && (
                <div>
                  <h5 className="text-orca fw-bolder px-5">正式檔案上傳狀態</h5>
                  <span className="d-flex">
                    <span className="w-90 d-flex text-orca fw-bolder mb-0 mx-auto row">
                      <Col xs={10} className="h6 my-auto mb-1 ps-1">
                        退件說明
                      </Col>
                      <Col xs={2} className="d-flex mb-1 pe-0">
                        <Link
                          className="noDeco text-orcaLink fs-6 ms-auto"
                          to="/orca/application/application-main"
                        >
                          <Button
                            size="sm"
                            variant="orcaMid"
                            className="noDropdownIcon ms-auto"
                          >
                            <FontAwesomeIcon
                              className="pe-1 fw-bolder"
                              icon={faCircleChevronRight}
                            />
                            前往修改
                          </Button>
                        </Link>
                      </Col>
                    </span>
                  </span>
                  <Form.Control
                    disabled
                    rows={3}
                    as="textarea"
                    className="card m-auto w-90"
                    value={specificApplication.application.remark}
                  />
                  <hr
                    className="my-4"
                    style={{ border: '1px dashed #6f6fb1' }}
                  />
                </div>
              )}
            </div>
          </div>
        )}

        {/* 尚未申請 */}
        {specificApplication.application.status === 'not yet' && (
          <div className="lh-sm py-2 h-85 text-orca pt-5">
            <div className="d-flex w-100 h-50 pt-5">
              <FontAwesomeIcon
                style={{ fontSize: '5rem' }}
                className="mx-auto mt-auto pb-4"
                icon={faCircleExclamation}
              />
            </div>
            <div className="h4 text-orcaDark text-center fw-bolder mb-auto lh-md">
              <h3>Oops!</h3>
              您尚未在 ORCA BM 提出任何申請。
            </div>
            <span className="d-flex py-4">
              <Link
                className="noDeco mx-auto"
                to="/orca/application/application-main"
              >
                <Button variant="orca">立即申請</Button>
              </Link>
            </span>
          </div>
        )}

        {/* 申請資料預覽 */}
        {specificApplication.application.status !== 'not yet' && (
          <>
            {/* Applicant Information */}
            <Row className="d-flex w-100 m-0 px-5 pb-5">
              <Col
                xs={5}
                className="pt-2 d-flex flex-column px-1 text-orcaDarker lh-md"
              >
                <h5 className="text-orca fw-bolder">帳戶申請資料</h5>
                <div className="lh-sm pb-2">
                  <Row className="pt-2">
                    <Col xs={3}>申請者:</Col>
                    <Col xs={9} className="text-end">
                      {specificApplication.applicant_data.applicant}
                    </Col>
                  </Row>
                </div>
                <div className="lh-sm py-2">
                  <Row>
                    <Col xs={3}>公司名稱:</Col>
                    <Col xs={9} className="text-end">
                      {specificApplication.applicant_data.company_name}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={3}>OCA 名稱:</Col>
                    <Col xs={9} className="text-end">
                      {specificApplication.applicant_data.oca_name}
                    </Col>
                  </Row>
                </div>
                <div className="lh-sm py-2">
                  <Row>
                    <Col xs={3}>聯絡電話:</Col>
                    <Col xs={9} className="text-end">
                      {specificApplication.applicant_data.contact_number}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={3}>郵遞區號:</Col>
                    <Col xs={9} className="text-end">
                      {
                        specificApplication.applicant_data
                          .mailchimp_addr_zip_code
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={2}>地址:</Col>
                    <Col
                      xs={10}
                      className="oneLineLimit text-end"
                      title={`${specificApplication.applicant_data.mailchimp_addr_country} ${specificApplication.applicant_data.mailchimp_addr_city} ${specificApplication.applicant_data.mailchimp_addr_state} ${specificApplication.applicant_data.mailchimp_addr_address}`}
                    >
                      {
                        specificApplication.applicant_data
                          .mailchimp_addr_country
                      }{' '}
                      {specificApplication.applicant_data.mailchimp_addr_city}{' '}
                      {specificApplication.applicant_data.mailchimp_addr_state}{' '}
                      {
                        specificApplication.applicant_data
                          .mailchimp_addr_address
                      }
                    </Col>
                  </Row>
                </div>
              </Col>

              {/* MA Information */}
              <Col
                xs={7}
                className="pt-2 d-flex flex-column px-3 text-orcaDarker lh-lg"
              >
                <h5 className="text-orca fw-bolder">ORCA MA 申請資料</h5>
                <div className="lh-lg ps-2">
                  <div className="lh-sm py-2">
                    <Row>
                      <Col xs={3}>寄件人信箱:</Col>
                      <Col xs={9} className="text-end">
                        {specificApplication.ma_data.mailchimp_reply_to}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3}>寄件人名稱:</Col>
                      <Col xs={9} className="text-end">
                        {specificApplication.ma_data.mailchimp_from_name}
                      </Col>
                    </Row>
                  </div>
                  <div className="lh-sm py-2">
                    <Row>
                      <Col xs={2}>Line ID:</Col>
                      <Col xs={10} className="text-end">
                        {specificApplication.ma_data.line_id}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3}>Line Name:</Col>
                      <Col xs={9} className="text-end">
                        {specificApplication.ma_data.line_name}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={5}>Line Channel Access Token:</Col>
                      <Col xs={7} className="text-end">
                        {specificApplication.ma_data.line_channel_access_token}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={4}>Line Channel Secret:</Col>
                      <Col xs={8} className="text-end">
                        {specificApplication.ma_data.line_channel_secret}
                      </Col>
                    </Row>
                  </div>
                  <div className="lh-sm py-2">
                    <Row>
                      <Col xs={3}>Facebook App ID:</Col>
                      <Col xs={9} className="text-end">
                        {specificApplication.ma_data.facebook_app_id}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={4}>Facebook App Secret:</Col>
                      <Col xs={8} className="text-end">
                        {specificApplication.ma_data.facebook_app_secret}
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              {/* <hr
                className="my-5 w-100"
                style={{ border: '1px dashed #6f6fb1' }}
              />
              <Row className="px-0">
                <Col className="h5 text-start text-orca fw-bolder pb-1">
                  範例數據欄位對應與下載
                </Col>
              </Row> */}

              {/* CRM PROFILE */}
              {/* <Row className="FormsMappingCard">
                <Col xs={3} className="d-flex my-auto pb-2">
                  <FontAwesomeIcon
                    icon={faAddressCard}
                    style={{ height: '1.9rem', color: '#3c39a9' }}
                  />
                  <h5 className="my-auto px-2">CRM PROFILE</h5>
                </Col>
                <Col
                  xs={5}
                  className="text-end text-orcaLighter fw-bolder oneLineLimit ms-auto my-auto"
                  title={`File Name: ${specificApplication.sample_data_csv.crm_profile_csv_name}`}
                >
                  {specificApplication.sample_data_csv.crm_profile_csv_name}
                  &ensp;
                </Col>
                <Col xs="auto" className="text-end pe-2">
                  <a
                    href={
                      specificApplication.sample_data_csv.crm_profile_csv_url
                    }
                  >
                    <Button
                      size="sm"
                      variant="orca"
                      title={
                        specificApplication.sample_data_csv.crm_profile_csv_name
                      }
                      className="mb-1"
                    >
                      檔案下載&ensp;
                      <FontAwesomeIcon
                        icon={faCircleDown}
                        className="text-light"
                      />
                    </Button>
                  </a>
                </Col>
                <div className="px-4 border-top">
                  <Row>
                    <Col xs={7}>crm_profile_crm_id:</Col>
                    <Col xs={5}>
                      {
                        specificApplication.sample_data_mapping
                          .crm_profile_crm_id
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={7}>crm_profile_email:</Col>
                    <Col xs={5}>
                      {
                        specificApplication.sample_data_mapping
                          .crm_profile_email
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={7}>crm_profile_phone:</Col>
                    <Col xs={5}>
                      {
                        specificApplication.sample_data_mapping
                          .crm_profile_phone
                      }
                    </Col>
                  </Row>
                </div>
              </Row> */}

              {/* ITEMS */}
              {/* <Row className="FormsMappingCard mt-2">
                <Col xs={3} className="d-flex my-auto pb-2">
                  <FontAwesomeIcon
                    icon={faRectangleList}
                    style={{ height: '1.8rem', color: '#3c39a9' }}
                  />
                  <h5 className="my-auto px-2">ITEMS</h5>
                </Col>
                <Col
                  xs={5}
                  className="text-orcaLighter fw-bolder oneLineLimit ms-auto my-auto"
                  title={`File Name: ${specificApplication.sample_data_csv.items_csv_name}`}
                >
                  {specificApplication.sample_data_csv.items_csv_name}
                  &ensp;
                </Col>
                <Col xs="auto" className="text-end pe-2">
                  <a href={specificApplication.sample_data_csv.items_csv_url}>
                    <Button
                      size="sm"
                      variant="orca"
                      title={specificApplication.sample_data_csv.items_csv_name}
                      className="mb-1"
                    >
                      檔案下載&ensp;
                      <FontAwesomeIcon
                        icon={faCircleDown}
                        className="text-light"
                      />
                    </Button>
                  </a>
                </Col>
                <div className="px-4 border-top">
                  <Row>
                    <Col xs={4}>items_item_id:</Col>
                    <Col xs={8}>
                      {specificApplication.sample_data_mapping.items_item_id}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>items_profit_center:</Col>
                    <Col xs={8}>
                      {
                        specificApplication.sample_data_mapping
                          .items_profit_center
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>items_type:</Col>
                    <Col xs={8}>
                      {specificApplication.sample_data_mapping.items_type}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>items_segment_1:</Col>
                    <Col xs={8}>
                      {specificApplication.sample_data_mapping.items_segment_1}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>items_segment_2:</Col>
                    <Col xs={8}>
                      {specificApplication.sample_data_mapping.items_segment_2}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>items_segment_3:</Col>
                    <Col xs={8}>
                      {specificApplication.sample_data_mapping.items_segment_3}
                    </Col>
                  </Row>
                </div>
              </Row> */}

              {/* ORDERS */}
              {/* <Row className="FormsMappingCard mt-2">
                <Col xs={3} className="d-flex my-auto pb-2">
                  <FontAwesomeIcon
                    icon={faMoneyCheckDollar}
                    style={{ height: '2rem', color: '#3c39a9' }}
                  />
                  <h5 className="my-auto px-2">ORDERS</h5>
                </Col>
                <Col
                  xs={5}
                  className="text-orcaLighter fw-bolder oneLineLimit ms-auto my-auto"
                  title={`File Name: ${specificApplication.sample_data_csv.orders_csv_name}`}
                >
                  {specificApplication.sample_data_csv.orders_csv_name}
                  &ensp;
                </Col>
                <Col xs="auto" className="text-end pe-2">
                  <a href={specificApplication.sample_data_csv.orders_csv_url}>
                    <Button
                      size="sm"
                      variant="orca"
                      title={
                        specificApplication.sample_data_csv.orders_csv_name
                      }
                      className="mb-1"
                    >
                      檔案下載&ensp;
                      <FontAwesomeIcon
                        icon={faCircleDown}
                        className="text-light"
                      />
                    </Button>
                  </a>
                </Col>
                <div className="px-4 border-top">
                  <Row>
                    <Col xs={4}>orders_crm_id:</Col>
                    <Col xs={8}>
                      {specificApplication.sample_data_mapping.orders_crm_id}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>orders_order_date:</Col>
                    <Col xs={8}>
                      {
                        specificApplication.sample_data_mapping
                          .orders_order_date
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>orders_order_number:</Col>
                    <Col xs={8}>
                      {
                        specificApplication.sample_data_mapping
                          .orders_order_number
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>orders_order_amount:</Col>
                    <Col xs={8}>
                      {
                        specificApplication.sample_data_mapping
                          .orders_order_amount
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>orders_item_id:</Col>
                    <Col xs={8}>
                      {specificApplication.sample_data_mapping.orders_item_id}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>orders_quantity:</Col>
                    <Col xs={8}>
                      {specificApplication.sample_data_mapping.orders_quantity}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4} className="title pe-0">
                      orders_unit_selling_price:
                    </Col>
                    <Col xs={8}>
                      {
                        specificApplication.sample_data_mapping
                          .orders_unit_selling_price
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4} className="title pe-0">
                      orders_actual_selling_price:
                    </Col>
                    <Col xs={8}>
                      {
                        specificApplication.sample_data_mapping
                          .orders_actual_selling_price
                      }
                    </Col>
                  </Row>
                </div>
              </Row> */}
            </Row>
          </>
        )}
      </Row>
      <Footer />
    </Container>
  )
}
