// src/services/api.js
// 用於呼叫後端API使用
import { apiAuthInstance } from '../apis/backend'

// 取得特定工單id的資料同步歷史
export async function fetchDataSyncHistoryData(client_application_id) {
  try {
    const response = await apiAuthInstance({
      url: 'applications/data-sync',
      method: 'get',
      params: { client_application_id },
    })
    return response
  } catch (error) {
    return error.response
  }
}

// 取得特定工單id的訂閱狀態切換歷史
export async function fetchSubscriptionHistoryData(client_application_id) {
  try {
    const response = await apiAuthInstance({
      url: 'applications/subscription-status',
      method: 'get',
      params: { client_application_id },
    })
    return response
  } catch (error) {
    return error.response
  }
}
