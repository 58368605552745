/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleRight,
  faCircleCheck,
  faCircleChevronRight,
  faCircleExclamation,
  faCircleInfo,
  faCloudArrowUp,
  faEnvelopeOpenText,
  faFileCircleCheck,
  faHandSparkles,
  faHourglassHalf,
  faSpinner,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons'
import { faSmile } from '@fortawesome/free-regular-svg-icons'
import Navbar from '../../components/shared/Navbar'
import Footer from '../../components/shared/Footer'
import DataCard from '../../components/shared/DataCard'
import ChartGroup from './ChartGroup'
import Toolbar from '../../components/shared/Toolbar'
import { apiAuthInstance } from '../../apis/backend'

export default function Home({ settings }) {
  const initSpecificApplicationData = {
    application: {
      status: '',
      remark: '',
    },
    applicant_data: {
      user: '',
      company_name: '',
      oca_name: '',
      contact_number: '',

      mailchimp_addr_address: '',
      mailchimp_addr_city: '',
      mailchimp_addr_state: '',
      mailchimp_addr_zip_code: '',
      mailchimp_addr_country: '',
    },

    ma_data: {
      mailchimp_reply_to: '',
      mailchimp_from_name: '',

      line_id: '',
      line_name: '',
      line_channel_access_token: '',
      line_channel_secret: '',

      facebook_app_id: '',
      facebook_app_secret: '',
    },

    sample_data_csv: {
      crm_profile_csv_url: '',
      crm_profile_csv_name: '',

      items_csv_url: '',
      items_csv_name: '',

      orders_csv_url: '',
      orders_csv_name: '',
    },

    sample_data_mapping: {
      crm_profile_crm_id: '',
      crm_profile_email: '',
      crm_profile_phone: '',

      items_item_id: '',
      items_profit_center: '',
      items_type: '',
      items_segment_1: '',
      items_segment_2: '',
      items_segment_3: '',

      orders_crm_id: '',
      orders_order_date: '',
      orders_order_number: '',
      orders_order_amount: '',
      orders_item_id: '',
      orders_quantity: '',
      orders_unit_selling_price: '',
      orders_actual_selling_price: '',
    },
  }
  const [specificApplication, setSpecificApplication] = useState(
    initSpecificApplicationData
  )
  const [isFormalData, setIsFormalData] = useState(false)
  async function getSpecificApplication() {
    await apiAuthInstance({
      url: 'applications/client-application/',
      method: 'get',
      params: {
        user_permission: 'bm_admin',
      },
    })
      .then((response) => {
        setSpecificApplication(response.data.data)
        if (
          response.data.data.application.status === 'formal data pending' ||
          response.data.data.application.status === 'formal data incomplete' ||
          response.data.data.application.status === 'formal data approved' ||
          response
        ) {
          setIsFormalData(true)
        }
      })
      .catch(() => {
        setSpecificApplication({
          ...specificApplication,
          application: { ...specificApplication.applicant, status: 'not yet' },
        })
      })
  }
  // console.log(specificApplication.application)

  useEffect(() => {
    getSpecificApplication()
  }, [])
  // console.log(settings.state.authData)
  return (
    <Container fluid="true" className="pt-2 pb-3 px-5 mb-5 h-100 w-100">
      <Navbar settings={settings} />
      <Toolbar settings={settings} />
      <Row className="card h-78 p-3 flex-column d-flex">
        {settings.state.authData.status === 'authorized' ? (
          <>
            <Col xs={6} className="h-100 px-3">
              <div className="d-flex h-10">
                <h5 className="fw-bolder text-orca pt-3">OCM 數據統計概覽</h5>
                <h6 className="text-orcaLink2 my-auto ms-auto">
                  <a
                    className="text-orcaLink2 noDeco"
                    target="_blank"
                    rel="noreferrer"
                    href="https://orcama-plus-en.punwave.com/account/login"
                  >
                    <FontAwesomeIcon
                      className="pe-1"
                      icon={faCircleChevronRight}
                    />
                    前往查看
                  </a>
                </h6>
              </div>
              <div
                className="h-87 scroller"
                style={{ overflowY: 'auto', overflowX: 'hidden' }}
              >
                <ChartGroup />
                <DataCard />
              </div>
            </Col>
            <Col xs={6} className="h-100 px-3">
              <div className="d-flex h-10">
                <h5 className="fw-bolder text-orca pt-3">OCA 數據分析概覽</h5>
                <h6 className="text-orcaLink2 my-auto ms-auto">
                  <FontAwesomeIcon
                    className="pe-1"
                    icon={faCircleChevronRight}
                  />
                  <a
                    className="text-orcaLink2 noDeco"
                    target="_blank"
                    rel="noreferrer"
                    href="https://taster.orca.punwave.com/"
                  >
                    前往查看
                  </a>
                </h6>
              </div>
              <div
                className="d-flex h-87 card scroller"
                style={{ overflowY: 'auto', overflowX: 'hidden' }}
              >
                <h6 className="m-auto text-orcaLight">
                  <FontAwesomeIcon icon={faCircleInfo} />
                  &ensp;目前尚無資料，
                  <a
                    className="text-orcaLink2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://taster.orca.punwave.com/"
                  >
                    點擊此處
                  </a>
                  以開通此服務。
                </h6>
              </div>
            </Col>
          </>
        ) : specificApplication.application.status === 'not yet' ? (
          <div className="d-flex h-95">
            <div className="m-auto">
              <span className="d-flex text-orcaMid">
                <FontAwesomeIcon
                  icon={faSmile}
                  className="ms-auto"
                  style={{
                    height: '3.5rem',
                  }}
                />
                <FontAwesomeIcon
                  icon={faHandSparkles}
                  className="my-auto me-auto"
                  style={{
                    height: '2.75rem',
                    transform: 'rotate(35deg)',
                  }}
                />
              </span>
              <h4 className="fw-bolder text-center text-orcaMid pt-4 pb-4 lh-md">
                Hi, {settings.state.authData.email.split('@')[0]},
                <br />
                立即點擊下方按鈕進行申請吧!
              </h4>
              <span className="d-flex pb-3">
                <Link
                  className="noDeco mx-auto"
                  to="/orca/application/application-main"
                >
                  <Button size="md" variant="orca">
                    <FontAwesomeIcon
                      className="pe-1"
                      icon={faCircleChevronRight}
                    />
                    開戶申請
                  </Button>
                </Link>
              </span>
            </div>
          </div>
        ) : specificApplication.application.status ? (
          <div className="h-95 w-100">
            <Row className="d-flex w-100">
              <Col
                xs={12}
                className="fw-bolder text-center text-orcaMid pt-4 h4 mb-0"
              >
                Hi, {settings.state.authData.email.split('@')[0]}, 歡迎回到 ORCA
                BM !
              </Col>
            </Row>
            <Row className="w-95 mx-auto pt-4">
              <Col
                xs={12}
                className="h6 text-center text-orcaLight mt-auto px-0"
              >
                您的開戶申請進度
              </Col>
            </Row>
            {/* 工單審核進度條 */}
            {specificApplication.application.status !== 'not yet' && (
              <>
                <hr className="my-0" style={{ border: '1px dashed #6f6fb1' }} />
                <Row className="d-flex mx-auto w-80 h-65 fw-bolder text-orcaWhite">
                  <Col
                    className={`d-flex my-auto px-0 ${
                      (specificApplication.application.status === 'pending' ||
                        specificApplication.application.status ===
                          'under review' ||
                        specificApplication.application.status ===
                          'incomplete' ||
                        specificApplication.application.status === 'approved' ||
                        isFormalData) &&
                      'text-orcaMid fw-bolder'
                    }`}
                  >
                    <h5 className="mx-auto mb-0">
                      <span className="d-flex">
                        <FontAwesomeIcon
                          icon={faSpinner}
                          className="mx-auto"
                          style={{ height: '3rem' }}
                        />
                      </span>
                      <br />
                      待審核
                    </h5>
                  </Col>
                  <Col
                    className={`d-flex my-auto px-0 ${
                      (specificApplication.application.status ===
                        'under review' ||
                        specificApplication.application.status ===
                          'incomplete' ||
                        specificApplication.application.status === 'approved' ||
                        isFormalData) &&
                      'text-orcaMid fw-bolder'
                    }`}
                  >
                    <FontAwesomeIcon
                      className="my-auto me-auto h4"
                      icon={faAngleRight}
                    />
                    <h5 className="me-auto mb-0">
                      <span className="d-flex">
                        <FontAwesomeIcon
                          icon={faEnvelopeOpenText}
                          className="mx-auto"
                          style={{ height: '3rem' }}
                        />
                      </span>
                      <br />
                      審核中
                    </h5>
                  </Col>
                  <Col className="d-flex my-auto px-0">
                    <FontAwesomeIcon
                      className={`my-auto me-auto h4 ${
                        (specificApplication.application.status ===
                          'incomplete' ||
                          specificApplication.application.status ===
                            'approved' ||
                          isFormalData) &&
                        'text-orcaMid fw-bolder'
                      }`}
                      icon={faAngleRight}
                    />
                    <h5
                      className={`me-auto mb-0 ${
                        (specificApplication.application.status ===
                          'incomplete' ||
                          specificApplication.application.status ===
                            'approved' ||
                          isFormalData) &&
                        'text-orcaMid fw-bolder'
                      }`}
                    >
                      <span className="d-flex">
                        <FontAwesomeIcon
                          icon={faTriangleExclamation}
                          className="mx-auto"
                          style={{ height: '3rem' }}
                        />
                      </span>
                      <br />
                      退件/未完成
                    </h5>
                  </Col>
                  <Col
                    className={`d-flex my-auto px-0 ${
                      (specificApplication.application.status === 'approved' ||
                        isFormalData) &&
                      'text-orcaMid fw-bolder'
                    }`}
                  >
                    <FontAwesomeIcon
                      className="my-auto me-auto h4"
                      icon={faAngleRight}
                    />
                    <h5 className="me-auto mb-0">
                      <span className="d-flex">
                        <FontAwesomeIcon
                          icon={faFileCircleCheck}
                          className="m-auto"
                          style={{ height: '3rem' }}
                        />
                      </span>
                      <br />
                      審核完成
                    </h5>
                  </Col>
                  <Col
                    className={`d-flex my-auto px-0 ${
                      (specificApplication.application.status === 'approved' ||
                        isFormalData) &&
                      'text-orcaMid fw-bolder'
                    }`}
                  >
                    <FontAwesomeIcon
                      className="my-auto me-auto h4"
                      icon={faAngleRight}
                    />
                    <div className="me-auto mb-0 h5">
                      <span className="d-flex">
                        <FontAwesomeIcon
                          icon={faCloudArrowUp}
                          className="mx-auto"
                          style={{ height: '3rem' }}
                        />
                      </span>
                      <br />
                      正式檔案上傳
                      <br />
                      <h6 className="position-absolute px-4 pt-1">
                        {specificApplication.application.status ===
                        'formal data pending' ? (
                          <span style={{ color: '#7013eb' }}>
                            <FontAwesomeIcon icon={faHourglassHalf} />
                            &nbsp;待審核
                          </span>
                        ) : specificApplication.application.status ===
                          'formal data incomplete' ? (
                          <span className="text-red">
                            <FontAwesomeIcon icon={faCircleExclamation} />
                            &nbsp;待修正
                          </span>
                        ) : specificApplication.application.status ===
                          'approved' ? (
                          <span className="text-red">
                            <FontAwesomeIcon icon={faCircleExclamation} />
                            &nbsp;未上傳
                          </span>
                        ) : (
                          ''
                        )}
                      </h6>
                    </div>
                  </Col>
                  <Col
                    className={`d-flex my-auto px-0 ${
                      settings.state.authData.status === 'authorized' &&
                      'text-orcaMid fw-bolder'
                    }`}
                  >
                    <FontAwesomeIcon
                      className="my-auto me-auto h4"
                      icon={faAngleRight}
                    />
                    <div className="me-auto mb-0 h5">
                      <span className="d-flex">
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          className="mx-auto"
                          style={{ height: '3rem' }}
                        />
                      </span>
                      <br />
                      帳戶已開通
                    </div>
                  </Col>
                </Row>
                <hr className="my-0" style={{ border: '1px dashed #6f6fb1' }} />
                <Row className="w-100 text-center py-3 mx-0">
                  {specificApplication.application.status === 'approved' ? (
                    <Link
                      className="d-flex noDeco"
                      to="/orca/formal/formal-main"
                    >
                      <Button
                        variant="outline-orca"
                        className="m-auto"
                        size="md"
                      >
                        <FontAwesomeIcon
                          className="pe-1 fw-bolder"
                          icon={faCircleChevronRight}
                        />
                        前往上傳
                      </Button>
                    </Link>
                  ) : (
                    <Link
                      className="d-flex noDeco"
                      to="/orca/application/check-application"
                    >
                      <Button
                        variant="outline-orca"
                        className="m-auto"
                        size="md"
                      >
                        <FontAwesomeIcon
                          className="pe-1 fw-bolder"
                          icon={faCircleChevronRight}
                        />
                        上傳檔案
                      </Button>
                    </Link>
                  )}
                </Row>
              </>
            )}
          </div>
        ) : (
          <div className="d-flex flex-column justify-content-center h-100 w-100">
            <Row
              className="d-flex w-100"
              style={{
                paddingLeft: '30px',
              }}
            >
              <Spinner
                className="m-auto p-0"
                // size="lg"
                style={{
                  height: '60px',
                  width: '60px',
                }}
                animation="border"
                variant="orcaMid"
              />
            </Row>
            <Row>
              <h4 className="text-center text-orcaMid pt-4">資料載入中</h4>
            </Row>
          </div>
        )}
      </Row>
      <Footer />
    </Container>
  )
}
