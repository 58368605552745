/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Navbar from '../../components/shared/Navbar'
import Footer from '../../components/shared/Footer'
import Toolbar from '../../components/shared/Toolbar'
import FormalDataUpload from './FormalDataUpload'
import SubmitSuccess from './SubmitSuccess'

export default function FormalMain({ settings }) {
  const [step, setStep] = useState(0)
  const forms = {
    0: <FormalDataUpload setStep={setStep} />,
    1: <SubmitSuccess setStep={setStep} />,
  }

  return (
    <Container fluid="true" className="pt-2 pb-3 px-5 mb-5 h-100 w-100">
      <Navbar settings={settings} />
      <Toolbar settings={settings} />
      <Row
        className="d-flex card h-78 mb-2 px-4 h6"
        style={{ flexDirection: 'row' }}
      >
        <h5 className="h-5 w-100 text-orcaMid text-center mt-auto">
          正式檔案上傳與更新
        </h5>
        <Row className="h-85 w-100 px-4 mx-0">
          <Col xs={12} className="d-flex card h-100">
            {forms[step]}
          </Col>
        </Row>
      </Row>
      <Footer />
    </Container>
  )
}
