import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { useNavigate } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Spinner from 'react-bootstrap/Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faLink } from '@fortawesome/free-solid-svg-icons'
import { apiAuthInstance } from '../../apis/backend'
import LoadingButton from '../../components/shared/LoadingButton'
import ModalSubmitWarning from '../../components/modal/ModalSubmitWarning'
import TableList from '../../components/shared/TableList'
import Navbar from '../../components/shared/Navbar'
import Footer from '../../components/shared/Footer'
import Toolbar from '../../components/shared/Toolbar'
import ModalApiStatus from '../../components/modal/ModalApiStatus'

export default function AdminTcloud({ settings }) {
  const navigate = useNavigate()

  const mails = {
    'ORCA BM 註冊通知信': {
      mail_topic: 'ORCA BM 註冊通知信',
      mail_content: [
        {
          field: 'title',
          type: 'fixed',
          value:
            '您好，系統已確認您的款項，請依系統指示流程進行 ORCA BM 帳戶註冊。',
        },
        { field: 'bm_account', type: 'text', value: `帳號: ` },
        { field: 'bm_password', type: 'text', value: `密碼: ` },
      ],
    },
    'ORCA BM 付款通知信': {
      mail_topic: 'ORCA BM 付款通知信',
      mail_content: [
        {
          field: 'title',
          type: 'fixed',
          value:
            '您好，以下為您的 ORCA BM 付款資訊，並請於付款後稍待付款狀態更新，謝謝。',
        },
        { field: 'bank_code', type: 'text', value: `銀行代碼: (012)` },
        { field: 'v_account', type: 'text', value: `虛擬賬戶: ` },
        { field: 'v_amount', type: 'text', value: `交易金額: ` },
      ],
    },
    'ORCA BM 付款異常通知信': {
      mail_topic: 'ORCA BM 付款異常通知信',
      mail_content: [
        {
          field: 'title',
          type: 'fixed',
          value:
            '您好，系統接已收到您的付款，但付款內容異常，請檢查付款賬戶及金額是否有誤。',
        },
        { field: 'error_type', type: 'text', value: `錯誤原因: ` },
      ],
    },
  }

  const initSpecificApplicationData = {
    application: {
      status: '',
      remark: '',
      is_authorized: false,
    },
    applicant_data: {
      user: '',
      company_name: '',
      oca_name: '',
      contact_number: '',

      mailchimp_addr_address: '',
      mailchimp_addr_city: '',
      mailchimp_addr_state: '',
      mailchimp_addr_zip_code: '',
      mailchimp_addr_country: '',

      selected_template: '',
      mail_topic: '【ORCA BM 系統通知】ORCA 開通完成',
      mail_content: [
        {
          field: 'title',
          type: 'fixed',
          value: '您已成功開通 ORCA! 請用以下帳號密碼登入。',
        },
        { field: 'account', type: 'text', value: `帳號：` },
        { field: 'password', type: 'text', value: `密碼：` },
      ],
      account: '',
      password: '',

      bank_code: '',
      v_account: '',
      v_amount: '',

      error_type: '',
    },

    // tclouddata
    tax_id: '',
    email: '',
    full_cname: '',
    argo_project_id: '',
    argo_project_name: '',
  }
  const initModalAction = {
    type: '',
    targetId: undefined,
  }
  const initModal = {
    action: initModalAction,
    loaded: true,
    show: false,
    addtcloudModalShow: false,
    warningModalShow: false,
    paymentModalShow: false,
    accountModalShow: false,
    notificationModalShow: false,
    settingsModalShow: false,
  }
  const initApiStatus = {
    status: '',
    message: '',
    apiStatusModalShow: false,
  }
  const [modal, setModal] = useState(initModal)
  const [specificApplication, setSpecificApplication] = useState(
    initSpecificApplicationData
  )
  const [apiStatus, setApiStatus] = useState(initApiStatus)
  const [applications, setApplications] = useState(false)
  const [tradingData, setTradingData] = useState([])

  const [isFormalData, setIsFormalData] = useState(false)
  const [applicationId, setApplicationId] = useState(-1)

  const [argoProjects, setArgoProjects] = useState([])

  const [newEmail, setNewEmail] = useState('')

  async function putPendingApplicationStatus(id) {
    await apiAuthInstance({
      url: 'applications/client-application/',
      method: 'put',
      data: {
        confirmation: {
          status: specificApplication.application.status,
        },
      },
      params: {
        application_id: id,
      },
    })
      .then(() => {})
      .catch(() => {})
  }

  async function getApplications() {
    await apiAuthInstance({
      url: 'payment/tcloud-client/',
      method: 'get',
    })
      .then((response) => {
        // console.log(response)
        setApplications(response.data.data)
      })
      .catch((error) => {
        switch (error.response.status) {
          case 401:
            navigate('/home')
            break
          default:
            break
        }
      })
  }
  async function putTcloudClient(id) {
    await apiAuthInstance({
      url: `payment/tcloud-client/${id}/`,
      method: 'put',
      data: {
        email: newEmail,
      },
    })
      .then((response) => {
        setModal((prevState) => ({
          ...prevState,
          loaded: true,
          show: false,
          warningModalShow: false,
        }))
        setApiStatus({
          status: response.data.status,
          message: response.data.message,
          apiStatusModalShow: true,
        })
        getApplications()
      })
      .catch((error) => {
        setApiStatus({
          status: error.response.data.status,
          message: error.response.data.message,
          apiStatusModalShow: true,
        })
      })
  }
  async function getSpecificApplication(id) {
    await apiAuthInstance({
      url: `payment/tcloud-client/${id}/`,
      method: 'get',
    })
      .then((response) => {
        console.log(response.data.data)
        setTradingData(response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  async function putApplicationStatus() {
    switch (modal.action.type) {
      case 'addtcloud':
        await apiAuthInstance({
          url: 'payment/tcloud-client/',
          method: 'post',
          data: {
            email: specificApplication.applicant_data.email,
            tax_id: specificApplication.applicant_data.tax_id,
            full_cname: specificApplication.full_cname,
            argo_project_id: specificApplication.argo_project_id,
            argo_project_name: specificApplication.argo_project_name,
          },
        })
          .then((response) => {
            // setApplications(applications.concat(response.data.data))
            setApiStatus({
              status: response.data.status,
              message: response.data.message,
              apiStatusModalShow: true,
            })
            getApplications()
          })
          .catch((error) => {
            setApiStatus({
              status: error.response.data.status,
              message: error.response.data.message,
              apiStatusModalShow: true,
            })
          })
        break
      default:
        break
    }
  }

  const handleModalShow = async (event) => {
    let selectedApplicationData
    switch (event.target.name) {
      case 'notification':
        selectedApplicationData = applications.find(
          (application) => application.id === parseInt(event.target.value, 10)
        )
        // temp deal with undefined
        if (selectedApplicationData) {
          await getSpecificApplication(selectedApplicationData.id)
          setApplicationId(selectedApplicationData.id)
        }
        setSpecificApplication({
          ...specificApplication,
          applicant_data: {
            ...specificApplication.applicant_data,
            ...mails[selectedApplicationData.status],
            selected_template: selectedApplicationData.status,
          },
        })
        setModal((prevState) => ({
          ...prevState,
          // change some action
          action: {
            ...initModalAction,
            type: 'notification',
          },
          show: false,
          paymentModalShow: true,
        }))
        break
      case 'review':
        selectedApplicationData = applications.find(
          (application) => application.id === parseInt(event.target.value, 10)
        )
        if (selectedApplicationData) {
          await getSpecificApplication(selectedApplicationData.id)
          setApplicationId(selectedApplicationData.id)
        }
        setModal((prevState) => ({
          ...prevState,
          // change some action
          action: {
            ...initModalAction,
            type: 'review',
          },
          show: false,
          accountModalShow: true,
        }))
        break
      case 'addtcloud':
        selectedApplicationData = applications.find(
          (application) => application.id === parseInt(event.target.value, 10)
        )
        // temp deal with undefined
        if (selectedApplicationData) {
          await getSpecificApplication(selectedApplicationData.id)
          setApplicationId(selectedApplicationData.id)
        }
        setModal((prevState) => ({
          ...prevState,
          action: {
            ...initModalAction,
            type: 'addtcloud',
          },
          show: false,
          addtcloudModalShow: true,
        }))
        break
      case 'settings':
        selectedApplicationData = applications.find(
          (application) => application.id === parseInt(event.target.value, 10)
        )
        setNewEmail(selectedApplicationData.email)
        setApplicationId(selectedApplicationData.id)
        setModal((prevState) => ({
          ...prevState,
          action: {
            ...initModalAction,
            type: 'settings',
          },
          show: false,
          settingsModalShow: true,
        }))
        break
      case 'delete':
        break

      default:
        // pass
        break
    }
  }
  async function sendNotificationEmail(id) {
    await apiAuthInstance({
      url: `payment/notification/${id}/`,
      method: 'post',
      data: {
        applicant_data: specificApplication.applicant_data,
      },
    })
      .then((response) => {
        setModal((prevState) => ({
          ...prevState,
          loaded: true,
          show: false,
          warningModalShow: false,
          rejectModalShow: false,
        }))
        setApiStatus({
          status: response.data.status,
          message: response.data.message,
          apiStatusModalShow: true,
        })
      })
      .catch((error) => {
        setApiStatus({
          status: error.response.data.status,
          message: error.response.data.message,
          apiStatusModalShow: true,
        })
      })
  }

  const handleModalClose = () => {
    setSpecificApplication(initSpecificApplicationData)
    setModal((prevState) => ({
      ...prevState,
      action: initModalAction,
      show: false,
      warningModalShow: false,
      rejectModalShow: false,
      notificationModalShow: false,
      addtcloudModalShow: false,
      paymentModalShow: false,
      accountModalShow: false,
      settingsModalShow: false,
    }))
    setApiStatus(initApiStatus)
    setIsFormalData(false)
    setNewEmail('')
  }
  const handleModalSubmit = (event) => {
    switch (event.target.name) {
      case 'notification':
        setModal((prevState) => ({
          ...prevState,
          notificationModalShow: false,
          addtcloudModalShow: false,
          warningModalShow: true,
        }))
        break
      case 'review':
        setModal((prevState) => ({
          ...prevState,
          notificationModalShow: false,
          addtcloudModalShow: false,
          warningModalShow: true,
        }))
        break
      case 'addtcloud':
        setModal((prevState) => ({
          ...prevState,
          notificationModalShow: false,
          addtcloudModalShow: false,
          warningModalShow: true,
        }))
        break
      case 'settings':
        setModal((prevState) => ({
          ...prevState,
          notificationModalShow: false,
          settingsModalShow: false,
          warningModalShow: true,
        }))
        break
      default:
        // pass
        break
    }
  }

  const handleWarningModalCancel = (event) => {
    switch (event.target.name) {
      default:
        setModal((prevState) => ({
          ...prevState,
          show: false,
          warningModalShow: false,
        }))
        break
    }
  }

  const handleWarningModalSubmit = () => {
    console.log(modal.action.type)
    switch (modal.action.type) {
      case 'addtcloud':
        putApplicationStatus()
        setModal((prevState) => ({
          ...prevState,
          show: false,
          warningModalShow: false,
        }))
        break
      case 'notification':
        sendNotificationEmail(applicationId)
        setModal((prevState) => ({
          ...prevState,
          show: false,
          warningModalShow: false,
          notificationModalShow: false,
        }))
        break
      case 'settings':
        putTcloudClient(applicationId)
        setModal((prevState) => ({
          ...prevState,
          show: false,
          warningModalShow: false,
          settingsModalShow: false,
        }))
        break
      default:
        // pass
        break
    }
  }
  useEffect(() => {
    getApplications()
  }, [])
  useEffect(() => {
    if (specificApplication.application.status === 'pending' && !isFormalData) {
      putPendingApplicationStatus(applicationId)
    }
  }, [specificApplication])

  const handleApplicantDataChange = (e) =>
    setSpecificApplication({
      ...specificApplication,
      applicant_data: {
        ...specificApplication.applicant_data,
        [e.target.name]: e.target.value,
      },
    })

  const getArgoProjects = async () => {
    await apiAuthInstance({
      url: 'payment/argo-project/',
      method: 'get',
      params: {
        tax_id: specificApplication.applicant_data.tax_id,
      },
    })
      .then((response) => {
        console.log(response)
        setArgoProjects(response.data.data.projects)
        setSpecificApplication({
          ...specificApplication,
          full_cname: response.data.data.full_cname,
        })
        // setApplications(response.data.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  return (
    <Container fluid="true" className="pt-2 pb-3 px-5 mb-5 h-100 w-100">
      <Navbar settings={settings} />
      <Toolbar settings={settings} />
      <ModalSubmitWarning
        settings={{
          submitButton: {
            name: modal.action.type,
            value: modal.action.targetId,
            content: '送出',
            loaded: modal.loaded,
          },
          warningModalShow: modal.warningModalShow,
          handleWarningModalCancel,
          handleModalSubmit: handleWarningModalSubmit,
          handleModalClose,
          warningMessageType: modal.action.type,
        }}
      />
      <ModalApiStatus
        settings={{
          content: {
            status: apiStatus.status,
            message: apiStatus.message,
          },
          apiStatusModalShow: apiStatus.apiStatusModalShow,
          handleModalClose,
        }}
      />
      <Row className="d-flex card h-78 mb-2 h6 tableList position-relative">
        {/* 懸浮按鈕 - Add Tcloud data */}
        <Button
          style={{ position: 'absolute', right: '2%', bottom: '8%' }}
          variant="orca"
          size="md"
          name="addtcloud"
          className="d-block w-auto rounded-pill"
          title="建立雲市集客戶資料"
          onClick={handleModalShow}
        >
          <FontAwesomeIcon icon={faCirclePlus} className="me-2" />
          建立雲市集客戶資料
        </Button>

        {applications ? (
          <TableList
            settings={{
              tableConfig: [
                {
                  head: '名稱',
                  dataType: 'text',
                  fieldName: 'full_cname',
                },
                {
                  head: 'Email',
                  dataType: 'text-center',
                  fieldName: 'email',
                },
                {
                  head: '專案名稱',
                  dataType: 'text-center',
                  fieldName: 'argo_project_name',
                },
                {
                  head: '交易狀態',
                  dataType: 'text-center',
                  fieldName: 'status',
                },
                {
                  head: '銀行代碼',
                  dataType: 'text-center',
                  fieldName: 'bank_code',
                },
                {
                  head: '虛擬賬戶',
                  dataType: 'text-copy-center',
                  fieldName: 'account_number',
                },
                {
                  head: '功能列',
                  dataType: 'tcloud',
                  fieldAsValue: 'id',
                  onReviewClick: handleModalShow,
                  onNotificationClick: handleModalShow,
                },
              ],
              data: applications,
              size: 10,
              pagination: true,
            }}
          />
        ) : (
          <div className="d-flex flex-column justify-content-center h-100 w-100">
            <Row
              className="d-flex w-100"
              style={{
                paddingLeft: '30px',
              }}
            >
              <Spinner
                className="m-auto p-0"
                style={{
                  height: '60px',
                  width: '60px',
                }}
                animation="border"
                variant="orcaMid"
              />
            </Row>
            <Row>
              <h4 className="text-center text-orcaMid pt-4">資料載入中</h4>
            </Row>
          </div>
        )}
      </Row>

      {/* 更新收件Email */}
      <Modal show={modal.settingsModalShow} size="md">
        <Modal.Header closeButton onHide={handleModalClose}>
          <Modal.Title className="px-4 fs-5">設定</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <div className="w-100 text-center text-orca py-3">
            <FontAwesomeIcon icon={faLink} style={{ fontSize: '5rem' }} />
          </div>
          <Form className="w-100">
            <Form.Group className="mb-3 w-100" controlId="">
              <Form.Label className="mb-0 text-orca h6">更新Email</Form.Label>
              <div className="d-flex">
                <Form.Control
                  className="w-75"
                  type="text"
                  placeholder="新的收件Email"
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex px-5">
          <div className="d-flex mx-auto">
            <Button variant="secondary" onClick={handleModalClose}>
              取消
            </Button>
            <Button
              variant="orca"
              className="mx-2"
              name="settings"
              onClick={handleModalSubmit}
            >
              確定
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* 寄件功能 */}
      <Modal show={modal.notificationModalShow} size="md">
        <Modal.Header closeButton onHide={handleModalClose}>
          <Modal.Title className="px-4 fs-5">寄件功能</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex px-5">
          <Row className="d-flex h-100 w-100 m-0 text-orca">
            <Form.Select
              size="sm"
              onChange={(e) => {
                const value = JSON.parse(e.target.value)
                setSpecificApplication({
                  ...specificApplication,
                  applicant_data: {
                    ...specificApplication.applicant_data,
                    mail_topic: value.mail_topic,
                    mail_content: value.mail_content,
                  },
                })
              }}
            >
              <option
                disabled
                value={JSON.stringify({
                  mail_topic: '',
                  mail_content: [{ field: '', type: 'text', value: '' }],
                })}
              >
                選擇信件模板
              </option>
              <option
                value={JSON.stringify({
                  mail_topic: '【ORCA BM 系統通知】ORCA 開通完成',
                  mail_content: [
                    {
                      field: 'title',
                      type: 'fixed',
                      value: '您已成功開通 ORCA! 請用以下帳號密碼登入。',
                    },
                    { field: 'account', type: 'text', value: `帳號：` },
                    { field: 'password', type: 'text', value: `密碼：` },
                  ],
                })}
              >
                ORCA 開通完成
              </option>
              <option
                value={JSON.stringify({
                  mail_topic: '',
                  mail_content: [{ field: '', type: 'text', value: '' }],
                })}
              >
                其他
              </option>
            </Form.Select>
            <h6 className="oneLineLimit text-orcaMid my-auto pt-3 px-0">
              收件人: {specificApplication.applicant_data.applicant}
            </h6>
            <hr
              className="my-2 w-100"
              style={{ border: '1px dashed #6f6fb1' }}
            />

            <Form.Group className="d-flex w-100 py-2 px-0">
              <Form.Control
                placeholder="主旨"
                type="text"
                size="sm"
                value={specificApplication.applicant_data.mail_topic}
                onChange={(e) =>
                  setSpecificApplication({
                    ...specificApplication,
                    applicant_data: {
                      ...specificApplication.applicant_data,
                      mail_topic: e.target.value,
                    },
                  })
                }
              />
            </Form.Group>
            <Form.Control
              placeholder="內容"
              as="textarea"
              size="sm"
              style={{ height: '13rem' }}
              value={specificApplication.applicant_data.mail_content
                .map(
                  (f) =>
                    `${f.value}${
                      specificApplication.applicant_data[f.field] || ''
                    }`
                )
                .join('\n')}
              onChange={(e) => {
                const values = {}
                specificApplication.applicant_data.mail_content.forEach(
                  (f, i) => {
                    if (f.type !== 'fixed')
                      values[f.field] = e.target.value
                        .split('\n')
                        [i].substring(f.value.length)
                  }
                )
                if (Object.keys(values).length > 0)
                  setSpecificApplication({
                    ...specificApplication,
                    applicant_data: {
                      ...specificApplication.applicant_data,
                      ...values,
                    },
                  })
              }}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex px-5">
          <div className="d-flex mx-auto">
            <Button variant="secondary" onClick={handleModalClose}>
              取消
            </Button>
            <span className="ps-2">
              <LoadingButton
                settings={{
                  name: modal.action.type,
                  value: modal.action.targetId,
                  content: '送出',
                  loaded: modal.loaded,
                  onClick: handleModalSubmit,
                }}
              />
            </span>
          </div>
        </Modal.Footer>
      </Modal>

      {/* 新建雲市集客戶資料 */}
      <Modal show={modal.addtcloudModalShow} size="md">
        <Modal.Header closeButton onHide={handleModalClose}>
          <Modal.Title className="px-4 fs-5">新建雲市集客戶資料</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex px-5 py-4 w-100">
          <Form className="w-100">
            <Form.Group className="mb-3 w-100" controlId="">
              <Form.Label className="mb-0 text-orca h6">
                客戶Email (通知信將寄發至此信箱)
              </Form.Label>
              <Form.Control
                type="text"
                name="email"
                onChange={handleApplicantDataChange}
                value={specificApplication.applicant_data.email}
                placeholder=""
              />
            </Form.Group>
            <Form.Group className="mb-3 w-100" controlId="">
              <Form.Label className="mb-0 text-orca h6">客戶統編</Form.Label>
              <div className="d-flex">
                <Form.Control
                  className="w-75"
                  type="text"
                  name="tax_id"
                  onChange={handleApplicantDataChange}
                  value={specificApplication.applicant_data.tax_id}
                  placeholder=""
                />
                <Button
                  variant="orca2"
                  onClick={getArgoProjects}
                  className="w-25 ms-1"
                >
                  尋找專案
                </Button>
              </div>
            </Form.Group>
            <Form.Group
              className="mb-0 text-orca h6"
              controlId="formBasicSegment"
            >
              <Form.Group className="mb-3 w-100" controlId="">
                <Form.Label className="pt-3 mb-0 text-orca h6">
                  客戶抬頭
                </Form.Label>
                <Form.Control
                  type="text"
                  name="full_cname"
                  onChange={handleApplicantDataChange}
                  value={specificApplication.full_cname}
                  placeholder=""
                  disabled
                />
              </Form.Group>
              <Form.Label className="mb-1">選擇專案</Form.Label>
              <Select
                // styles={purpleSelector}
                value={{
                  value: specificApplication.argo_project_id,
                  label: specificApplication.argo_project_name,
                }}
                placeholder=""
                closeMenuOnSelect
                name=""
                // value={{
                //   value: 't',
                //   label: '',
                // }}
                // components={{ Option: TitledOptions, Control: TitledControl }}
                onChange={(event) => {
                  setSpecificApplication({
                    ...specificApplication,
                    argo_project_id: event.value,
                    argo_project_name: event.label,
                  })
                }}
                options={argoProjects.map((project) => ({
                  value: project.id,
                  label: project.name,
                }))}
              />
            </Form.Group>

            {/* <Form.Group className="mb-3 w-100" controlId="">
              <Form.Label className="mb-0 text-orca h6">客戶代號</Form.Label>
              <Form.Control
                type="text"
                // value={specificApplication.applicant_data.email}
                placeholder=""
              />
            </Form.Group> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex mx-auto">
            <Button variant="secondary" onClick={handleModalClose}>
              取消
            </Button>
            <span className="ps-2">
              <LoadingButton
                settings={{
                  name: modal.action.type,
                  value: modal.action.targetId,
                  content: '確認',
                  loaded: modal.loaded,
                  onClick: handleModalSubmit,
                }}
              />
            </span>
          </div>
        </Modal.Footer>
      </Modal>

      {/* 交易紀錄 log */}
      <Modal show={modal.accountModalShow} size="lg">
        <Modal.Header closeButton onHide={handleModalClose}>
          <Modal.Title className="px-4 fs-5">交易紀錄</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column p-0 h-100 tableList tableList_log">
          <TableList
            settings={{
              tableConfig: [
                {
                  head: '交易時間',
                  dataType: 'datetime-tz+8',
                  fieldName: 'trade_date',
                },
                {
                  head: '金額',
                  dataType: 'text-center',
                  fieldName: 'amount',
                },
                // {
                //   head: '狀態',
                //   dataType: 'text-center',
                //   fieldName: 'virtual_acc',
                // },
              ],
              data: tradingData,
              size: 10,
              pagination: true,
            }}
          />
        </Modal.Body>
      </Modal>

      {/* 付款資料 */}
      <Modal show={modal.paymentModalShow} size="md">
        <Modal.Header closeButton onHide={handleModalClose}>
          <Modal.Title className="px-4 fs-5">寄件功能</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex px-5">
          交易狀態: {specificApplication.status || '等待API'}
        </Modal.Body>
        <Modal.Body className="d-flex px-5">
          <Row className="d-flex h-100 w-100 m-0 text-orca">
            <Form.Select
              size="sm"
              name="selected_template"
              value={specificApplication.applicant_data.selected_template}
              onChange={(e) => {
                handleApplicantDataChange(e)
                if (mails[e.target.value]) {
                  setSpecificApplication({
                    ...specificApplication,
                    applicant_data: {
                      ...specificApplication.applicant_data,
                      ...mails[e.target.value],
                      [e.target.name]: e.target.value,
                    },
                  })
                }
              }}
            >
              <option disabled value="">
                選擇信件模板
              </option>
              {Object.keys(mails).map((key) => (
                <option value={key}>{key}</option>
              ))}
              <option value="other">其他</option>
            </Form.Select>
            <h6 className="oneLineLimit text-orcaMid my-auto pt-3 px-0">
              收件人: {specificApplication.applicant_data.applicant}
            </h6>
            <hr
              className="my-2 w-100"
              style={{ border: '1px dashed #6f6fb1' }}
            />

            <Form.Group className="d-flex w-100 py-2 px-0">
              <Form.Control
                placeholder="主旨"
                type="text"
                size="sm"
                value={specificApplication.applicant_data.mail_topic}
                onChange={(e) =>
                  setSpecificApplication({
                    ...specificApplication,
                    applicant_data: {
                      ...specificApplication.applicant_data,
                      mail_topic: e.target.value,
                    },
                  })
                }
              />
            </Form.Group>
            <Form.Control
              placeholder="內容"
              as="textarea"
              size="sm"
              style={{ height: '13rem' }}
              value={specificApplication.applicant_data.mail_content
                .map(
                  (f) =>
                    `${f.value}${
                      specificApplication.applicant_data[f.field] || ''
                    }`
                )
                .join('\n')}
              onChange={(e) => {
                const values = {}
                specificApplication.applicant_data.mail_content.forEach(
                  (f, i) => {
                    if (f.type !== 'fixed')
                      values[f.field] = e.target.value
                        .split('\n')
                        [i].substring(f.value.length)
                  }
                )
                if (Object.keys(values).length > 0)
                  setSpecificApplication({
                    ...specificApplication,
                    applicant_data: {
                      ...specificApplication.applicant_data,
                      ...values,
                    },
                  })
              }}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex px-5">
          <div className="d-flex mx-auto">
            <Button variant="secondary" onClick={handleModalClose}>
              取消
            </Button>
            <span className="ps-2">
              <LoadingButton
                settings={{
                  name: modal.action.type,
                  value: modal.action.targetId,
                  content: '送出',
                  loaded: modal.loaded,
                  onClick: handleModalSubmit,
                }}
              />
            </span>
          </div>
        </Modal.Footer>
      </Modal>
      <Footer />
    </Container>
  )
}
