import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

// custom compoment
import LoadingButton from '../shared/LoadingButton'

export default function ModalSubmitWarning(props) {
  // warningModalShow
  // onPrePageClick
  // onSubmitClick
  // warningMessageType
  const { settings } = props

  function warningMessage(action) {
    let message = ''
    switch (action) {
      case 'create':
        message = '確定要提交新增嗎？'
        break
      case 'edit':
        message = '確定要提交編輯嗎？'
        break
      case 'delete':
        message = '確定要提交刪除嗎？'
        break
      case 'maSubmit':
        message = '確認要提交申請資料嗎？'
        break
      case 'reject':
        message = '確認要退回申請嗎？'
        break
      default:
        message = '確定要提交嗎？'
        break
    }
    return message
  }
  return (
    <Modal show={settings.warningModalShow}>
      <Modal.Header closeButton onHide={settings.handleModalClose} />
      <Modal.Body>
        <div className="d-flex my-3">
          <FontAwesomeIcon
            style={{ fontSize: '5rem' }}
            className="text-orca m-auto"
            icon={faTriangleExclamation}
          />
        </div>
        <div>
          <h5 className="text-center text-orca pt-2 pb-4 lh-lg">
            {warningMessage(settings.warningMessageType)}
          </h5>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button
          variant="secondary"
          name={settings.warningMessageType}
          onClick={settings.handleWarningModalCancel}
        >
          取消
        </Button>

        <LoadingButton
          settings={{
            ...settings.submitButton,
            onClick: settings.handleModalSubmit,
          }}
        />
      </Modal.Footer>
    </Modal>
  )
}
