import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { Container } from 'react-bootstrap'
import {
  faCaretLeft,
  faCaretRight,
  faFloppyDisk,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { apiAuthInstance } from '../../apis/backend'
import LoadingButton from '../../components/shared/LoadingButton'
import ModalSubmitWarning from '../../components/modal/ModalSubmitWarning'
import ModalFormConfirm from '../../components/modal/ModalFormConfirm'
import ModalApiStatus from '../../components/modal/ModalApiStatus'

export default function MaRequirement(props) {
  const { setStep } = props

  const initMaRequirementData = {
    contact_number: '', // 必填
    company_name: '',
    oca_name: '',

    mailchimp_reply_to: '',
    mailchimp_from_name: '',
    mailchimp_addr_address: '',
    mailchimp_addr_city: '',
    mailchimp_addr_state: '',
    mailchimp_addr_zip_code: '',
    mailchimp_addr_country: '',

    line_id: '',
    line_name: '',
    line_channel_access_token: '',
    line_channel_secret: '',

    facebook_app_id: '',
    facebook_app_secret: '',
  }
  const initPageAction = {
    type: 'maSubmit',
    targetId: undefined,
  }
  const initPage = {
    action: initPageAction,
    loaded: true,
    warningModalShow: false,
    formConfirmModalShow: false,
  }
  const initApiStatus = {
    status: '',
    message: '',
    apiStatusModalShow: false,
    redirect: false,
  }
  const [page, setPage] = useState(initPage)
  const [apiStatus, setApiStatus] = useState(initApiStatus)
  const [maRequirementData, setMaRequirementData] = useState(
    initMaRequirementData
  )
  const [isSaved, setIsSaved] = useState(false)
  async function getMaRequirement() {
    await apiAuthInstance({
      url: 'applications/requirement/',
      method: 'get',
      params: {
        data_type: 'marketing_automation',
      },
    })
      .then((response) => {
        setMaRequirementData(response.data.data)
      })
      .catch((error) => {
        setApiStatus({
          status: error.response.data.status,
          message: error.response.data.message,
          apiStatusModalShow: true,
          redirect: true,
        })
      })
  }
  async function postClientApplication() {
    setPage((prevState) => ({
      ...prevState,
      loaded: false,
    }))
    await apiAuthInstance({
      url: 'applications/client-application/',
      method: 'post',
    })
      .then(() => {})
      .catch((error) => {
        setPage(initPage)
        setApiStatus({
          status: error.response.data.status,
          message: error.response.data.message,
          apiStatusModalShow: true,
        })
      })
  }
  async function postMaRequirement() {
    setPage((prevState) => ({
      ...prevState,
      loaded: false,
    }))
    await apiAuthInstance({
      url: 'applications/requirement/',
      method: 'post',
      params: {
        data_type: 'marketing_automation',
      },
      data: maRequirementData,
    })
      .then((response) => {
        setApiStatus({
          status: response.data.status,
          message: response.data.message,
          apiStatusModalShow: true,
        })
        setPage(initPage)
        setIsSaved(true)
        postClientApplication()
        setStep(1)
        // navigate('/orca/application/sample-data/upload') // navigate to the next page
      })
      .catch((error) => {
        setPage(initPage)
        setApiStatus({
          status: error.response.data.status,
          message: error.response.data.message,
          apiStatusModalShow: true,
        })
      })
  }
  const handleModalClose = () => {
    setPage((prevState) => ({
      ...prevState,
      action: initPageAction,
      warningModalShow: false,
      formConfirmModalShow: false,
    }))
    setApiStatus(initApiStatus)
  }
  const handleModalSubmit = (event) => {
    switch (event.target.name) {
      case 'maSubmit':
        setPage((prevState) => ({
          ...prevState,
          // warningModalShow: true,
          formConfirmModalShow: true, // 先過資料檢查
        }))
        // setStep(1)
        break
      case 'confirm':
        setPage((prevState) => ({
          ...prevState,
          warningModalShow: true,
          formConfirmModalShow: false,
        }))
        // setStep(1)
        break
      case 'next':
        postClientApplication()
        setStep(1)
        break
      default:
        break
    }
  }
  const handleFormConfirmModalCancel = (event) => {
    switch (event.target.name) {
      case 'confirm':
        setPage((prevState) => ({
          ...prevState,
          formConfirmModalShow: false,
        }))
        break
      default:
        setPage((prevState) => ({
          ...prevState,
          formConfirmModalShow: false,
        }))
        break
    }
  }
  const handleWarningModalCancel = (event) => {
    switch (event.target.name) {
      case 'maSubmit':
        setPage((prevState) => ({
          ...prevState,
          warningModalShow: false,
        }))
        break
      default:
        setPage((prevState) => ({
          ...prevState,
          warningModalShow: false,
        }))
        break
    }
  }
  const handleWarningModalSubmit = (event) => {
    switch (event.target.name) {
      case 'maSubmit':
        postMaRequirement()
        break
      default:
        break
    }
  }
  useEffect(() => {
    getMaRequirement()
  }, [])
  return (
    <Container fluid="true" className="pt-2 pb-3 px-5 mb-5 h-100 w-100">
      <Row
        className="d-flex h-100 mb-0 px-0 h6"
        style={{ flexDirection: 'row', overflow: 'hidden' }}
      >
        <div className="d-flex h-85 scroller" style={{ overflowY: 'auto' }}>
          <Col xs={6} className="m-auto w-50 pt-3 px-4 mx-0 h-100">
            <h5 className="text-orcaLight fw-bold py-2">基本資料</h5>

            <Form.Group className="mb-3 w-100" controlId="formBasicTitle">
              <Form.Label className="d-flex mb-1">
                公司名稱
                <small className="text-red ms-auto mt-auto">＊必填</small>
              </Form.Label>
              <Form.Control
                className="m-auto"
                maxLength="20"
                name="companyName"
                type="text"
                placeholder="type the company name..."
                defaultValue={maRequirementData.company_name}
                onChange={(event) =>
                  setMaRequirementData({
                    ...maRequirementData,
                    company_name: event.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group className="w-100 mb-3" controlId="formBasicTitle">
              <Form.Label className="d-flex mb-1">
                帳戶名稱(OCA Name)
                <small className="text-red ms-auto mt-auto">＊必填</small>
              </Form.Label>
              <Form.Control
                maxLength="20"
                name="ocaName"
                type="text"
                placeholder="type the name you want to display in the OCA URL..."
                defaultValue={maRequirementData.oca_name}
                onChange={(event) =>
                  setMaRequirementData({
                    ...maRequirementData,
                    oca_name: event.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group className="w-100 mb-3" controlId="formBasicTitle">
              <Form.Label className="d-flex mb-1">
                聯絡電話
                <small className="text-red ms-auto mt-auto">＊必填</small>
              </Form.Label>
              <Form.Control
                required
                maxLength="20"
                name="contactNumber"
                type="text"
                defaultValue={maRequirementData.contact_number}
                onChange={(event) =>
                  setMaRequirementData({
                    ...maRequirementData,
                    contact_number: event.target.value,
                  })
                }
              />
            </Form.Group>

            {/* company address for mailchimp settings */}
            <Form.Group className="w-100 mb-3" controlId="formBasicTitle">
              <Form.Label className="mb-1">地址</Form.Label>
              <Form.Control
                maxLength="255"
                name="emailAddrAddress"
                type="text"
                defaultValue={maRequirementData.mailchimp_addr_address}
                onChange={(event) =>
                  setMaRequirementData({
                    ...maRequirementData,
                    mailchimp_addr_address: event.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group className="w-100 mb-3" controlId="formBasicTitle">
              <Form.Label className="mb-1">城市</Form.Label>
              <Form.Control
                maxLength="100"
                name="emailAddrCity"
                type="text"
                defaultValue={maRequirementData.mailchimp_addr_city}
                onChange={(event) =>
                  setMaRequirementData({
                    ...maRequirementData,
                    mailchimp_addr_city: event.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group className="w-100 mb-3" controlId="formBasicTitle">
              <Form.Label className="mb-1">鄉鎮市區</Form.Label>
              <Form.Control
                maxLength="100"
                name="emailAddrState"
                type="text"
                defaultValue={maRequirementData.mailchimp_addr_state}
                onChange={(event) =>
                  setMaRequirementData({
                    ...maRequirementData,
                    mailchimp_addr_state: event.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group className="w-100 mb-3" controlId="formBasicTitle">
              <Form.Label className="d-flex w-100 mb-1">
                郵遞區號
                {/* <small className="text-red ms-auto mt-auto">＊必填</small> */}
              </Form.Label>
              <Form.Control
                required
                // isInvalid={maRequirementData.mailchimp_addr_zip_code !== 0}
                maxLength="10"
                name="emailAddrZipCode"
                type="text"
                defaultValue={maRequirementData.mailchimp_addr_zip_code}
                onChange={(event) =>
                  setMaRequirementData({
                    ...maRequirementData,
                    mailchimp_addr_zip_code: event.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group className="w-100 mb-3" controlId="formBasicTitle">
              <Form.Label className="mb-1">國家</Form.Label>
              <Form.Control
                maxLength="100"
                name="emailAddrCountry"
                type="text"
                defaultValue={maRequirementData.mailchimp_addr_country}
                onChange={(event) =>
                  setMaRequirementData({
                    ...maRequirementData,
                    mailchimp_addr_country: event.target.value,
                  })
                }
              />
            </Form.Group>
          </Col>

          <Col xs={6} className="m-0 pt-3 px-4 h-85">
            <h5 className="text-orcaLight fw-bold py-2">EMAIL</h5>
            <Form.Group className="mb-3 w-100" controlId="formBasicTitle">
              <Form.Label className="mb-1">寄件者信箱</Form.Label>
              <Form.Control
                maxLength="255"
                name="emailReplyTo"
                type="text"
                placeholder="type the email address for the receiver's reply..."
                defaultValue={maRequirementData.mailchimp_reply_to}
                onChange={(event) =>
                  setMaRequirementData({
                    ...maRequirementData,
                    mailchimp_reply_to: event.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group className="w-100 mb-3" controlId="formBasicTitle">
              <Form.Label className="mb-1">寄件者名稱</Form.Label>
              <Form.Control
                maxLength="50"
                name="emailFromName"
                type="text"
                placeholder="type the name that will show on the email campaign..."
                defaultValue={maRequirementData.mailchimp_from_name}
                onChange={(event) =>
                  setMaRequirementData({
                    ...maRequirementData,
                    mailchimp_from_name: event.target.value,
                  })
                }
              />
            </Form.Group>

            <div className="m-0">
              <h5 className="text-orcaLight fw-bold py-2">LINE</h5>
              <Form.Group className="mb-3 w-100" controlId="formBasicTitle">
                <Form.Label className="mb-1">Line Channel ID</Form.Label>
                <Form.Control
                  maxLength="255"
                  name="lineId"
                  type="text"
                  defaultValue={maRequirementData.line_id}
                  onChange={(event) =>
                    setMaRequirementData({
                      ...maRequirementData,
                      line_id: event.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="w-100 mb-3" controlId="formBasicTitle">
                <Form.Label className="mb-1">Line Name</Form.Label>
                <Form.Control
                  maxLength="50"
                  name="lineName"
                  type="text"
                  defaultValue={maRequirementData.line_name}
                  onChange={(event) =>
                    setMaRequirementData({
                      ...maRequirementData,
                      line_name: event.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="w-100 mb-3" controlId="formBasicTitle">
                <Form.Label className="mb-1">
                  Line Channel Access Token
                </Form.Label>
                <Form.Control
                  maxLength="255"
                  name="lineChannelAccessToken"
                  type="text"
                  defaultValue={maRequirementData.line_channel_access_token}
                  onChange={(event) =>
                    setMaRequirementData({
                      ...maRequirementData,
                      line_channel_access_token: event.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="w-100 mb-3" controlId="formBasicTitle">
                <Form.Label className="mb-1">Line Channel Secret</Form.Label>
                <Form.Control
                  maxLength="50"
                  name="lineSecret"
                  type="text"
                  defaultValue={maRequirementData.line_channel_secret}
                  onChange={(event) =>
                    setMaRequirementData({
                      ...maRequirementData,
                      line_channel_secret: event.target.value,
                    })
                  }
                />
              </Form.Group>
            </div>

            <div className="w-100 m-0">
              <h5 className="text-orcaLight fw-bold py-2">FACEBOOK</h5>
              <Form.Group className="w-100 mb-3" controlId="formBasicTitle">
                <Form.Label className="mb-1">Facebook App ID</Form.Label>
                <Form.Control
                  maxLength="20"
                  name="facebookAppId"
                  type="text"
                  defaultValue={maRequirementData.facebook_app_id}
                  onChange={(event) =>
                    setMaRequirementData({
                      ...maRequirementData,
                      facebook_app_id: event.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="w-100 mb-3" controlId="formBasicTitle">
                <Form.Label className="mb-1">Facebook App Secret</Form.Label>
                <Form.Control
                  maxLength="20"
                  name="facebookAppSecret"
                  type="text"
                  defaultValue={maRequirementData.facebook_app_secret}
                  onChange={(event) =>
                    setMaRequirementData({
                      ...maRequirementData,
                      facebook_app_secret: event.target.value,
                    })
                  }
                />
              </Form.Group>
            </div>
          </Col>
        </div>

        {/* Return & Submit btn */}
        <Row className="p-0 h-9 m-auto w-100">
          <Col xs={2} className="d-flex px-0">
            <Link
              to="/home"
              title="Back to home"
              className="noDeco mb-auto w-100"
            >
              <Button variant="secondary" className="w-100" size="md">
                <FontAwesomeIcon className="text-light" icon={faCaretLeft} />
                &ensp;取消
              </Button>
            </Link>
          </Col>
          <Col xs={6} className="d-flex px-0" />
          <Col xs={2} className="px-1">
            <div className="w-100 btn-w-100 h-auto btn-save">
              <LoadingButton
                settings={{
                  name: page.action.type,
                  value: page.action.targetId,
                  content: (
                    <>
                      儲存&ensp;
                      <FontAwesomeIcon
                        className="my-auto"
                        icon={faFloppyDisk}
                      />
                    </>
                  ),
                  loaded: page.loaded,
                  onClick: handleModalSubmit,
                }}
              />
            </div>
          </Col>
          <Col xs={2} className="px-0">
            <div
              className="w-100 btn-w-100 h-auto"
              style={{
                cursor: isSaved ? 'default' : 'not-allowed',
              }}
              title="提示：請先確保資料無誤並儲存後再進行下一步"
            >
              <LoadingButton
                settings={{
                  name: 'next',
                  value: page.action.targetId,
                  content: (
                    <>
                      送出&ensp;
                      <FontAwesomeIcon
                        className="text-light my-auto"
                        icon={faCaretRight}
                      />
                    </>
                  ),
                  loaded: page.loaded,
                  onClick: handleModalSubmit,
                  disabled: !isSaved,
                }}
              />
            </div>
          </Col>
        </Row>
      </Row>
      <ModalSubmitWarning
        settings={{
          submitButton: {
            name: page.action.type,
            value: page.action.targetId,
            content: '確定',
            loaded: page.loaded,
          },
          warningModalShow: page.warningModalShow,
          handleWarningModalCancel,
          handleModalSubmit: handleWarningModalSubmit,
          handleModalClose,
          warningMessageType: page.action.type,
        }}
      />
      <ModalApiStatus
        settings={{
          content: {
            status: apiStatus.status,
            message: apiStatus.message,
          },
          apiStatusModalShow: apiStatus.apiStatusModalShow,
          handleModalClose,
          redirect: apiStatus.redirect,
        }}
      />
      <ModalFormConfirm
        settings={{
          submitButton: {
            name: page.action.type,
            value: page.action.targetId,
          },
          content: '行銷自動化',
          data: maRequirementData,
          dataType: 'object',
          formConfirmModalShow: page.formConfirmModalShow,
          handleFormConfirmModalCancel,
          handleModalSubmit,
          handleModalClose,
        }}
      />
    </Container>
  )
}
