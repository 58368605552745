import React from 'react'
import Router from './routes'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'

function App() {
  return (
    <div
      className="d-flex vh-100 bgImg_gif2"
      style={{ userSelect: 'none', overflow: 'hidden' }}
    >
      <Router />
    </div>
  )
}

export default App
