/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Card from 'react-bootstrap/esm/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import Chart from '../../components/shared/Chart'

export default function ChartGroup() {
  const initChannelCostLineChartData = [
    {
      date: new Date(),
      rate1: 12,
      rate2: 20,
      rate3: 50,
    },
  ]
  const initRecords = {
    total_campaigns_sms: 70,
    total_campaigns_mail: 30,
    total_cost_sms: 70,
    total_cost_mail: 30,
  }
  const [records, setRecords] = useState(initRecords)
  const [channelProportion, setChannelProportion] = useState({
    campaignProportionSms: 10,
    campaignProportionMail: 20,
    sentProportionSms: 30,
    sentProportionMail: 40,
    costProportionSms: 50,
    costProportionMail: 60,
  })
  const [channelCostLineChartData, setChannelCostLineChartData] = useState(
    initChannelCostLineChartData
  )
  // console.log(setRecords)
  // console.log(setChannelProportion)
  // console.log(setChannelCostLineChartData)
  return (
    <div className="text-orcaMid">
      <Row className="h-40">
        <Col xs={6}>
          <Card className="h-100 p-3 CardList d-block">
            <p
              className="text-orcaMid text-start h6 oneLineLimit"
              title="各管道 Campaign 佔比"
            >
              各管道 Campaign 佔比
            </p>
            <Row>
              <Col
                className="position-absolute"
                style={{ bottom: '5%', left: '2%' }}
              >
                <FontAwesomeIcon
                  style={{ pointerEvents: 'none', color: '#e85151' }}
                  icon={faCircle}
                  className="fa-fw"
                />
                <b> for sms ({channelProportion.campaignProportionSms}%)</b>
                <br />
                <FontAwesomeIcon
                  style={{ pointerEvents: 'none', color: '#f3a5a5' }}
                  icon={faCircle}
                  className="fa-fw"
                />
                <b> for email ({channelProportion.campaignProportionMail}%)</b>
                <br />
              </Col>
              <Col>
                <Chart
                  settings={{
                    data: [
                      { item: 'SMS', count: records.total_campaigns_sms },
                      { item: 'Email', count: records.total_campaigns_mail },
                    ],
                    type: 'pieChart',
                    margin: { top: 20, right: 30, bottom: 30, left: 30 },
                    translation: {
                      x: 140,
                      y: 75,
                    },
                    radius: {
                      inner: 30,
                      outer: 70,
                    },
                    colors: ['#e85151', '#f3a5a5', '#f3a500'],
                    chartId: 'campaignTotal',
                  }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={6} className="ps-1">
          <Card className="h-100 px-3 pt-3 pb-5 CardList d-block">
            <p
              className="text-orcaMid text-start h6 oneLineLimit"
              title="各管道花費佔比"
            >
              各管道花費佔比
            </p>
            <Row>
              <Col
                className="position-absolute"
                style={{ bottom: '5%', left: '2%' }}
              >
                <FontAwesomeIcon
                  style={{ pointerEvents: 'none', color: '#e85151' }}
                  icon={faCircle}
                  className="fa-fw"
                />
                <b> for sms ({channelProportion.costProportionSms}%)</b>
                <br />
                <FontAwesomeIcon
                  style={{ pointerEvents: 'none', color: '#f3a5a5' }}
                  icon={faCircle}
                  className="fa-fw"
                />
                <b> for email ({channelProportion.costProportionMail}%)</b>
                <br />
              </Col>
              <Col>
                <Chart
                  settings={{
                    data: [
                      { item: 'SMS', count: records.total_cost_sms },
                      { item: 'Email', count: records.total_cost_mail },
                    ],
                    type: 'pieChart',
                    margin: { top: 20, right: 30, bottom: 30, left: 30 },
                    translation: {
                      x: 140,
                      y: 75,
                    },
                    radius: {
                      inner: 30,
                      outer: 70,
                    },
                    colors: ['#e85151', '#f3a5a5', '#f3a500'],
                    chartId: 'costTotal',
                  }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row className="h-50 pt-3">
        <Col xs={12} className="pb-3">
          <Card className="h-100 px-3 py-2 CardList d-block">
            <p
              className="text-orcaMid text-start h6 oneLineLimit"
              title="折線圖指標名稱 1"
            >
              各管道花費趨勢圖
            </p>
            <Row className="h-80">
              <Col xs={3} className="mt-auto">
                <FontAwesomeIcon
                  style={{ pointerEvents: 'none', color: '#e85151' }}
                  icon={faCircle}
                  className="fa-fw"
                />
                <b> for sms</b>
                <br />
                <FontAwesomeIcon
                  style={{ pointerEvents: 'none', color: '#f3a5a5' }}
                  icon={faCircle}
                  className="fa-fw"
                />
                <b> for email</b>
                <br />
                <FontAwesomeIcon
                  style={{ pointerEvents: 'none', color: '#f3a500' }}
                  icon={faCircle}
                  className="fa-fw"
                />
                <b> for line</b>
                <br />
              </Col>
              <Col xs={9}>
                <Chart
                  settings={{
                    data: channelCostLineChartData,
                    type: 'threeLine',
                    margin: { top: 20, right: 30, bottom: 50, left: 30 },
                    colors: ['#e85151', '#f3a5a5', '#f3a500'],
                    chartId: 'channelThreeLine',
                    translation: {
                      x: 160,
                      y: 75,
                    },
                  }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
