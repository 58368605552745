/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFilePen,
  faCirclePlus,
  faChartSimple,
  faPaperPlane,
  faFeatherPointed,
  faHouse,
  faGear,
  faCloud,
  faFileArrowUp,
} from '@fortawesome/free-solid-svg-icons'
import { apiAuthInstance } from '../../apis/backend'
import ModalApiStatus from '../modal/ModalApiStatus'

export default function Toolbar({ settings }) {
  const location = useLocation()
  const { auth_rank } = settings.state.authData
  const isOrcaAdmin = auth_rank === 'orca_admin'
  const isBmAdmin = auth_rank === 'bm_admin'

  // OCA & OCM 站點
  const initLinks = {
    oca_links: '',
    ocm_links: 'https://orcama-plus.punwave.com/',
  }
  const [links, setLinks] = useState(initLinks)
  const [dataSrc, setDataSrc] = useState('')

  const initApiStatus = {
    status: '',
    message: '',
    apiStatusModalShow: false,
  }
  const [apiStatus, setApiStatus] = useState(initApiStatus)

  async function getLinks() {
    await apiAuthInstance({
      url: 'account/site-url/',
      method: 'get',
    })
      .then((response) => {
        setLinks({
          oca_links: response.data.data.orca_analytics_url,
          ocm_links: response.data.data.orca_ma_url,
        })
      })
      .catch(() => {})
  }

  async function getDataSrc() {
    await apiAuthInstance({
      url: 'applications/data-source/',
      method: 'get',
    })
      .then((response) => {
        setDataSrc(response.data.data.data_src)
      })
      .catch(() => {})
  }

  const handleModalClose = () => {
    setApiStatus(initApiStatus)
  }

  // pagevisit log api
  async function postLog(pagePath) {
    await apiAuthInstance({
      url: 'log/log/',
      method: 'post',
      data: {
        type: 'page_visit',
        data: {
          page: pagePath,
        },
      },
    }).then(() => {})
  }
  useEffect(() => {
    const array = location.pathname.split('/')
    postLog(`${array.join('/')}`)
  }, [])

  useEffect(() => {
    getLinks()
    getDataSrc()
  }, [])

  // 將新增呼叫當下使用者OCA, OCM站
  return (
    <>
      <ModalApiStatus
        settings={{
          content: {
            status: apiStatus.status,
            message: apiStatus.message,
          },
          apiStatusModalShow: apiStatus.apiStatusModalShow,
          handleModalClose,
        }}
      />
      <Row
        className="d-flex card h-7 mb-2 px-3 h6"
        style={{ flexDirection: 'row' }}
      >
        <Col className="text-start my-auto h6 text-orca">
          {links.ocm_links ? (
            <a
              className="noDeco text-orcaLink"
              target="_blank"
              rel="noreferrer"
              href={links.ocm_links}
              title="前往 OCM"
            >
              <FontAwesomeIcon className="pe-1" icon={faPaperPlane} />
              ORCA MA+
            </a>
          ) : (
            <a
              className="noDeco text-orcaLink ps-4"
              onClick={() =>
                setApiStatus({
                  status: '無法點擊',
                  message: '站點尚未綁定，請聯絡服務人員。',
                  apiStatusModalShow: true,
                })
              }
              title="前往 OCA"
              aria-hidden
            >
              <FontAwesomeIcon className="pe-1" icon={faPaperPlane} />
              ORCA MA+
            </a>
          )}
          {links.oca_links ? (
            <a
              className="noDeco text-orcaLink ps-4"
              target="_blank"
              rel="noreferrer"
              href={links.oca_links}
              title="前往 OCA"
            >
              <FontAwesomeIcon className="pe-1" icon={faChartSimple} />
              ORCA Analysis
            </a>
          ) : (
            <a
              className="noDeco text-orcaLink ps-4"
              onClick={() =>
                setApiStatus({
                  status: '無法點擊',
                  message: '站點尚未綁定，請聯絡服務人員。',
                  apiStatusModalShow: true,
                })
              }
              title="前往 OCA"
              aria-hidden
            >
              <FontAwesomeIcon className="pe-1" icon={faChartSimple} />
              ORCA Analysis
            </a>
          )}
        </Col>
        <Col xs={9} className="text-end my-auto">
          <Link className="noDeco text-orcaLink pe-4" to="/home">
            <FontAwesomeIcon className="pe-2" icon={faHouse} />
            主頁
          </Link>
          <Link
            className="noDeco text-orcaLink pe-4"
            to="/orca/application/application-main"
          >
            <FontAwesomeIcon className="pe-2" icon={faCirclePlus} />
            開戶申請
          </Link>
          <Link
            className="noDeco text-orcaLink pe-4"
            to="/orca/application/check-application"
          >
            <FontAwesomeIcon className="pe-2" icon={faFilePen} />
            申請狀態
          </Link>
          {dataSrc === 'csv' && (
            <Link
              className="noDeco text-orcaLink pe-4"
              to="/orca/formal/formal-main"
            >
              <FontAwesomeIcon className="pe-2" icon={faFileArrowUp} />
              上傳／更新正式檔案
            </Link>
          )}
          {isOrcaAdmin && (
            <Link
              className="noDeco text-orcaLink pe-4"
              to="/orca/application/tcloud"
            >
              <FontAwesomeIcon className="pe-2" icon={faCloud} />
              雲市集客戶管理
            </Link>
          )}
          {(isOrcaAdmin || isBmAdmin) && (
            <Link
              className="noDeco text-orcaLink pe-4"
              to="/orca/application/management"
            >
              <FontAwesomeIcon className="pe-2" icon={faGear} />
              管理中心
            </Link>
          )}
          {isOrcaAdmin && (
            <Link
              className="noDeco text-orcaLink pe-4"
              to="/orca/application/review"
            >
              <FontAwesomeIcon className="pe-2" icon={faFeatherPointed} />
              審核
            </Link>
          )}
        </Col>
      </Row>
    </>
  )
}
