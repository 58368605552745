/* eslint-disable no-case-declarations */
import React, { useRef, useState, useEffect } from 'react'
import * as d3 from 'd3'

export default function Chart(props) {
  const { settings } = props
  const {
    type,
    margin,
    data,
    translation,
    colors,
    innerColors,
    outerColors,
    chartId,
    radius,
  } = settings

  const chart = useRef()

  // Set chart width and height
  let pieWidth = 0
  let pieHeight = 0
  let pieTranslate = ''
  let pieWidthSvg = 0
  let pieHeightSvg = 0
  let lineWidth = 0
  let lineHeight = 0
  let lineTranslate = ''
  let lineWidthSvg = 0
  let lineHeightSvg = 0
  let barWidth = 0
  let barHeight = 0
  let barTranslate = ''
  let barWidthSvg = 0
  let barHeightSvg = 0
  let threeLineWidth = 0
  let threeLineHeight = 0
  let threeLineTranslate = ''
  let threeLineWidthSvg = 0
  let threeLineHeightSvg = 0
  // let concentricPieWidth = 0
  // let concentricPieHeight = 0
  const concentricPieTranslate = ''
  const concentricPieWidthSvg = 0
  const concentricPieHeightSvg = 0
  // const WidthSvg = 0
  // const HeightSvg = 0

  const [style, setStyle] = useState({
    width: 0,
    height: 0,
  })
  useEffect(() => {
    const d3Select = d3.select(`#${chartId}`)
    if (d3Select.node())
      setStyle({
        width: parseInt(d3Select.style('width'), 10),
        height: parseInt(d3Select.style('height'), 10),
      })
  }, [chartId])
  const { width, height } = style

  if (!data || data.length === 0 || !translation) return <div />

  switch (type) {
    case 'pieChart':
      pieWidth = width - margin.left - margin.right
      pieHeight = height - margin.top - margin.bottom
      // console.log(d3Select.node())
      // pieWidthSvg = 200
      // pieHeightSvg = 180
      pieWidthSvg = pieWidth + margin.left + margin.right
      pieHeightSvg = pieHeight + margin.top + margin.bottom
      pieTranslate = `translate(${translation.x},${translation.y})`
      break

    case 'lineChart':
      lineWidth = width - margin.left - margin.right
      lineHeight = height - margin.top - margin.bottom
      lineWidthSvg = lineWidth + margin.left + margin.right
      lineHeightSvg = lineHeight + margin.top + margin.bottom
      lineTranslate = `translate(${margin.left},${margin.top})`
      break

    case 'barChart':
      barWidth = width - margin.left - margin.right
      barHeight = height - margin.top - margin.bottom
      barWidthSvg = barWidth + margin.left + margin.right
      barHeightSvg = barHeight + margin.top + margin.bottom
      barTranslate = `translate(${margin.left},${margin.top})`
      break

    case 'threeLine':
      // console.log('type')
      threeLineWidth = width - margin.left - margin.right
      threeLineHeight = height - margin.top - margin.bottom
      // console.log(d3Select.node())
      // console.log(threeLineHeight)
      threeLineWidthSvg = threeLineWidth + margin.left + margin.right
      // threeLineHeightSvg = threeLineHeight + margin.top + margin.bottom
      threeLineHeightSvg = 180
      threeLineTranslate = `translate(${margin.left},${margin.top})`
      break

    // case 'concentricPie':
    //   concentricPieWidth =
    //     width -
    //     margin.left -
    //     margin.right
    //   concentricPieHeight =
    //     height -
    //     margin.top -
    //     margin.bottom
    //   concentricPieWidthSvg = 200
    //   concentricPieHeightSvg = 250
    //   concentricPieTranslate = `translate(${translation.x},${translation.y})`
    //   break

    default:
      break
  }

  d3.select(chart.current).selectAll('*').remove()

  // Set svg
  // const svg = d3.select(chart.current)
  // .attr('width', widthSvg)
  // .attr('height', heightSvg)
  // .append('g')
  //     .attr('transform', translate);

  // Select chart type and draw it
  switch (type) {
    case 'pieChart':
      const pieSvg = d3
        .select(chart.current)
        .attr('width', pieWidthSvg)
        .attr('height', pieHeightSvg)
        .append('g')
        .attr('transform', pieTranslate)
      // Get positions for each data object
      const piedata = d3.pie().value((d) => d.count)(data)
      // Define arcs for graphing
      const pieArc = d3
        .arc()
        .innerRadius(radius.inner)
        .outerRadius(radius.outer)
      const colorList = d3.scaleOrdinal(colors)

      // Draw pie
      pieSvg
        .append('g')
        .selectAll('path')
        .data(piedata)
        .join('path')
        .attr('d', pieArc)
        .attr('fill', (d, i) => colorList(i))
        .attr('stroke', 'white')
      break

    case 'concentricPie':
      const concentricPieSvg = d3
        .select(chart.current)
        .attr('width', concentricPieWidthSvg)
        .attr('height', concentricPieHeightSvg)
        .append('g')
        .attr('transform', concentricPieTranslate)
      // Get positions for each data object
      const concentricPieInnerData = d3.pie().value((d) => d.count)(data.inner)
      const concentricPieOuterData = d3.pie().value((d) => d.count)(data.outer)
      // Define arcs for graphing
      const concentricPieInnerArc = d3
        .arc()
        .innerRadius(radius.inner.inner)
        .outerRadius(radius.inner.outer)
      const concentricPieOuterArc = d3
        .arc()
        .innerRadius(radius.outer.inner)
        .outerRadius(radius.outer.outer)
      const innerColorList = d3.scaleOrdinal(innerColors)
      const outerColorList = d3.scaleOrdinal(outerColors)
      // Draw pie
      /// / inner
      concentricPieSvg
        .append('g')
        .selectAll('path')
        .data(concentricPieInnerData)
        .join('path')
        .attr('d', concentricPieInnerArc)
        .attr('fill', (d, i) => innerColorList(i))
        .attr('stroke', 'white')
      /// / outer
      concentricPieSvg
        .append('g')
        .selectAll('path')
        .data(concentricPieOuterData)
        .join('path')
        .attr('d', concentricPieOuterArc)
        .attr('fill', (d, i) => outerColorList(i))
        .attr('stroke', 'white')
      break

    case 'lineChart':
      const lineSvg = d3
        .select(chart.current)
        .attr('width', lineWidthSvg)
        .attr('height', lineHeightSvg)
        .append('g')
        .attr('transform', lineTranslate)
      // x axis scale
      const lineChartX = d3
        .scaleTime()
        .domain(d3.extent(data, (d) => d.date))
        .range([0, lineWidth])

      lineSvg
        .append('g')
        .attr('transform', `translate(0,${lineHeight})`)
        .call(d3.axisBottom(lineChartX).tickFormat(d3.timeFormat('%d %B %Y')))
        .call(lineChartX)
        .selectAll('text')
        .style('text-anchor', 'end')
        .attr('dx', '-.8em')
        .attr('dy', '.15em')
        .attr('transform', 'rotate(-25)')

      // Get the max value of counts
      const lineChartMax = d3.max(data, (d) => d.rate)

      // y axis scale
      const lineChartY = d3
        .scaleLinear()
        .domain([0, lineChartMax])
        .range([lineHeight, 0])

      lineSvg.append('g').call(d3.axisLeft(lineChartY))

      // Draw line
      lineSvg
        .append('path')
        .datum(data)
        .attr('fill', 'none')
        .attr('stroke', '#7170c2')
        .attr('stroke-width', 4)
        .attr('stroke-linecap', 'round')
        .attr(
          'd',
          d3
            .line()
            .x((d) => lineChartX(d.date))
            .y((d) => lineChartY(d.rate))
        )
      break

    case 'threeLine':
      const threeLineSvg = d3
        .select(chart.current)
        .attr('width', threeLineWidthSvg)
        .attr('height', threeLineHeightSvg)
        .append('g')
        .attr('transform', threeLineTranslate)

      const threeLineX = d3
        .scaleTime()
        .domain(d3.extent(settings.data, (d) => d.date))
        .range([0, threeLineWidth])

      threeLineSvg
        .append('g')
        .attr('transform', `translate(0,${threeLineHeight})`)
        .call(d3.axisBottom(threeLineX).tickFormat(d3.timeFormat('%d %B %Y')))
        // rotate x axis label
        .call(threeLineX)
        .selectAll('text')
        .style('text-anchor', 'end')
        .attr('dx', '-.8em')
        .attr('dy', '.15em')
        .attr('transform', 'rotate(-25)')

      // Get the max value of counts
      const threeLineMax = d3.max(
        [
          d3.max(settings.data, (d) => d.rate1),
          d3.max(settings.data, (d) => d.rate2),
          d3.max(settings.data, (d) => d.rate3),
        ],
        (d) => d
      )
      // y axis scale
      const threeLineY = d3
        .scaleLinear()
        .domain([0, threeLineMax])
        .range([threeLineHeight, 0])
      threeLineSvg.append('g').call(d3.axisLeft(threeLineY))

      // Draw line
      threeLineSvg
        .append('path')
        .datum(settings.data)
        .attr('fill', 'none')
        // .attr('stroke','#e85151')
        .attr('stroke', colors[0])
        .attr('stroke-width', 3)
        .attr('stroke-linecap', 'round')
        .attr(
          'd',
          d3
            .line()
            .x((d) => threeLineX(d.date))
            .y((d) => threeLineY(d.rate1))
            .curve(d3.curveMonotoneX)
        )
      threeLineSvg
        .append('path')
        .datum(settings.data)
        .attr('fill', 'none')
        // .attr('stroke','#f3a5a5')
        .attr('stroke', colors[1])
        .attr('stroke-width', 3)
        .attr('stroke-linecap', 'round')
        .attr(
          'd',
          d3
            .line()
            .x((d) => threeLineX(d.date))
            .y((d) => threeLineY(d.rate2))
            .curve(d3.curveMonotoneX)
        )

      threeLineSvg
        .append('path')
        .datum(settings.data)
        .attr('fill', 'none')
        // .attr('stroke','#f3a500')
        .attr('stroke', colors[2])
        .attr('stroke-width', 3)
        .attr('stroke-linecap', 'round')
        .attr(
          'd',
          d3
            .line()
            .x((d) => threeLineX(d.date))
            .y((d) => threeLineY(d.rate3))
            .curve(d3.curveMonotoneX)
        )
      break

    case 'barChart':
      const barSvg = d3
        .select(chart.current)
        .attr('width', barWidthSvg)
        .attr('height', barHeightSvg)
        .append('g')
        .attr('transform', barTranslate)

      const barChartX = d3
        .scaleBand()
        .domain(d3.range(settings.data.length))
        .range([margin.left, barWidth - margin.right])
        .padding(0.75)

      barSvg
        .append('g')
        .attr('transform', `translate(0, ${barHeight})`)
        .call(
          d3
            .axisBottom(barChartX)
            .tickFormat((i) => settings.data[i].campaign_name)
            .tickSizeOuter(0)
        )
        // rotate x axis label
        .call(barChartX)
        .selectAll('text')
        .style('text-anchor', 'end')
        .attr('dx', '-.8em')
        .attr('dy', '.15em')
        .attr('transform', 'rotate(-25)')

      const barChartMax = d3.max(settings.data, (d) => d.rate)

      const barChartY = d3
        .scaleLinear()
        .domain([0, barChartMax])
        .range([barHeight, margin.top])

      barSvg
        .append('g')
        .attr('transform', `translate(${margin.left}, 0)`)
        .call(d3.axisLeft(barChartY))

      barSvg
        .append('g')
        .attr('fill', '#6610f2')
        .selectAll('rect')
        .data(settings.data)
        .join('rect')
        .attr('x', (d, i) => barChartX(i))
        .attr('y', (d) => barChartY(d.rate))
        .attr('height', (d) => barChartY(0) - barChartY(d.rate))
        .attr('width', barChartX.bandwidth())
      break

    default:
      break
  }
  return (
    <div id={chartId}>
      <svg ref={chart} />
    </div>
  )
}
