import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

export default function ModalApiStatus(props) {
  const { settings } = props
  const { content, redirect } = settings
  const { apiStatusModalShow, handleModalClose } = settings

  return (
    <Modal show={apiStatusModalShow}>
      <Modal.Header closeButton onHide={handleModalClose} />
      <Modal.Body className="text-center text-orca py-5">
        <FontAwesomeIcon
          style={{ fontSize: '4rem', paddingBottom: '1rem' }}
          icon={faCircleExclamation}
        />
        <br />
        <h2>{content.status}</h2>
        <h5>{content.message}</h5>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        {!redirect && (
          <Button
            variant="secondary"
            name="apiStatus"
            onClick={handleModalClose}
          >
            OK
          </Button>
        )}
        {redirect && (
          <Link to="/home" title="返回">
            <Button variant="orcaLight">返回</Button>
          </Link>
        )}
      </Modal.Footer>
    </Modal>
  )
}
