import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/esm/Button'
import Form from 'react-bootstrap/Form'

export default function Footer() {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const claimedContent = [
    `「會員數據分析暨行銷自動化平台」（以下簡稱「本服
務」）係由「潮網科技股份有限公司」（以下簡稱「本公
司」）所提供。請用戶於使用本服務前詳閱下列本公司之
聲明事項（以下簡稱「本聲明」），並於確認瞭解且同意
後始開始使用本服務。

（1）用戶知悉並同意，本服務是依「現況」及「現有」基礎提供。

（2）本公司具有隨時更新本聲明的權利，任何更新會公告於本服務之中，並於公告時起立即生效。如用戶於本聲明更新後繼續使用本服務，即代表同意接受更新之內容。

（3）本公司相當重視用戶之資料保密，並承諾於用戶使用本服務之期間內，對用戶之機密資料負擔保密義務。未經用戶事前之書面同意，本公司不會為提供本服務以外之其他目的而揭露、使用、公開、散佈用戶機密資料之一部或全部，或以任何方式揭露一部或全部之機密資料予用戶以外之第三人。本公司對用戶所負擔之保密義務，悉依本公司與用戶間簽署之「資料蒐集處理政策暨保密合約」辦理。

（4）於向用戶提供本服務之目的範圍內，本公司需蒐集、處理及利用實際操作使用本平台之用戶人員之個人資料。用戶人員應於使用本服務前先行詳閱本公司之「隱私權政策」，並確認同意由本公司依該政策所揭之內容與範圍蒐集、處理及利用關於用戶人員之個人資料後，始使用本服務。

（5）如用戶提供至本服務之資料涉有任何第三方（例如：用戶之客戶、用戶之往來廠商）之個人資料者，用戶應擔保本公司於提供本服務之目的範圍內，以合於個人資料保護法及一切相關法規之方式蒐集、處理與利用經用戶提供至本服務之個人資料。如用戶提供至本服務之資料涉有第三方之機密資料者，用戶並應自行確認將該資料提供至本服務不違反任何適用法令及用戶與該第三方之間之契約關係。倘有違反者，用戶應自負其責，與本服務無涉。

（6）用戶知悉本公司對以下關於本服務之事項不負擔任何明示或默示之擔保責任：
 本服務得以不受干擾地持續運作；
 本服務所涉之資料得以被即時、安全可靠、正確無誤地傳輸、處理、儲存及備份；
 本服務系統及資料之錯誤，將被即時發現及更正；
 用戶之傳輸或所傳輸之資料已經合法授權；
 本平台不含任何瑕疵、錯誤或無法使用等情形。

（7）用戶同意本公司得自行判斷因任何理由，包含但不限於法院或政府機關命令、本服務無法繼續或本服務內容實質變更、無法預期之技術或安全因素或問題、用戶所為詐欺或違法行為，或其他本公司認為用戶已經違反與本公司間之契約內容，而終止或限制用戶使用本服務，或刪除用戶帳號中全部或部分資料及檔案。不論係因何種原因，由本公司註銷用戶帳號或是停止提供本服務時，本公司就用戶帳號的所有相關資訊均不負任何備份之義務，且本公司不因此對用戶或任何第三方承擔任何違約責任或損害賠償責任。

（8）本服務所使用之系統及本平台上所有內容，包括但不限於著作、圖片、檔案、資訊、資料、程式或網站畫面的安排、網站架構、頁面設計、資訊內容、提交內容等，均由本公司依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。用戶不得逕自使用、修改、重製、公開播送、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯。

（9）用戶瞭解並同意，本公司與用戶間就本服務之提供所生之權利義務悉依雙方前曾簽署之「資料蒐集處理政策暨保密合約」及其附件「隱私權政策」、「服務條款」辦理。如因本公司違反前揭約定而對用戶負擔損害賠償責任者，損害賠償總額以本公司已實際自用戶收取之服務費用總額為上限。`,
  ]

  return (
    <div
      className="d-flex position-fixed py-1 mt-auto text-light h6"
      style={{ bottom: '0.75%', left: '30%', right: '30%' }}
    >
      <Row className="d-flex mx-auto text-center">
        <Col xs={12} className="mt-auro text-center">
          <small className="mt-auto">
            Copyright © 2023 Wavenet. all rights reserved.｜
          </small>
          <Button
            className="h7 pt-0 ps-0"
            variant="orcaIcon3"
            size="sm"
            onClick={handleShow}
          >
            隱私權聲明
          </Button>
        </Col>
      </Row>

      <Modal
        show={show}
        onHide={handleClose}
        className="text-orcaDark unselectable"
        style={{ opacity: '0.9' }}
      >
        <Modal.Header closeButton />
        <Modal.Body className="unselectable">
          <Form className="px-3 pb-3">
            <Form.Label className="d-flex">
              <div className="mx-auto fw-bloder">
                <h5 className="text-center mb-0">
                  會員數據分析暨行銷自動化平台
                </h5>
                <h5 className="text-center">資料保密暨服務聲明</h5>
              </div>
            </Form.Label>
            <Form.Control
              style={{ cursor: 'ns-resize' }}
              className="text-start"
              as="textarea"
              rows={22}
              value={claimedContent}
              readOnly
            />
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  )
}
