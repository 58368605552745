import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { Container } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCaretLeft,
  faCaretRight,
  faCloudArrowUp,
  faFloppyDisk,
} from '@fortawesome/free-solid-svg-icons'
import { apiAuthInstance } from '../../apis/backend'
import LoadingButton from '../../components/shared/LoadingButton'
import ModalSubmitWarning from '../../components/modal/ModalSubmitWarning'
import ModalApiStatus from '../../components/modal/ModalApiStatus'

export default function FormalDataUpload(props) {
  const { setStep } = props

  const initCsvFiles = {
    crm_profile: {
      csv_data: '',
      csv_name: '',
    },
    items: {
      csv_data: '',
      csv_name: '',
    },
    orders: {
      csv_data: '',
      csv_name: '',
    },
  }
  const initPageAction = {
    type: 'formalDataUpload',
    targetId: undefined,
  }
  const initPage = {
    action: initPageAction,
    loaded: true,
    warningModalShow: false,
  }
  const initApiStatus = {
    status: '',
    message: '',
    apiStatusModalShow: false,
    redirect: false,
  }
  const [page, setPage] = useState(initPage)
  const [apiStatus, setApiStatus] = useState(initApiStatus)
  const [csvFiles, setCsvFiles] = useState(initCsvFiles)
  const [isSaved, setIsSaved] = useState(false)

  const uploadCsv = async (dataType, uploadCsvFile) => {
    const fileReader = new FileReader()
    fileReader.onload = (e) => {
      setCsvFiles({
        ...csvFiles,
        [dataType]: { csv_data: e.target.result, csv_name: uploadCsvFile.name },
      })
    }
    fileReader.readAsText(uploadCsvFile)
  }
  async function getFormalData() {
    await apiAuthInstance({
      url: 'applications/requirement/',
      method: 'get',
      params: {
        data_type: 'formal_data_csv',
      },
    })
      .then((response) => {
        setCsvFiles(response.data.data)
      })
      .catch((error) => {
        setPage(initPage)
        setApiStatus({
          status: error.response.data.status,
          message: error.response.data.message,
          apiStatusModalShow: true,
          redirect: true,
        })
      })
  }
  async function postFormalCsvData() {
    setPage((prevState) => ({
      ...prevState,
      loaded: false,
    }))
    await apiAuthInstance({
      url: 'applications/requirement/',
      method: 'post',
      params: {
        data_type: 'formal_data',
      },
      data: {
        csv_files: csvFiles,
      },
    })
      .then(() => {
        setPage(initPage)
        setIsSaved(true)
      })
      .catch((error) => {
        setPage(initPage)
        setApiStatus({
          status: error.response.data.status,
          message: error.response.data.message,
          apiStatusModalShow: true,
        })
      })
  }
  async function postApplicationData() {
    setPage((prevState) => ({
      ...prevState,
      loaded: false,
    }))
    await apiAuthInstance({
      url: 'applications/client-application/',
      method: 'post',
      params: {
        data_type: 'formal_data',
      },
    })
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        setPage(initPage)
        setApiStatus({
          status: error.response.data.status,
          message: error.response.data.message,
          apiStatusModalShow: true,
        })
      })
  }
  const handleModalClose = () => {
    setPage((prevState) => ({
      ...prevState,
      action: initPageAction,
      show: false,
      warningModalShow: false,
    }))
    setApiStatus(initApiStatus)
  }
  const handleModalSubmit = (event) => {
    switch (event.target.name) {
      case 'formalDataUpload':
        setPage((prevState) => ({
          ...prevState,
          show: false,
          warningModalShow: true,
        }))
        break
      case 'next':
        postApplicationData()
        setStep(1)
        break
      default:
        break
    }
  }
  const handleWarningModalCancel = (event) => {
    switch (event.target.name) {
      case 'formalDataUpload':
        setPage((prevState) => ({
          ...prevState,
          show: true,
          warningModalShow: false,
        }))
        break
      default:
        setPage((prevState) => ({
          ...prevState,
          show: false,
          warningModalShow: false,
        }))
        break
    }
  }
  const handleWarningModalSubmit = (event) => {
    switch (event.target.name) {
      case 'formalDataUpload':
        postFormalCsvData()
        break
      default:
        break
    }
  }
  useEffect(() => {
    getFormalData()
  }, [])
  return (
    <Container className="px-0 pb-3 mb-5 h-90">
      <Row
        className="d-flex h-100 mb-2 px-0 h6"
        style={{ flexDirection: 'row', overflow: 'hidden' }}
      >
        <div className="d-flex h-90 scroller" style={{ overflowY: 'auto' }}>
          <Col xs={5} className="d-flex p-5 text-orcaLighter">
            <FontAwesomeIcon
              className="my-auto ms-auto pe-5"
              style={{ fontSize: '9rem' }}
              icon={faCloudArrowUp}
            />
          </Col>
          <Col xs={7} className="p-5 h-100">
            <Form.Group className="w-75" controlId="formBasicTitle">
              <Form.Label className="text-orcaLight fw-bold h4">
                Crm_profile
              </Form.Label>
              <Form.Control
                name="contacts-csv-file"
                type="file"
                placeholder="crm_profile .csv File"
                accept=".csv"
                onChange={(e) => {
                  uploadCsv('crm_profile', e.target.files[0])
                }}
              />
              {csvFiles.crm_profile.csv_name && (
                <div className="d-flex w-100 small text-orcaLighter pt-1">
                  <div style={{ whiteSpace: 'nowrap' }}>
                    最後更新檔案:&ensp;
                  </div>
                  <div
                    className="oneLineLimit"
                    title={csvFiles.crm_profile.csv_name}
                  >
                    {csvFiles.crm_profile.csv_name}
                  </div>
                </div>
              )}
            </Form.Group>

            <Form.Group className="w-75 my-3" controlId="formBasicTitle">
              <Form.Label className="text-orcaLight fw-bold h4">
                Items
              </Form.Label>
              <Form.Control
                name="contacts-csv-file"
                type="file"
                placeholder="items .csv File"
                accept=".csv"
                onChange={(e) => {
                  uploadCsv('items', e.target.files[0])
                }}
              />
              {csvFiles.items.csv_name && (
                <div className="d-flex w-100 small text-orcaLighter pt-1">
                  <div style={{ whiteSpace: 'nowrap' }}>
                    最後更新檔案:&ensp;
                  </div>
                  <div className="oneLineLimit" title={csvFiles.items.csv_name}>
                    {csvFiles.items.csv_name}
                  </div>
                </div>
              )}
            </Form.Group>

            <Form.Group className="w-75 mb-3" controlId="formBasicTitle">
              <Form.Label className="text-orcaLight fw-bold h4">
                Orders
              </Form.Label>
              <Form.Control
                name="contacts-csv-file"
                type="file"
                placeholder="orders .csv File"
                accept=".csv"
                onChange={(e) => {
                  uploadCsv('orders', e.target.files[0])
                }}
              />
              {csvFiles.orders.csv_name && (
                <div className="d-flex w-100 small text-orcaLighter pt-1">
                  <div style={{ whiteSpace: 'nowrap' }}>
                    最後更新檔案:&ensp;
                  </div>
                  <div
                    className="oneLineLimit"
                    title={csvFiles.orders.csv_name}
                  >
                    {csvFiles.orders.csv_name}
                  </div>
                </div>
              )}
            </Form.Group>
          </Col>
        </div>
      </Row>

      {/* Return & Submit btn */}
      <Row className="p-0 h-9 mt-auto w-100">
        <Col xs={4} className="px-0">
          <Button
            className="h-auto w-100"
            variant="secondary"
            name="formalDataUpload"
            onClick={handleWarningModalCancel}
          >
            <FontAwesomeIcon className="text-light" icon={faCaretLeft} />
            &ensp;上一步
          </Button>
        </Col>
        <Col xs={4} className="px-1">
          <div className="w-100 btn-w-100 h-auto btn-save">
            <LoadingButton
              settings={{
                name: page.action.type,
                value: page.action.targetId,
                content: (
                  <>
                    儲存&ensp;
                    <FontAwesomeIcon
                      style={{ color: '#4341a7 !important' }}
                      className="text-light my-auto"
                      icon={faFloppyDisk}
                    />
                  </>
                ),
                loaded: page.loaded,
                onClick: handleModalSubmit,
              }}
            />
          </div>
        </Col>
        <Col xs={4} className="px-0">
          <div className="w-100 btn-w-100 h-auto">
            <LoadingButton
              settings={{
                name: 'next',
                value: page.action.targetId,
                content: (
                  <>
                    下一步&ensp;
                    <FontAwesomeIcon
                      className="text-light my-auto"
                      icon={faCaretRight}
                    />
                  </>
                ),
                loaded: page.loaded,
                onClick: handleModalSubmit,
                disabled: !isSaved,
              }}
            />
          </div>
        </Col>
      </Row>
      <ModalApiStatus
        settings={{
          content: {
            status: apiStatus.status,
            message: apiStatus.message,
          },
          apiStatusModalShow: apiStatus.apiStatusModalShow,
          handleModalClose,
          redirect: apiStatus.redirect,
        }}
      />
      <ModalSubmitWarning
        settings={{
          submitButton: {
            name: page.action.type,
            value: page.action.targetId,
            content: '送出',
            loaded: page.loaded,
          },
          warningModalShow: page.warningModalShow,
          handleWarningModalCancel,
          handleModalSubmit: handleWarningModalSubmit,
          handleModalClose,
          warningMessageType: page.action.type,
        }}
      />
    </Container>
  )
}
