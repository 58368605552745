/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import React, { useState, useEffect } from 'react'

// Home
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from 'react-router-dom'
import Cookies from 'universal-cookie'
import Home from './pages/home/Home'
import ErrorPage from './pages/home/ErrorPage'

// account
import Activation from './pages/account/Activation'
import Login from './pages/account/LoginPage'
import Register from './pages/account/RegisterPage'

// application
import ApplicationMain from './pages/application/ApplicationMain'
import AdminApplicationReview from './pages/review/AdminApplicationReview'
import ApplicantApplicationReview from './pages/review/ApplicantApplicationReview'

// formal
import FormalMain from './pages/formal/FormalMain'

// Management
import AdminManagement from './pages/management/AdminManagement'

// Tcloud
import AdminTcloud from './pages/tcloud/AdminTcloud'

import { apiAuthInstance } from './apis/backend'

export default function Router() {
  const cookies = new Cookies()
  const initAuthData = {
    auth_rank: '',
    comp_name: '',
    email: '',
    status: cookies.get('access_token') ? 'tbd' : 'unauthorized',
  }
  const [authData, setAuthData] = useState(initAuthData)
  const settings = {
    state: {
      initAuthData,
      authData,
      setAuthData,
    },
    cookies,
  }
  // console.log(authData)

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<UnAuth settings={settings} />}>
          <Route path="" element={<Navigate to="/account/login" />} />
        </Route>
        <Route path="/account" element={<UnAuth settings={settings} />}>
          <Route
            path="activation/:jwtToken"
            element={<Activation settings={settings} />}
          />
          <Route path="login" element={<Login settings={settings} />} />
          <Route path="register" element={<Register settings={settings} />} />
        </Route>

        <Route path="/" element={<AuthRequired settings={settings} />}>
          <Route path="home" element={<Home settings={settings} />} />
        </Route>

        <Route path="/orca" element={<AuthRequired settings={settings} />}>
          <Route path="application">
            <Route
              path="application-main"
              element={<ApplicationMain settings={settings} />}
            />
            <Route
              path="review"
              element={<AdminApplicationReview settings={settings} />}
            />
            <Route
              path="management"
              element={<AdminManagement settings={settings} />}
            />
            <Route
              path="tcloud"
              element={<AdminTcloud settings={settings} />}
            />
            <Route
              path="check-application"
              element={<ApplicantApplicationReview settings={settings} />}
            />
          </Route>

          <Route path="formal">
            <Route
              path="formal-main"
              element={<FormalMain settings={settings} />}
            />
          </Route>
        </Route>

        <Route element={<UnAuth settings={settings} />}>
          <Route path="" element={<Navigate to="/account/login" />} />
        </Route>

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  )
}

function UnAuth(props) {
  const { settings } = props
  // console.log(settings.state.authData.status)
  // return settings.state.authData.status !== 'authorized' ? (
  //   <Outlet />
  // ) : (
  //   <Navigate to="/home" />
  // )
  return settings.state.authData.status !== 'authorized' ? (
    <Outlet />
  ) : (
    <Navigate to="/orca/home" />
  )
}
// function IsAdmin(props) {
//     const { settings } = props
//     switch (settings.state.authData.auth_rank) {
//         case "developer":
//             return (
//                 <Outlet />
//             );

//         default:
//             return (
//                 <Navigate to="/home" />
//             );
//     }
// }
function AuthRequired(props) {
  const { settings } = props
  useEffect(() => {
    function getUserData() {
      apiAuthInstance({
        headers: {
          Authorization: `Bearer ${settings.cookies.get('access_token')}`,
        },
        url: 'account/user/',
        method: 'get',
      })
        .then((response) => {
          settings.state.setAuthData(response.data)
        })
        .catch((error) => {
          switch (error.response.status) {
            case 401:
              settings.state.setAuthData({
                ...settings.state.initAuthData,
                status: 'unauthorized',
              })
              break

            default:
              settings.state.setAuthData({
                ...settings.state.initAuthData,
                status: 'unauthorized',
              })
              break
          }
        })
    }

    switch (settings.state.authData.status) {
      case 'loginSuccess':
        getUserData()
        break

      case 'tbd':
        getUserData()
        break

      default:
        // security
        break
    }
  }, [settings])
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  // console.log('should not appear')
  return settings.state.authData.status === 'unauthorized' ? (
    <Navigate to="/account/login" />
  ) : (
    <Outlet />
  )
}
