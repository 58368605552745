import { React } from 'react'
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import orcaLogo from '../../assets/images/orca.svg'
import bmLogo from '../../assets/images/orcabm-logo-text.png'
import { apiInstance } from '../../apis/backend'

export default function Navbar(props) {
  const { settings } = props
  async function postLog(type, status, user = '') {
    await apiInstance({
      url: `log/log/`,
      method: 'post',
      data: {
        type,
        data: {
          status,
          user,
        },
      },
    })
      .then(() => {})
      .catch(() => {})
  }
  const handleLogout = () => {
    postLog('login_logout', 'logout', settings.state.authData.email)
    settings.cookies.remove('access_token', { path: '/' })
    settings.cookies.remove('refresh_token', { path: '/' })
    settings.state.setAuthData({
      ...settings.state.initAuthData,
      status: 'unauthorized',
    })
  }

  // console.log(settings.state.authData)
  // console.log(settings.state.authData.email.split('@')[0])

  return (
    <Row className="d-flex justify-content-start">
      <Col xs={8} className="my-auto py-1 pb-3">
        <Link to="/home" title="回主頁">
          <Image style={{ width: '2.75rem' }} src={orcaLogo} />
          <Image
            style={{ width: '10.25rem' }}
            className="ps-2 pt-1"
            src={bmLogo}
          />
        </Link>
      </Col>
      <Col xs={4} className="text-end my-auto text-light">
        <Dropdown>
          <DropdownToggle
            variant="orcaIcon"
            id="basic-nav-dropdown"
            align="end"
          >
            <span className="fs-6 text-light my-auto fw-bolder">
              <FontAwesomeIcon className="my-auto fs-5" icon={faUser} />
              &ensp;{settings.state.authData.email.split('@')[0]}
            </span>
          </DropdownToggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  )
}
