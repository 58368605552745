/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Pagination from 'react-bootstrap/Pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrashCan,
  faHourglass,
  faSquarePen,
  faSquareEnvelope,
  faEnvelope,
  faFileLines,
  faLink,
  // faAddressCard,
  // faRotateRight,
  faPenToSquare,
  faGear,
  faTableList,
  faCheck,
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons'
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons'

export default function TableList(props) {
  const { settings } = props
  const { size, page, totalPage, onPageClick, pagination, loading } = settings
  const [pageSlice, setPageSlice] = useState(1)

  useEffect(() => {
    const max = Math.ceil(settings.data.length / size) || 1
    if (pageSlice > max) setPageSlice(max)
  }, [settings.data, pageSlice])

  // dot control
  const [dot, setdot] = useState(' ·')
  useEffect(() => {
    setTimeout(() => {
      if (dot.length === 3) setdot('')
      else setdot(`${dot}·`)
    }, 550)
  }, [dot])

  if (loading) {
    return (
      <Row className="d-flex py-3 pt-5 h-100">
        <Form.Label className="text-center text-orcaMid my-auto pb-4">
          <FontAwesomeIcon icon={faHourglass} className="load-icon h1" />
          <br />
          <h5 className="pt-3">資料載入中 {dot}</h5>
        </Form.Label>
      </Row>
    )
  }

  // page switcher
  const onPageClickSlice = (target) => {
    if (target <= Math.ceil(settings.data.length / size) && target >= 1)
      setPageSlice(target)
  }

  let sliceData = []
  if (Array.isArray(settings.data) && size)
    sliceData = settings.data.slice(size * (pageSlice - 1), size * pageSlice)
  else if (Array.isArray(settings.data)) sliceData = settings.data

  // eslint-disable-next-line consistent-return
  function tableData(
    item,
    itemIndex,
    data,
    dataIndex,
    rowId,
    clickToShowModal
  ) {
    let dataField = data
    if (typeof item.fieldName === 'string') {
      const fieldNames = item.fieldName.split('.')
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < fieldNames.length; i++) {
        dataField = dataField[fieldNames[i]]
      }
    }

    switch (item.dataType) {
      // Global basic (too much place...)

      case 'text':
        return (
          <OverlayTrigger
            key={itemIndex}
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={
              String(dataField) && String(dataField).length > 0 ? (
                <Tooltip className="Tips">{String(dataField)}</Tooltip>
              ) : (
                <></>
              )
            }
          >
            <td
              key={itemIndex}
              value={rowId ? data[rowId] : dataIndex}
              name="dataField"
              onClick={
                clickToShowModal !== undefined ? clickToShowModal : () => false
              }
            >
              {String(dataField)}
            </td>
          </OverlayTrigger>
        )

      // OrcaTags + Tags + Directories: Customer; Admin: Perm; Customer: Phone; SmsAnalytics + MailAnalytics: numbers;
      case 'text-center':
        return (
          <OverlayTrigger
            key={itemIndex}
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={
              String(dataField) && String(dataField).length > 0 ? (
                <Tooltip className="Tips">{String(dataField)}</Tooltip>
              ) : (
                <></>
              )
            }
          >
            <td
              key={itemIndex}
              value={rowId ? data[rowId] : dataIndex}
              name="dataField"
              onClick={
                clickToShowModal !== undefined ? clickToShowModal : () => false
              }
              style={{ textAlign: 'center' }}
            >
              {String(dataField)}
            </td>
          </OverlayTrigger>
        )

      case 'text-copy-center':
        return (
          <OverlayTrigger
            key={itemIndex}
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={
              String(dataField) && String(dataField).length > 0 ? (
                <Tooltip className="Tips">點擊以複製內容</Tooltip>
              ) : (
                <></>
              )
            }
          >
            <td
              key={itemIndex}
              value={rowId ? data[rowId] : dataIndex}
              name="dataField"
              onClick={() => {
                navigator.clipboard.writeText(String(dataField))
              }}
              style={{ textAlign: 'center', cursor: 'pointer' }}
            >
              {String(dataField)}
            </td>
          </OverlayTrigger>
        )

      case 'text-end':
        return (
          <td
            key={itemIndex}
            value={rowId ? data[rowId] : dataIndex}
            name="dataField"
            onClick={
              clickToShowModal !== undefined ? clickToShowModal : () => false
            }
            style={{ textAlign: 'end' }}
          >
            {String(dataField)} <span className="pe-3" />
          </td>
        )
      case 'status-indicator':
        const isSuccess = dataField === 200

        const statusStyle = {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }

        const dotStyle = {
          height: '10px',
          width: '10px',
          borderRadius: '50%',
          marginRight: '8px',
          backgroundColor: isSuccess ? 'green' : 'red',
        }

        return (
          <td key={itemIndex} style={{ textAlign: 'center' }}>
            <div style={statusStyle}>
              <span style={dotStyle} />
              <span>{dataField}</span>
            </div>
          </td>
        )

      // UserManagement: Orca, Active, Staff, Admin;
      case 'boolean':
        return (
          <td
            className={dataField ? 'text-success' : 'text-secondary'}
            key={itemIndex}
            value={rowId ? data[rowId] : dataIndex}
            name="dataField"
            onClick={
              clickToShowModal !== undefined ? clickToShowModal : () => false
            }
            style={{ fontWeight: '600', textAlign: 'center' }}
          >
            ● {String(dataField)}
          </td>
        )

      case 'boolean-pure':
        return (
          <td
            className={dataField ? 'text-success' : 'text-secondary'}
            key={itemIndex}
            value={rowId ? data[rowId] : dataIndex}
            name="dataField"
            onClick={
              clickToShowModal !== undefined ? clickToShowModal : () => false
            }
            style={{ fontWeight: '600', textAlign: 'center' }}
          >
            ●
          </td>
        )
      // SmsSending: Status
      case 'status':
        return (
          <td
            className={
              dataField === 'Sent' || dataField === 'Delivered'
                ? 'text-success'
                : dataField === 'Schedule' || dataField === 'Scheduled'
                ? 'text-blue'
                : dataField === 'Paused'
                ? 'text-red'
                : 'text-grey'
            }
            key={itemIndex}
            value={rowId ? data[rowId] : dataIndex}
            name="dataField"
            onClick={
              clickToShowModal !== undefined ? clickToShowModal : () => false
            }
            style={{ textIndent: '17%' }}
          >
            {`● ${
              String(dataField) === 'Save' ||
              String(dataField) === 'Pause' ||
              String(dataField) === 'draft'
                ? `Draft`
                : String(dataField) === 'Schedule' ||
                  String(dataField) === 'Scheduled'
                ? `On Schedule`
                : String(dataField) === 'Sent'
                ? `Delivered`
                : String(dataField)
            }
            `}
          </td>
        )

      // SmsSending: Send Time / Edit Time, Customers: Reveived Time, Reveived Time, Open Time, Click Time; Tags: Created, Updated; SmsSending: Send Time / Edit Time; MailSending: Send Time, MailTemplate: Create Time;
      case 'datetime':
        return (
          <td
            key={itemIndex}
            value={rowId ? data[rowId] : dataIndex}
            name="dataField"
            onClick={
              clickToShowModal !== undefined ? clickToShowModal : () => false
            }
            style={{ textAlign: 'center' }}
          >
            {String(dataField).slice(0, 10)} {String(dataField).slice(11, 19)}
          </td>
        )
      case 'datetime-tz+8':
        return (
          <td
            key={itemIndex}
            value={rowId ? data[rowId] : dataIndex}
            name="dataField"
            onClick={
              clickToShowModal !== undefined ? clickToShowModal : () => false
            }
            style={{ textAlign: 'center' }}
          >
            {dataField !== ''
              ? new Date(dataField).toLocaleString('sv-SE', {
                  timeZone: 'Asia/Taipei',
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  hour12: false,
                  minute: '2-digit',
                  second: '2-digit',
                })
              : '–'}
          </td>
        )

      case 'review applications':
        return (
          <td key={itemIndex} className="text-center">
            {data.application_src === 'bm' && (
              <>
                <Button
                  variant="orcaIcon"
                  title="設 定"
                  name="settings"
                  value={data[item.fieldAsValue]}
                  onClick={item.onReviewClick}
                  className="p-1 pe-3"
                >
                  <FontAwesomeIcon
                    style={{ pointerEvents: 'none' }}
                    icon={faGear}
                    className="fs-4"
                  />
                </Button>

                <Button
                  variant="orcaIcon"
                  title="審 核"
                  name="review"
                  value={data[item.fieldAsValue]}
                  onClick={item.onReviewClick}
                  className="p-1 pe-3"
                >
                  <FontAwesomeIcon
                    style={{ pointerEvents: 'none' }}
                    icon={faSquarePen}
                    className="fs-4"
                  />
                </Button>
              </>
            )}

            <Button
              variant="orcaIcon"
              title="寄 件"
              name="notification"
              value={data[item.fieldAsValue]}
              onClick={item.onReviewClick}
              className="p-1"
            >
              <FontAwesomeIcon
                style={{ pointerEvents: 'none' }}
                icon={faSquareEnvelope}
                className="fs-4"
              />
            </Button>
          </td>
        )

      case 'management of orca admin':
        return (
          <td key={itemIndex} className="text-center">
            <Button
              variant="orcaIcon"
              title="申 請 資 訊"
              name="clientApplicationDetail"
              value={data[item.fieldAsValue]}
              onClick={item.onReviewClick}
              className="px-2"
            >
              <FontAwesomeIcon
                style={{ pointerEvents: 'none' }}
                icon={faMagnifyingGlass}
                className="fs-5"
              />
            </Button>
            {!['pending', 'under review', 'incomplete'].includes(
              data.status
            ) && (
              <Button
                variant="orcaIcon"
                title="專 案 設 定"
                name="project"
                value={data[item.fieldAsValue]}
                onClick={item.onReviewClick}
                className="px-2"
              >
                <FontAwesomeIcon
                  style={{ pointerEvents: 'none' }}
                  icon={faGear}
                  className="fs-5"
                />
              </Button>
            )}
            <Button
              variant="orcaIcon"
              title="站 點 管 理"
              name="links"
              value={data[item.fieldAsValue]}
              onClick={item.onReviewClick}
              className="px-2"
            >
              <FontAwesomeIcon
                style={{ pointerEvents: 'none' }}
                icon={faLink}
                className="fs-5"
              />
            </Button>

            {/* <Button
              variant="orcaIcon"
              title="寄 通 知 信"
              name="send"
              value={data[item.fieldAsValue]}
              onClick={item.onReviewClick}
              className="px-2"
            >
              <FontAwesomeIcon
                style={{ pointerEvents: 'none' }}
                icon={faPaperPlane}
                className="fs-5"
              />
            </Button> */}
            {/* <Button
              variant="orcaIcon"
              title="成 員 列 表"
              name="members"
              value={data[item.fieldAsValue]}
              onClick={item.onReviewClick}
              className="px-2"
            >
              <FontAwesomeIcon
                style={{ pointerEvents: 'none' }}
                icon={faAddressCard}
                className="fs-5"
              />
            </Button> */}

            <Button
              variant="orcaIcon"
              title="更 新 紀 錄"
              name="projectUpdateRecord"
              value={data[item.fieldAsValue]}
              onClick={item.onReviewClick}
              className="px-2"
            >
              <FontAwesomeIcon
                style={{ pointerEvents: 'none' }}
                icon={faTableList}
                className="fs-5"
              />
            </Button>
            {dataField.application_src !== 'bm' && (
              <Button
                variant="orcaIcon"
                title="開 站 完 成"
                name="clientApplicationApproved"
                value={data[item.fieldAsValue]}
                onClick={item.onReviewClick}
                className="px-2"
              >
                <FontAwesomeIcon
                  style={{ pointerEvents: 'none' }}
                  icon={faCheck}
                  className="fs-5"
                />
              </Button>
            )}
            <Button
              variant="orcaIcon"
              title="刪 除"
              name="delete"
              value={data[item.fieldAsValue]}
              onClick={item.onReviewClick}
              className="px-2"
            >
              <FontAwesomeIcon
                style={{ pointerEvents: 'none', color: 'red' }}
                icon={faTrashCan}
                className="fs-5"
              />
            </Button>
          </td>
        )

      case 'management of bm admin':
        return (
          <td key={itemIndex} className="text-center">
            {/* <Button
              variant="orcaIcon"
              title="成 員 管 理"
              name="members"
              value={data[item.fieldAsValue]}
              onClick={item.onReviewClick}
              className="px-2"
            >
              <FontAwesomeIcon
                style={{ pointerEvents: 'none' }}
                icon={faAddressCard}
                className="fs-5"
              />
            </Button> */}

            <Button
              variant="orcaIcon"
              title="更 新 紀 錄"
              name="projectUpdateRecord"
              value={data[item.fieldAsValue]}
              onClick={item.onReviewClick}
              className="px-2"
            >
              <FontAwesomeIcon
                style={{ pointerEvents: 'none' }}
                icon={faTableList}
                className="fs-5"
              />
            </Button>
            {/* <Button
              variant="orcaIcon"
              title="同 步 更 新"
              name="reload"
              value={data[item.fieldAsValue]}
              onClick={item.onReviewClick}
              className="px-2"
            >
              <FontAwesomeIcon
                style={{ pointerEvents: 'none' }}
                icon={faRotateRight}
                className="fs-5"
              />
            </Button> */}
          </td>
        )

      case 'tcloud':
        return (
          <td key={itemIndex} className="text-center">
            <Button
              variant="orcaIcon"
              title="設 定"
              name="settings"
              value={data[item.fieldAsValue]}
              onClick={item.onReviewClick}
              className="p-1 pe-3"
            >
              <FontAwesomeIcon
                style={{ pointerEvents: 'none' }}
                icon={faGear}
                className="fs-5"
              />
            </Button>

            <Button
              variant="orcaIcon"
              title="查 看 交 易 紀 錄"
              name="review"
              value={data[item.fieldAsValue]}
              onClick={item.onReviewClick}
              className="p-1 pe-3"
            >
              <FontAwesomeIcon
                style={{ pointerEvents: 'none' }}
                icon={faFileLines}
                className="fs-5"
              />
            </Button>

            {/* 判斷交易status改變寄件類型 */}
            <Button
              variant="orcaIcon"
              title="寄 件 功 能"
              name="notification"
              value={data[item.fieldAsValue]}
              onClick={item.onNotificationClick}
              className="p-1"
            >
              <FontAwesomeIcon
                style={{ pointerEvents: 'none' }}
                icon={faEnvelope}
                className="fs-5"
              />
            </Button>
          </td>
        )

      // members
      case 'view and delete':
        return (
          <td key={itemIndex} className="text-center">
            <Button
              variant="orcaIcon"
              title="編 輯"
              name="edit"
              value={data[item.fieldAsValue]}
              onClick={item.onUpdateClick}
              className="p-1"
            >
              <FontAwesomeIcon
                style={{ pointerEvents: 'none' }}
                icon={faPenToSquare}
                className="fs-5"
              />
            </Button>

            <Button
              variant="orcaIcon"
              title="移 除"
              name="delete"
              value={data[item.fieldAsValue]}
              onClick={item.onDeleteClick}
              className="p-1 ps-2"
            >
              <FontAwesomeIcon
                style={{ pointerEvents: 'none' }}
                icon={faCircleXmark}
                className="fs-5 fw-bolder"
              />
            </Button>
          </td>
        )

      // Tags + SmsTemplate + MailTemplate: Func.
      case 'update and delete':
        return (
          <td key={itemIndex} className="text-center">
            <Button
              variant="orca"
              title="編 輯"
              name="edit"
              value={data[item.fieldAsValue]}
              onClick={item.onUpdateClick}
              className="p-1"
            >
              <FontAwesomeIcon
                style={{ pointerEvents: 'none' }}
                icon={faSquarePen}
                className="fs-4"
              />
            </Button>

            <Button
              variant="orca"
              title="刪 除"
              name="delete"
              value={data[item.fieldAsValue]}
              onClick={item.onDeleteClick}
              className="p-1"
            >
              <FontAwesomeIcon
                style={{ pointerEvents: 'none' }}
                icon={faTrashCan}
                className="fs-4"
              />
            </Button>
          </td>
        )

      default:
        return (
          <OverlayTrigger
            key={itemIndex}
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={
              String(dataField) && String(dataField).length > 0 ? (
                <Tooltip className="Tips">{String(dataField)}</Tooltip>
              ) : (
                <></>
              )
            }
          >
            <td
              key={itemIndex}
              value={rowId ? data[rowId] : dataIndex}
              name="dataField"
              onClick={
                clickToShowModal !== undefined ? clickToShowModal : () => false
              }
            >
              {String(dataField)}
            </td>
          </OverlayTrigger>
        )
    }
  }

  // page loading effect
  return (
    <>
      <Table
        bordered
        hover
        className="text-orca"
        style={{ textOverflow: 'hidden' }}
      >
        <thead>
          <tr>
            {settings.tableConfig.map((item, index) => (
              <th title={item.head} key={index} style={item.style}>
                {item.head}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sliceData.map((data, dataIndex) => (
            <tr key={dataIndex}>
              {settings.tableConfig.map((item, itemIndex) =>
                tableData(item, itemIndex, data, dataIndex, settings.rowId)
              )}
            </tr>
          ))}
        </tbody>
      </Table>

      {pagination && (
        <div className="d-flex mt-auto w-100 mx-0 px-0">
          <div id="TablePagination" className="d-flex w-100">
            <div className="d-flex w-100 h-100">
              <Pagination className="mb-0 px-0 d-flex">
                <Pagination.Prev
                  className="prev w-100"
                  name="prev"
                  onClick={() => {
                    if (onPageClick) onPageClick(page - 1)
                    else onPageClickSlice(pageSlice - 1)
                  }}
                />
                <Pagination.Item active className="w-100 text-center">
                  {totalPage ? (
                    <div className="w-100 h-75 bg-orca rounded-3 mx-auto">
                      {page || pageSlice}／{totalPage}
                    </div>
                  ) : (
                    <div className="w-100 h-75 bg-orca rounded-3 mx-auto">
                      {page || pageSlice}／
                      {Math.ceil(settings.data.length / size) || 1}
                    </div>
                  )}
                </Pagination.Item>
                <Pagination.Next
                  className="next w-100"
                  name="next"
                  onClick={() => {
                    if (onPageClick) onPageClick(page + 1)
                    else onPageClickSlice(pageSlice + 1)
                  }}
                />
              </Pagination>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
