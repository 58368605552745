import React, { useState, useEffect } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import TableList from '../shared/TableList'

export default function ModalHistory({
  show,
  handleClose,
  title,
  apiToCall,
  client_application_id,
  tableConfig,
}) {
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (show && client_application_id) {
      setLoading(true)
      setError(null)
      apiToCall(client_application_id)
        .then((response) => {
          setTableData(response.data.data || [])
          setLoading(false)
        })
        .catch((err) => {
          //   console.log('err', err)
          setError(err)
          setLoading(false)
          setTableData([])
        })
    }
  }, [show, client_application_id, apiToCall])

  let content
  if (loading) {
    content = (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: '200px' }}
      >
        <Spinner animation="border" />
      </div>
    )
  } else if (error) {
    content = (
      <div className="text-center text-danger">加載失敗: {error.message}</div>
    )
  } else if (tableData.length === 0) {
    content = <div className="text-center">沒有數據可顯示</div>
  } else {
    content = (
      <TableList
        settings={{
          tableConfig,
          data: tableData,
          size: 10,
          pagination: true,
        }}
      />
    )
  }

  return (
    <Modal show={show} size="lg" onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ backgroundColor: 'rgb(248 248 248)' }}>
        <div
          className="d-flex flex-column p-0 h-100 tableList_members rounded border"
          style={{ backgroundColor: '#fff' }}
        >
          {content}
        </div>
      </Modal.Body>
    </Modal>
  )
}
