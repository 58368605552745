/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'

export default function SubmitSuccess() {
  return (
    <Row className="h-100">
      <div className="text-center mx-auto mt-auto text-orcaLight fw-bold pb-4">
        <FontAwesomeIcon
          style={{ fontSize: '3rem', paddingBottom: '1rem' }}
          icon={faCircleCheck}
        />
        <br />
        <h3>您的資料已成功送出!</h3>
      </div>
      <div className="text-center">
        <Link
          className="noDeco text-center"
          to="/orca/application/check-application"
        >
          <Button variant="orcaLight">前往確認</Button>
        </Link>
      </div>
    </Row>
  )
}
