import React from 'react'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentSms, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import faLine from '../../assets/images/line-brands.svg'

export default function DataCardList() {
  const dataListTitle = {
    title_sms: (
      <>
        <FontAwesomeIcon icon={faCommentSms} />
        &ensp;SMS
      </>
    ),
    title_email: (
      <>
        <FontAwesomeIcon icon={faEnvelope} />
        &ensp;EMAIL
      </>
    ),
    title_line: (
      <>
        <Image width="20px" src={faLine} />
        &ensp;LINE
      </>
    ),
  }

  return (
    <>
      <div className="flex-grow-1">
        <Card
          className="p-3 CardListPurple h-100"
          style={{ borderRadius: '0.5rem' }}
        >
          <Row className="d-flex h-100">
            <Col xs={6} className="h-100 my-auto">
              <h5 className="text-orcaMid text-start my-auto ps-4">
                {dataListTitle.title_sms} 分析總覽
              </h5>
            </Col>

            <Col xs={6} className="my-auto">
              <Row>
                <Col
                  xs={5}
                  className="lh-xs d-flex text-start text-orcaMid px-0"
                >
                  <h6 className="my-auto">總活動數</h6>
                </Col>
                <Col xs={7} className="text-orcaMid text-end px-0 pe-4">
                  <span className="h5 my-auto" value="0" name="dataField">
                    0<span className="h6"> 次</span>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={5}
                  className="lh-xs d-flex text-start text-orcaMid px-0"
                >
                  <span className="h6 my-auto">總寄發數</span>
                </Col>
                <Col xs={7} className="text-orcaMid text-end px-0 pe-4">
                  <span className="h5 my-auto" value="0" name="dataField">
                    0<span className="h6"> 封</span>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={5}
                  className="lh-xs d-flex text-start text-orcaMid px-0"
                >
                  <span className="h6 my-auto">總花費</span>
                </Col>
                <Col xs={7} className="text-orcaMid text-end px-0 pe-4">
                  <span className="h5 my-auto" value="0" name="dataField">
                    0<span className="h6"> 點</span>
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </div>
      <div className="flex-grow-1 pt-3">
        <Card
          className="p-3 CardListPurple h-100"
          style={{ borderRadius: '0.5rem' }}
        >
          <Row className="d-flex h-100">
            <Col xs={6} className="h-100 my-auto">
              <h5 className="text-orcaMid text-start my-auto ps-4">
                {dataListTitle.title_email} 分析總覽
              </h5>
            </Col>
            <Col xs={6} className="my-auto">
              <Row>
                <Col
                  xs={5}
                  className="lh-xs d-flex text-start text-orcaMid px-0"
                >
                  <h6 className="my-auto">總活動數</h6>
                </Col>
                <Col xs={7} className="text-orcaMid text-end px-0 pe-4">
                  <span className="h5 my-auto" value="0" name="dataField">
                    0<span className="h6"> 次</span>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={5}
                  className="lh-xs d-flex text-start text-orcaMid px-0"
                >
                  <span className="h6 my-auto">總寄發數</span>
                </Col>
                <Col xs={7} className="text-orcaMid text-end px-0 pe-4">
                  <span className="h5 my-auto" value="0" name="dataField">
                    0<span className="h6"> 封</span>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={5}
                  className="lh-xs d-flex text-start text-orcaMid px-0"
                >
                  <span className="h6 my-auto">總花費</span>
                </Col>
                <Col xs={7} className="text-orcaMid text-end px-0 pe-4">
                  <span className="h5 my-auto" value="0" name="dataField">
                    0<span className="h6"> 點</span>
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </div>

      <div className="flex-grow-1 pt-3">
        <Card className="p-3 h-100" style={{ borderRadius: '0.5rem' }}>
          <Row className="d-flex h-100">
            <Col xs={6} className="h-100 my-auto">
              <h5 className="text-orcaMid text-start my-auto ps-4">
                {dataListTitle.title_line} 分析總覽
              </h5>
            </Col>
            <Col xs={6} className="my-auto">
              <Row>
                <Col
                  xs={5}
                  className="lh-xs d-flex text-start text-orcaMid px-0"
                >
                  <h6 className="my-auto">總活動數</h6>
                </Col>
                <Col xs={7} className="text-orcaMid text-end px-0 pe-4">
                  <span className="h5 my-auto" value="0" name="dataField">
                    0<span className="h6"> 次</span>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={5}
                  className="lh-xs d-flex text-start text-orcaMid px-0"
                >
                  <span className="h6 my-auto">總寄發數</span>
                </Col>
                <Col xs={7} className="text-orcaMid text-end px-0 pe-4">
                  <span className="h5 my-auto" value="0" name="dataField">
                    0<span className="h6"> 封</span>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={5}
                  className="lh-xs d-flex text-start text-orcaMid px-0"
                >
                  <span className="h6 my-auto">總花費</span>
                </Col>
                <Col xs={7} className="text-orcaMid text-end px-0 pe-4">
                  <span className="h5 my-auto" value="0" name="dataField">
                    0<span className="h6"> 點</span>
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  )
}
