// 繁體中文
import twModalFormConfirm from './language/zh-tw/modal/ModalFormConfirm.json'

const resources = {
  tw: {
    ModalFormConfirm: twModalFormConfirm,
  },
}

export default resources
