import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faUser } from '@fortawesome/free-solid-svg-icons'
import Navbar from '../../components/shared/Navbar'
// import Toolbar from '../../components/shared/Toolbar'
import Footer from '../../components/shared/Footer'
import { apiInstance } from '../../apis/backend'

export default function Activation({ settings }) {
  const { jwtToken } = useParams()
  // const navigate = useNavigate();
  const initmodalForm = {
    comp_name: '',
    email: '',
    password: '',
    confirm_password: '',
    status: '',
    loaded: true,
    show: true,
  }
  const [modalForm, setModalForm] = useState(initmodalForm)

  // const handleModalShow = (event) => {
  //   if (event === undefined) {
  //     setModalForm((prevState) => ({
  //       ...prevState,
  //       password: '',
  //       confirm_password: '',
  //       show: false,
  //     }))
  //   } else {
  //     switch (event.target.name) {
  //       case 'register-modal':
  //         setModalForm((prevState) => ({
  //           ...prevState,
  //           show: true,
  //         }))
  //         break

  //       default:
  //         setModalForm((prevState) => ({
  //           ...prevState,
  //           show: true,
  //         }))
  //         break
  //     }
  //   }
  // }

  useEffect(() => {
    // jwtToken authz and get email from jwtToken
    apiInstance({
      url: `account/email_activation/${jwtToken}/`,
      method: 'put',
      data: {},
    }).then((response) => {
      setModalForm((prevState) => ({
        ...prevState,
        comp_name: response.data.comp_name,
        email: response.data.email,
        is_active: response.data.is_active,
        status: response.data.status,
      }))
    })
  }, [jwtToken])
  // useEffect(() => {
  //     if (modalForm.status === "invalid") {
  //         navigate('/error')
  //     } else if (modalForm.status === "verified"){
  //         navigate('/account/login')
  //     }
  // }, [navigate, modalForm.status]);
  console.log(modalForm)
  return (
    <Container fluid="true" className="pt-2 pb-3 px-5 mb-5 h-100 w-100">
      <Navbar settings={settings} />
      {/* <Toolbar /> */}
      <Row className="card h-78 p-4 flex-column d-flex">
        <div className="text-center mx-auto mt-auto text-orcaMid fw-bold pb-4">
          <FontAwesomeIcon
            style={{ fontSize: '3.5rem', paddingBottom: '1rem' }}
            icon={faUser}
          />
          <FontAwesomeIcon
            style={{ fontSize: '2.5rem', paddingBottom: '1rem' }}
            icon={faCircleCheck}
          />
          <br />
          <h3>您的帳戶已驗證成功!</h3>
        </div>
        <div className="text-center mx-auto mb-auto pb-4">
          <Link className="noDeco text-center" to="/account/login">
            <Button variant="orca">重新登入</Button>
          </Link>
        </div>
      </Row>
      <Footer />
    </Container>
  )
}
