import React, { useEffect, useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import moment from 'moment'
import PropTypes from 'prop-types'
import { apiAuthInstance } from '../../apis/backend'

export default function ModalSubscriptionStatusChange(props) {
  const { show, onClose, clientApplicationId } = props

  // 狀態管理
  const [newStatus, setNewStatus] = useState('')
  const [expirationTime, setExpirationTime] = useState('')
  const [reason, setReason] = useState('')

  async function submitSubscriptionStatusChange(payload) {
    await apiAuthInstance({
      url: 'applications/subscription-status/',
      method: 'post',
      data: payload,
    })
      .then((response) => {
        // console.log('payload', payload)
        // console.log('API Success Response:', response.data)
        onClose(response.data)
        // 成功後可以更新狀態，並關閉 modal
        // setModal((prevState) => ({
        //   ...prevState,
        //   show: false, // 關閉 modal
        // }))
        // setApiStatus({
        //   status: response.data.status,
        //   message: response.data.message,
        //   apiStatusModalShow: true, // 顯示API狀態
        // })
        // onClose() // 確認後關閉 modal
      })
      .catch((error) => {
        // console.log(error.response)
        // console.log(error)
        // console.log('API Error Response:', error.message)
        onClose({
          error_status: error.response.data.status,
          error: error.response.data.message,
        })
        // setApiStatus({
        //   status: error.response.data.status,
        //   message: error.response.data.message,
        //   apiStatusModalShow: true, // 顯示API狀態
        // })
      })
  }

  // 提交 API 請求
  const handleSubmit = () => {
    const payload = {
      client_application_id: clientApplicationId,
      new_status: newStatus,
      expiration_time: moment(expirationTime).format('YYYY-MM-DD HH:mm:ss'),
      reason,
    }

    // 調用 API
    submitSubscriptionStatusChange(payload)
  }

  useEffect(() => {
    if (newStatus === 'free') setExpirationTime('')
  }, [newStatus])

  return (
    <Modal show={show} onHide={onClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>調整訂閱狀態</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formNewStatus">
            <Form.Label>訂閱狀態</Form.Label>
            <Form.Control
              as="select"
              value={newStatus}
              onChange={(e) => setNewStatus(e.target.value)}
            >
              <option value="">請選擇</option>
              <option value="trial">Trial</option>
              <option value="subscribe">Subscribe</option>
              <option value="free">Free</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formExpirationTime">
            <Form.Label>到期時間</Form.Label>
            <Form.Control
              type="datetime-local"
              value={expirationTime}
              onChange={(e) => setExpirationTime(e.target.value)}
              disabled={newStatus === 'free'} // free 狀態時禁用到期時間
            />
          </Form.Group>

          <Form.Group controlId="formReason">
            <Form.Label>原因</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onClose()}>
          取消
        </Button>
        <Button variant="orca" onClick={handleSubmit}>
          確認
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

// PropTypes 驗證
ModalSubscriptionStatusChange.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  clientApplicationId: PropTypes.number.isRequired,
  // setModal: PropTypes.func.isRequired,
  // setApiStatus: PropTypes.func.isRequired,
}
