import React from 'react'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import LoadingButton from '../shared/LoadingButton'

export default function LoginModalForm(props) {
  const { settings } = props
  return (
    <div size="sm">
      <Form className="mx-auto">
        <Row className="d-flex mx-auto h-100 w-30">
          <Col xs={9} className="pe-0 ms-auto">
            <Row className="d-flex">
              <Col xs={4} className="ms-auto my-auto text-end text-light pt-1">
                Account
              </Col>
              <Col xs={8} className="d-flex ps-0">
                <Form.Group className="my-2 w-100" controlId="email">
                  <Form.Control
                    required
                    type="email"
                    placeholder="Email"
                    onChange={settings.onLoginFormChange}
                    size="sm"
                    style={{ opacity: '.5' }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="d-flex">
              <Col
                xs={4}
                type="account"
                className="ms-auto my-auto text-end text-light pt-1"
              >
                Password
              </Col>
              <Col xs={8} className="d-flex ps-0">
                <Form.Group className="mb-2 w-100" controlId="password">
                  <Form.Control
                    required
                    type="password"
                    placeholder="Password"
                    onChange={settings.onLoginFormChange}
                    size="sm"
                    style={{ opacity: '.5' }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col xs={3} className="d-flex ps-0 my-auto">
            <div className="m-auto d-flex w-100 ms-3">
              <LoadingButton
                size="sm"
                name="submit"
                settings={{
                  name: '',
                  value: '',
                  onClick: () => {
                    // setLoaded(false)
                    settings.handleLogin()
                  },
                  content: 'LOGIN',
                  loaded: settings.state.loginForm.loaded,
                  variant: 'outline-light',
                  size: 'sm',
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <div className="d-grid text-center text-danger h7">
            {settings.state.loginForm.status}
          </div>
        </Row>
      </Form>
    </div>
  )
}
