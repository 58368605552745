/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import axios from 'axios'
import Cookies from 'universal-cookie'
// Use the httpOnly flag to prevent JavaScript from reading it.
// Use the secure=true flag so it can only be sent over HTTPS.
// Use the SameSite=strict flag whenever possible to prevent CSRF
import { BACKEND_URL } from './settings'

const cookies = new Cookies()
const baseURL = `${BACKEND_URL}/apis/`
axios.defaults.withCredentials = true

const apiInstance = axios.create({
  baseURL,
  timeout: 20000,
})

const apiAuthInstance = axios.create({
  baseURL,
  timeout: 200000,
})
const apiAuthRefreshInstance = axios.create({
  baseURL,
  timeout: 20000,
})
const apiAuthInstanceOnce = axios.create({
  baseURL,
  timeout: 20000,
})

function getAccessToken() {
  const accessToken = cookies.get('access_token')
  return accessToken || false
}
// function getRefreshToken() {
//   const refreshToken = cookies.get('refresh_token')
//   return refreshToken || false
// }

function setToken(accessToken, refreshToken) {
  cookies.set('access_token', accessToken, { path: '/' })
  cookies.set('refresh_token', refreshToken, { path: '/' })
}
function delToken() {
  cookies.remove('access_token')
  cookies.remove('refresh_token')
}

// apiInstance.interceptors.response.use(
//   (response) => {
//     const resp = {
//       status: response.request.status,
//       data: response.data,
//     }
//     return resp
//   },
//   (error) => {
//     const resp = {
//       status: error.response.status,
//       data: undefined,
//     }
//     return resp
//   }
// )

apiAuthInstanceOnce.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    const accessToken = getAccessToken()
    config.headers =
      accessToken !== 'undefined'
        ? {
            Authorization: `Bearer ${accessToken}`,
          }
        : {}
    return config
  },
  (error) => Promise.reject(error)
)
apiAuthInstanceOnce.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          delToken()
          break

        case 500:
          // go to 500 page
          break

        default:
          break
      }
      return Promise.reject(error.message)
    }
  }
)
apiAuthRefreshInstance.interceptors.response.use(
  (response) => {
    setToken(response.data.access, response.data.refresh)
    return response
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          delToken()
          break

        case 500:
          // go to 500 page
          break

        default:
          break
      }
      return Promise.reject(error)
    }
  }
)
apiAuthInstance.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    const accessToken = getAccessToken()
    config.headers =
      accessToken !== 'undefined'
        ? {
            Authorization: `Bearer ${accessToken}`,
          }
        : {}
    return config
  },
  (error) => Promise.reject(error)
)
apiAuthInstance.interceptors.response.use(
  (response) =>
    // Do something with response data
    response,
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // return apiAuthRefreshInstance({
          //   url: 'account/token/refresh/',
          //   method: 'post',
          //   data: { refresh: getRefreshToken() },
          // })
          //   .then(() =>
          //     apiAuthInstanceOnce({
          //       url: error.config.url,
          //       method: error.config.method,
          //       data:
          //         error.config.data === undefined
          //           ? undefined
          //           : JSON.parse(error.config.data),
          //     })
          //   )
          //   .catch((refresh_error) => Promise.reject(refresh_error))
          break

        case 500:
          console.log('程式發生問題')
          // go to 500 page
          break

        default:
          break
      }
      return Promise.reject(error)
    }
  }
)

export {
  apiInstance,
  apiAuthInstance,
  apiAuthInstanceOnce,
  apiAuthRefreshInstance,
}
