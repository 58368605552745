/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'
import orcaLogo from '../../assets/images/orca.svg'
import bmLogo from '../../assets/images/orcabm-logo-text.png'
import { apiAuthInstance, apiInstance } from '../../apis/backend'
import LoginModalForm from '../../components/account/LoginModalForm'
import RegisterModalForm from '../../components/account/RegisterModalForm'
import Footer from '../../components/shared/Footer'
import ModalApiStatus from '../../components/modal/ModalApiStatus'

export default function Login(props) {
  // const [loginState, setLoginState ] = useState(false)
  // const handleLogin = () => {
  //     setTimeout(() => setLoginState(true), 1000);
  // }
  const { settings } = props
  const initLoginForm = {
    email: '',
    password: '',
    loaded: true,
  }
  const initApiStatus = {
    status: '',
    message: '',
    apiStatusModalShow: false,
    redirect: false,
  }
  const [loginForm, setLoginForm] = useState(initLoginForm)
  const [modalShow, setModalShow] = useState(true)
  const [apiStatus, setApiStatus] = useState(initApiStatus)

  const initRegistermodalForm = {
    step: 0,
    claimed: false,
    comp_name: '',
    email: '',
    password: '',
    confirm_password: '',
    unified_business_number: '',
    status: '',
    loaded: true,
    show: false,
  }
  const [registermodalForm, setRegistermodalForm] = useState(
    initRegistermodalForm
  )
  const handleRegisterModalShow = () =>
    setRegistermodalForm({
      ...registermodalForm,
      show: !registermodalForm.show,
    })
  const handleRegisterModalChange = (e) =>
    setRegistermodalForm({
      ...registermodalForm,
      [e.target.name]: e.target.value,
    })

  const navigate = useNavigate()

  async function postLoginLog(type, status, user = '', ipAddress = '') {
    await apiAuthInstance({
      url: `log/log/`,
      method: 'post',
      data: {
        type,
        data: {
          status,
          user,
          ip_address: ipAddress,
        },
      },
    })
      .then(() => {})
      .catch(() => {})
  }
  async function postLoginErrorLog(type, status, user = '---') {
    await apiInstance({
      url: `log/log/`,
      method: 'post',
      data: {
        type,
        data: {
          status,
          user,
        },
      },
    })
      .then(() => {})
      .catch(() => {})
  }

  const handleModalShow = (event) => {
    if (event === undefined) {
      setModalShow(false)
    } else {
      switch (event.target.name) {
        case 'login-modal':
          setModalShow(true)
          break
        default:
          setModalShow(false)
          break
      }
    }
  }

  const onLoginFormChange = (event) => {
    switch (event.target.id) {
      case 'email':
        setLoginForm((prevState) => ({
          ...prevState,
          email: event.target.value,
        }))
        break

      case 'password':
        setLoginForm((prevState) => ({
          ...prevState,
          password: event.target.value,
        }))
        break

      default:
        break
    }
  }
  const handleModalClose = () => {
    setApiStatus(initApiStatus)
    setRegistermodalForm(initRegistermodalForm)
    setLoginForm(initLoginForm)
    window.location.reload(true)
  }
  const handleLogin = async () => {
    setLoginForm({
      ...loginForm,
      loaded: false,
    })
    await apiInstance({
      url: 'account/token/obtain/',
      method: 'post',
      data: loginForm,
    })
      .then((tokenResponse) => {
        switch (tokenResponse.status) {
          case 200:
            settings.cookies.set('refresh_token', tokenResponse.data.refresh, {
              path: '/',
            })
            settings.cookies.set('access_token', tokenResponse.data.access, {
              path: '/',
            })
            // get user data
            settings.state.setAuthData({
              ...settings.state.initAuthData,
              status: 'loginSuccess',
              loaded: true,
            })
            postLoginLog('login_logout', 'login')
            navigate('/home')
            break
          case 500:
            postLoginErrorLog('login_logout', 'system_error', loginForm.email)
            setLoginForm({
              ...loginForm,
              status: '*系統發生錯誤',
              loaded: true,
            })
            break

          default:
            postLoginErrorLog('login_logout', 'password_error', loginForm.email)
            setLoginForm({
              ...loginForm,
              status: '*帳號或密碼輸入錯誤',
              loaded: true,
            })
            break
        }
      })
      .catch(() => {
        postLoginErrorLog('login_logout', 'password_error', loginForm.email)
        setLoginForm({
          ...loginForm,
          status: '*帳號或密碼輸入錯誤',
          loaded: true,
        })
      })
  }
  const handleRegister = async () => {
    if (registermodalForm.step === 0) {
      setRegistermodalForm({ ...registermodalForm, step: 1 })
      return
    }
    setRegistermodalForm({
      ...registermodalForm,
      loaded: false,
    })
    await apiInstance({
      url: 'account/user-detail-admin/',
      method: 'post',
      data: registermodalForm,
    })
      .then((tokenResponse) => {
        switch (tokenResponse.status) {
          case 200:
            settings.cookies.set('refresh_token', tokenResponse.data.refresh, {
              path: '/',
            })
            settings.cookies.set('access_token', tokenResponse.data.access, {
              path: '/',
            })
            // get user data
            settings.state.setAuthData({
              ...settings.state.initAuthData,
              status: 'loginSuccess',
              loaded: true,
            })
            setRegistermodalForm({ ...registermodalForm, step: 2 })
            // navigate("/account/login");
            break
          case 500:
            setRegistermodalForm({
              ...registermodalForm,
              status: '*系統發生錯誤',
              loaded: true,
              step: 2,
            })
            break

          default:
            setRegistermodalForm({
              ...registermodalForm,
              status: '*帳號或密碼輸入錯誤',
              loaded: true,
              step: 2,
            })
            break
        }
      })
      .catch(() => {
        setRegistermodalForm({
          ...registermodalForm,
          status: '*帳號或密碼輸入錯誤',
          loaded: true,
          // step: 2,
        })
      })
  }
  return (
    <Container className="w-100 h-100 px-0 m-auto">
      <Row className="d-flex w-100 h-50 m-auto">
        <div className="d-flex mx-auto mt-auto">
          <div className="mx-auto mt-auto logo-hover-rotate">
            <Image
              style={{ width: '13rem' }}
              src={orcaLogo}
              className="App-logo mt-auto mx-auto"
            />
          </div>
        </div>
      </Row>
      <Row className="d-flex w-100 pt-0 mb-auto mx-auto">
        <Image
          style={{ width: '17.5rem' }}
          src={bmLogo}
          className="mt-auto mx-auto pt-2 pb-3"
        />
      </Row>
      <Row className="d-flex align-items-center mx-0">
        <LoginModalForm
          settings={{
            state: {
              loginForm,
              modalShow,
              setModalShow,
            },
            handleModalShow,
            onLoginFormChange,
            handleLogin,
          }}
        />
      </Row>
      <Row className="d-flex pt-2">
        <span className="text-center">
          <Link
            title="註 冊 新 帳 戶"
            className="text-orcaIcon3 text-center small fw-bolder"
            onClick={handleRegisterModalShow}
          >
            Register
          </Link>
        </span>
        <RegisterModalForm
          settings={{
            state: {
              modalForm: registermodalForm,
            },
            handleRegisterModalShow,
            onRegisterFormChange: handleRegisterModalChange,
            handleRegister,
            handleClose: () => setRegistermodalForm(initRegistermodalForm),
          }}
        />
      </Row>
      <ModalApiStatus
        settings={{
          content: {
            status: apiStatus.status,
            message: apiStatus.message,
          },
          apiStatusModalShow: apiStatus.apiStatusModalShow,
          handleModalClose,
          redirect: apiStatus.redirect,
        }}
      />
      <Footer />
    </Container>
  )
}
