import React, { useState } from 'react'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { fa1, fa2, fa3, fa4 } from '@fortawesome/free-solid-svg-icons'
import Navbar from '../../components/shared/Navbar'
import Footer from '../../components/shared/Footer'
import Toolbar from '../../components/shared/Toolbar'
import MaRequirement from './MaRequirementPage'
// import SampleDataUpload from './SampleDataUpload'
// import SampleDataMapping from './SampleDataMapping'
import SubmitSuccess from './SubmitSuccess'

export default function ApplicationMain({ settings }) {
  const [step, setStep] = useState(0)
  // const stepIcons = [fa1, fa4]
  const forms = {
    0: <MaRequirement setStep={setStep} />,
    // 1: <SampleDataUpload setStep={setStep} />,
    // 2: <SampleDataMapping setStep={setStep} />,
    1: <SubmitSuccess setStep={setStep} />,
  }

  return (
    <Container fluid="true" className="pt-2 pb-3 px-5 mb-5 h-100 w-100">
      <Navbar settings={settings} />
      <Toolbar settings={settings} />
      <Row
        className="d-flex card h-78 mb-2 px-4 h6"
        style={{ flexDirection: 'row' }}
      >
        <div className="h-3 w-100 position-relative">
          {/* <h3
            className="position-absolute text-center text-orcaLight fw-bold py-2 mb-0 w-100"
            style={{
              top: '8.6%',
              zIndex: '5',
            }}
          >
            STEP
          </h3> */}
          {/* <div className="d-flex w-100 text-orcaLight mx-auto h-100 pb-3">
            <Row
              className="position-absolute w-55"
              style={{
                borderTop: '8px solid #fff',
                left: '24%',
                top: '48.6%',
                zIndex: '2',
              }}
            /> */}
          <Row
            className="d-flex position-absolute mx-0"
            style={{ zIndex: '3', width: '72%', left: '15%', top: '26.8%' }}
          >
            {[0, 1].map((s) => (
              <Col key={s} xs={3} className="rounded-circle ps-0">
                <FontAwesomeIcon
                  className={`d-flex rounded-circle ${
                    step === s ? 'steper_active' : 'steper_default'
                  } mx-auto`}
                  // icon={stepIcons[s]}
                />
              </Col>
            ))}
          </Row>
          {/* </div> */}
        </div>
        <Row className="h-85 w-100 px-4 mx-0">
          <Col xs={12} className="d-flex card h-100">
            {forms[step]}
          </Col>
        </Row>
      </Row>
      <Footer />
    </Container>
  )
}
