import React from 'react'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

export default function LoadingButton(props) {
  const { settings } = props
  const { name, value, onClick, content, loaded, disabled, variant, size } =
    settings
  return loaded ? (
    <Button
      variant={variant || 'orca'}
      size={size}
      className="d-flex"
      disabled={disabled}
      name={name}
      value={value}
      onClick={(event) => {
        onClick(event)
      }}
    >
      {content}
    </Button>
  ) : (
    <Button variant="orca" size={size} style={{ minWidth: '60px' }}>
      <Spinner
        className="m-auto"
        size="sm"
        animation="border"
        variant="light"
      />
    </Button>
  )
}
