import React, { useState } from 'react'
import Figure from 'react-bootstrap/Figure'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import { useNavigate } from 'react-router-dom'
import orcaLogo from '../../assets/images/orca.svg'
import bmLogo from '../../assets/images/orcabm-logo-text.png'
import RegisterModalForm from '../../components/account/RegisterModalForm'
import { apiInstance } from '../../apis/backend'

export default function Register(props) {
  // const [loginState, setLoginState ] = useState(false)
  // const handleLogin = () => {
  //     setTimeout(() => setLoginState(true), 1000);
  // }
  const { settings } = props

  const initRegisterForm = {
    email: '',
    password: '',
    unified_business_number: '',
  }
  const [registerForm, setRegisterForm] = useState(initRegisterForm)
  const [modalShow, setModalShow] = useState(true)

  const navigate = useNavigate()

  const handleModalShow = (event) => {
    if (event === undefined) {
      setModalShow(false)
    } else {
      switch (event.target.name) {
        case 'register-modal':
          setModalShow(true)
          break

        default:
          setModalShow(false)
          break
      }
    }
  }
  const onRegisterFormChange = (event) => {
    switch (event.target.id) {
      case 'email':
        setRegisterForm((prevState) => ({
          ...prevState,
          email: event.target.value,
        }))
        break

      case 'password':
        setRegisterForm((prevState) => ({
          ...prevState,
          password: event.target.value,
        }))
        break

      case 'confirm-password':
        // setRegisterForm(prevState => ({
        //     ...prevState,
        //     "confirm-password": event.target.value
        // }));
        break
      case 'unified-business-number':
        // 需檢查是否為八位數數字
        setRegisterForm((prevState) => ({
          ...prevState,
          unified_business_number: event.target.value,
        }))
        break

      default:
        break
    }
  }
  const handleRegister = async () => {
    setRegisterForm({
      ...registerForm,
      loaded: false,
    })
    await apiInstance({
      url: 'account/user-detail-admin/',
      method: 'post',
      data: registerForm,
    }).then((tokenResponse) => {
      switch (tokenResponse.status) {
        case 200:
          settings.cookies.set('refresh_token', tokenResponse.data.refresh, {
            path: '/',
          })
          settings.cookies.set('access_token', tokenResponse.data.access, {
            path: '/',
          })
          // get user data
          settings.state.setAuthData({
            ...settings.state.initAuthData,
            status: 'loginSuccess',
            loaded: true,
          })
          navigate('/account/login')
          break
        case 500:
          setRegisterForm({
            ...registerForm,
            status: '*系統發生錯誤',
            loaded: true,
          })
          break

        default:
          setRegisterForm({
            ...registerForm,
            status: '*帳號或密碼輸入錯誤',
            loaded: true,
          })
          break
      }
    })
  }
  return (
    <div
      className="bgImg_gif2 vh-100"
      style={{ userSelect: 'none', overflow: 'hidden' }}
    >
      <Container className="h-100 LoginPage px-0 bg-transp" fluid="true">
        <Col className="d-flex h-97 align-items-center mx-0">
          <Figure className="text-center mx-auto" style={{ zIndex: '9999' }}>
            <Figure.Image
              width={200}
              src={orcaLogo}
              className="whiteForce App-logo row mx-auto"
            />
            <Figure.Image width={220} src={bmLogo} className="row mx-auto" />
            <RegisterModalForm
              settings={{
                state: {
                  registerForm,
                  modalShow,
                  setModalShow,
                },
                handleModalShow,
                onRegisterFormChange,
                handleRegister,
              }}
            />
            <Figure.Caption />
          </Figure>
          <div
            className="d-flex text-light text-center bg-transp position-absolute pb-2 w-100"
            style={{ bottom: '0' }}
          >
            <h6 className="mx-auto">
              Copyright © 2023 Wavenet. all rights reserved.
            </h6>
          </div>
        </Col>
      </Container>
    </div>
  )
}
